import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from '@material-ui/core';
import SynonymService from '../services/synonyms';
import { DictionaryFilters } from './DictionaryFilters';

import { Table, Pagination, Loader } from './Tab.styles';

export const Tab = ({ type }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [pagination, setPagination] = useState({});

  const fetchInconsistencies = async (newPage, newPerPage, newSearch = '') => {
    setLoading(true);
    try {
      const response = await SynonymService.getInconsistencies({
        page: newPage,
        perPage: newPerPage,
        search: newSearch,
      });
      setBrands(response.data);
      setPagination(response.pagination);
    } catch (err) {
      setBrands([]);
      setPagination({});
    }
    setLoading(false);
  };

  const fetchDictionary = async (newPage, newPerPage, newSearch = '') => {
    setLoading(true);
    try {
      const response = await SynonymService.getAll({
        type,
        page: newPage,
        perPage: newPerPage,
        search: newSearch,
      });
      setBrands(response.data);
      setPagination(response.pagination);
    } catch (err) {
      setBrands([]);
      setPagination({});
    }
    setLoading(false);
  };

  const fetchData = async (newPage, newPerPage, newSearch = '') => {
    if (type === 'Inconsistency') {
      await fetchInconsistencies(newPage, newPerPage, newSearch);
    } else {
      await fetchDictionary(newPage, newPerPage, newSearch);
    }
  };

  const hanldePageChange = async (e, newPage) => {
    setPage(newPage);
    await fetchData(newPage, perPage);
  };
  const haneldeFiltersChange = async (search) => {
    await fetchData(page, perPage, search);
  };

  const getFontColor = (score) => {
    if (score < 50) {
      return '#e57373';
    }
    if (score >= 50 && score < 85) {
      return '#ffb74d';
    }
    if (score >= 85) {
      return '#81c784';
    }
  };

  useEffect(() => {
    fetchData(page, perPage);
  }, []);

  return (
    <div>
      <DictionaryFilters type={type} onFiltersChange={haneldeFiltersChange} />
      {loading && <Loader />}
      {!loading && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={300}>
                  {type === 'Inconsistency' ? 'Raw Value' : 'Structure'}
                </TableCell>
                <TableCell>
                  {type === 'Inconsistency'
                    ? 'Structures (DP ID)'
                    : 'Raw Value (Impressions)'}
                </TableCell>
                <TableCell width={150}>DP ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brands.map((brand) => (
                <TableRow key={brand.id}>
                  <TableCell>
                    {type === 'Inconsistency' && (
                      <strong>{brand.raw_value}</strong>
                    )}
                    {type !== 'Inconsistency' && (
                      <strong>{brand.object_name}</strong>
                    )}
                  </TableCell>
                  <TableCell>
                    {type === 'Inconsistency' && (
                      <div style={{ display: 'flex' }}>
                        <strong>{(brand.structures || []).length}</strong>
                        <div style={{ marginLeft: 20 }}>
                          {(brand.structures || [])
                            .map((s) => `${s.object_name} (${s.object_id})`)
                            .join('  |  ') || 'No Structures'}
                        </div>
                      </div>
                    )}
                    {type !== 'Inconsistency' && (
                      <div style={{ display: 'flex' }}>
                        <strong>{(brand.synonyms || []).length}</strong>
                        <div style={{ marginLeft: 20 }}>
                          {(brand.synonyms || []).length ? (
                            brand.synonyms.map((s, i) => (
                              <>
                                {s.raw_value} ({s.count})&nbsp;[
                                <span
                                  style={{
                                    color: getFontColor(
                                      s.scoreValues.score ?? 0
                                    ),
                                  }}
                                >
                                  {s.scoreValues.score ?? 0}%
                                </span>
                                ]
                                {i !== brand.synonyms.length - 1 ? (
                                  <>&nbsp;&nbsp; | &nbsp;&nbsp;</>
                                ) : (
                                  <></>
                                )}
                              </>
                            ))
                          ) : (
                            <>No Synonyms</>
                          )}
                        </div>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {type === 'Product' && (
                      <a
                        style={{ color: '#3F51B5' }}
                        href={`https://www.designerpages.com/products/${brand.object_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {brand.object_id}
                      </a>
                    )}
                    {type !== 'Product' && <span>{brand.object_id}</span>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {pagination && (
            <Pagination
              color="primary"
              style={{
                marginTop: 50,
                display: 'flex',
                justifyContent: 'center',
              }}
              count={pagination.pages || 1}
              page={pagination.onPage || 1}
              onChange={hanldePageChange}
            />
          )}
        </>
      )}
    </div>
  );
};

Tab.defaultProps = {
  type: 'Manufacturer',
};

Tab.propTypes = {
  type: PropTypes.string,
};

export default Tab;
