/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { subDays, subWeeks, subMonths, subYears } from 'date-fns';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { DateRangePicker } from 'materialui-daterange-picker';

import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import _ from 'lodash';

const getDefaultRanges = (date) => [
  {
    label: '1 Day',
    startDate: subDays(date, 1),
    endDate: date,
  },
  {
    label: '1 Week',
    startDate: subWeeks(date, 1),
    endDate: date,
  },
  {
    label: '1 Month',
    startDate: subMonths(date, 1),
    endDate: date,
  },
  {
    label: 'Year To Date',
    startDate: subYears(date, 1),
    endDate: date,
  },
];

const defaultRanges = getDefaultRanges(new Date());

export default function DateRange({ showCalendarBtn, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState({});
  const [label, setLabel] = useState('1 Day');

  const open = Boolean(anchorEl);

  const handleOpenDatePicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDatePicker = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    onChange && !_.isEmpty(dateRange) && onChange(dateRange);
  }, [dateRange]);

  return (
    <>
      <Button onClick={handleOpenDatePicker} style={{ alignSelf: 'center' }}>
        {label}
        {showCalendarBtn && <DateRangeIcon />}
        <ArrowDropDownIcon />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRangePicker
          open
          definedRanges={defaultRanges}
          toggle={handleCloseDatePicker}
          onChange={(range) => {
            setDateRange(range);
            setLabel(range.label || 'Custom');
            handleCloseDatePicker();
          }}
        />
      </Popover>
    </>
  );
}

DateRange.defaultProps = {
  showCalendarBtn: false,
  onChange: () => {},
};

DateRange.propTypes = {
  showCalendarBtn: PropTypes.bool,
  onChange: PropTypes.func,
};
