import React from 'react';

const DPLinkComponent = (props, context) => {
  const { dpid, type } = props;
  return (
    <a style={{ color: '#333' }} target="_dpsite" href={`http://www.designerpages.com/${type}/${dpid}`}>{dpid}</a>
  );
};


export default DPLinkComponent;
