/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import {
  Tooltip,
  Accordion as ExpansionPanel,
  AccordionSummary as ExpansionPanelSummary,
  AccordionDetails as ExpansionPanelDetails,
} from '@material-ui/core';
import Pill from './Pill';
import AlertDialog from './AlertDialog';

const getBulletColor = ({ synonym, scoreData }) => {
  if (!synonym) {
    return '#4fc3f7';
  }
  if (scoreData.score < 50) {
    return '#e57373';
  }
  if (scoreData.score >= 50 && scoreData.score < 85) {
    return '#ffb74d';
  }
  if (scoreData.score >= 85) {
    return '#81c784';
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '90%',
    flexShrink: 0,
  },
}));

const Bullet = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  background-color: ${(props) => getBulletColor(props)};
`;

// product has to match the csi as well
function productsInBrand(csiId, brandId, tags) {
  return tags.products.filter(
    (o) => o.csiId === csiId && o.brandId === brandId
  );
}

function brandsInSection(sectionId, tags, showTags) {
  const seen = {};
  // console.log('**** BiS', { sectionId, brands: tags.brands } )
  const filtered = (tags?.brands || []).filter((o, i) => {
    if (o.csiId === sectionId) {
      if (o.bottom - o.top > 100) {
        return false;
      }
      if (seen[o.inspexId]) {
        seen[o.inspexId] += 1;
      } else {
        seen[o.inspexId] = 1;
      }
      if (seen[o.inspexId] === 1 && !o.synonym) {
        return true;
      }
      const score = JSON.parse(o.scoreData || '{}')?.score || 0;
      if (showTags.bad && score < 50) {
        return true;
      }
      if (showTags.good && score >= 50 && score < 85) {
        return true;
      }
      if (showTags.best && score >= 85) {
        return true;
      }
    }
    return false;
  });

  // we now have all brands that are in this section.
  const brands = filtered.map((b) => {
    const x = b;
    x.children = productsInBrand(sectionId, b.inspexId, tags);
    x.count = seen[b.inspexId];
    return x;
  });

  //
  // now get the products that have no brand in this section.
  //
  const brandIds = filtered.map((b) => b.brandId);
  const lonelyProducts = (tags?.products || []).filter(
    (p) => p.csiId === sectionId && !brandIds.includes(p.brandId)
  );
  if (lonelyProducts.length > 0) {
    brands.push({
      id: `${sectionId}-unknown-brand`,
      csiId: sectionId,
      inspexId: null,
      text: 'Unknown Brand',
      location: { box: { top: 0, left: 0, bottom: 0, right: 0 }, page: 0 },
      source: 'content',
      type: 'brand',
      children: lonelyProducts,
      count: 1,
    });
  }
  return brands;

  // we now have all brands and products in this section.

  // still missing products that have NO BRAND in this section.
  // e.g. a list of products that matched in the section, but didnt have a brand that matched
}

function tagMapper(tags, showTags) {
  const sections = (tags?.sections || []).map((s) => {
    const x = s;
    x.children = brandsInSection(s.id, tags, showTags);
    return x;
  });

  const unknownBrands = brandsInSection(null, tags);
  if (unknownBrands.length > 0) {
    sections.push({
      id: null,
      code: 'UNKNOWN SECTION',
      start: {
        box: { left: 0, right: 0, top: 0, bottom: 0 },
        page: 0,
      },
      end: {
        box: null,
        page: null,
      },
      part2: {
        box: null,
        page: null,
      },
      children: unknownBrands,
    });
  }

  return sections;
}

export default function TagNavigation(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [highlighted, sethigHlighted] = React.useState(null);
  const [lv2Expanded, setLV2Expanded] = React.useState(false);
  const [isZoomed, setIsZoomed] = React.useState(
    !(parseInt(window.devicePixelRatio * 100, 10) === 100)
  );
  const { tags, onTraverse, showTags } = props;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleLV2Change = (panel) => (event, isExpanded) => {
    console.log('hlv2');
    setLV2Expanded(isExpanded ? panel : false);
  };

  const handleClick = (event, obj) => {
    console.log('handleClick', obj);
    onTraverse(obj);
    sethigHlighted(obj.id);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const organized = tagMapper(tags, showTags);

  return (
    <div className={classes.root}>
      <AlertDialog
        open={isZoomed}
        onClose={() => setIsZoomed(false)}
        confirm="Ok, thanks!"
        refuse="Why are you bothering me!?"
        message="Your browser is zoomed.  Use ctrl -/+ to set to 100%. Instead, use the - + buttons to adjust the size of the pdf"
        title="Warning"
      />
      {organized.map((section) => {
        return (
          <ExpansionPanel
            key={section.id}
            expanded={expanded === section.id}
            onChange={handleChange(section.id)}
            onClick={(event) => handleClick(event, section.start)}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                {section.code}
              </Typography>
              <Pill count={section.children.length} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List component="nav">
                {section.children.map(
                  (brand) => (
                    <ListItem
                      key={brand.id}
                      button
                      onClick={(event) => handleClick(event, brand)}
                    >
                      <Tooltip
                        title={brand.synonym ? 'Brand Synonym' : 'Brand'}
                        placement="top"
                      >
                        <Bullet
                          synonym={brand.synonym}
                          scoreData={JSON.parse(brand.scoreData || '{}')}
                        />
                      </Tooltip>
                      <ListItemText
                        primary={brand.text}
                        primaryTypographyProps={{
                          style: {
                            fontWeight:
                              highlighted === brand.id ? 'bold' : 'normal',
                          },
                        }}
                      />
                    </ListItem>
                  )
                  // remove the product expansion, since we are not showing products right now.
                  // <ExpansionPanel key={brand.id} expanded={lv2Expanded === brand.id} onChange={handleLV2Change(brand.id)} onClick={event => handleClick(event, brand)}>
                  //   <ExpansionPanelSummary
                  //     expandIcon={<ExpandMore />}
                  //     aria-controls="panel2bh-content"
                  //     id="panel2bh-header"
                  //   >
                  //     <Typography className={classes.heading}>{brand.text}</Typography>
                  //     <Pill count={brand.children.length} />
                  //   </ExpansionPanelSummary>
                  //   <ExpansionPanelDetails>
                  //     <List
                  //       component="nav"
                  //     >
                  //       {brand.children.map(product => (
                  //         <ListItem key={product.id} button onClick={event => handleClick(event, product)}>
                  //           <ListItemText primary={product.text}>
                  //           </ListItemText>
                  //         </ListItem>
                  //       ))}
                  //     </List>
                  //   </ExpansionPanelDetails>
                  // </ExpansionPanel>
                )}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
}
