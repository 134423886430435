/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SynonymsService from '../services/synonyms';

import {
  Wrapper,
  TableContainer,
  InlineContainer,
  VerticalContainer,
  Padding,
  SmallFont,
} from './SynonymSyncModal.styles';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  successIcon: {
    color: theme.palette.success.light,
    fontSize: 18,
  },
  warningIcon: {
    color: theme.palette.warning.light,
    fontSize: 18,
  },
  infoIcon: {
    color: theme.palette.info.light,
    fontSize: 18,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const SynonymSyncModal = ({
  open,
  projectId,
  synonyms,
  onSave,
  onCancel,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [calculated, setCalculated] = useState([]);

  const saveSynonyms = async () => {
    const output = [];
    calculated.forEach((m) => {
      if (
        m.synonyms.manufacturerName &&
        m.manufacturerName &&
        m.manufacturerStatus === 'missing'
      ) {
        output.push({
          projectId,
          objectId: m.manufacturerId,
          objectType: 'Manufacturer',
          objectName: m.manufacturerName,
          rawValue: m.synonyms.manufacturerName,
        });
      }
      if (
        m.synonyms.productName &&
        m.productName &&
        m.productStatus === 'missing'
      ) {
        output.push({
          projectId,
          objectId: m.productId,
          objectType: 'Product',
          objectName: m.productName,
          rawValue: m.synonyms.productName,
        });
      }
      m.categoryStatus.forEach((status, index) => {
        if (
          m.synonyms.categoryName &&
          m.categoryNames[index] &&
          status === 'missing'
        ) {
          output.push({
            projectId,
            objectId: m.categoryIds[index],
            objectType: 'Category',
            objectName: m.categoryNames[index],
            rawValue: m.synonyms.categoryName,
          });
        }
      });
    });

    const response = await SynonymsService.save(projectId, output);
    if (response.error || response.errors) {
      enqueueSnackbar('Failed to sync synonyms', { variant: 'error' });
      return false;
    }

    enqueueSnackbar("Synonyms sync'd successfully", { variant: 'success' });
    return true;
  };

  const hasToSync = () => {
    if (calculated.length) {
      const found = calculated.find((c) => !!c.toSync);
      return !found;
    }

    return false;
  };

  const getGeaterStatus = (statuses = []) => {
    if (statuses.includes('missing')) {
      return 'missing';
    }
    if (statuses.includes('exists')) {
      return 'exists';
    }
    if (statuses.includes('equal')) {
      return 'equal';
    }
  };

  const handleClose = () => {
    onCancel && onCancel();
  };

  const handleSave = async () => {
    const saved = await saveSynonyms();
    if (saved) {
      onSave && onSave();
    }
  };

  useEffect(() => {
    (async () => {
      if (synonyms && synonyms.length) {
        setLoading(true);
        const response = await SynonymsService.calculate(projectId, synonyms);
        setCalculated(response || []);
        setLoading(false);
      }
    })();
  }, []);

  const renderStatusIcon = (status) => {
    if (status === 'equal') {
      return (
        <Tooltip title="No synonym to register" placement="top">
          <SyncDisabledIcon className={classes.warningIcon} />
        </Tooltip>
      );
    }
    if (status === 'exists') {
      return (
        <Tooltip title="The synonym is already register" placement="top">
          <CheckCircleOutlineIcon className={classes.successIcon} />
        </Tooltip>
      );
    }
    if (status === 'missing') {
      return (
        <Tooltip title="The synonym will be register" placement="top">
          <SyncIcon className={classes.infoIcon} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="No synonym to register" placement="top">
        <SyncDisabledIcon className={classes.warningIcon} />
      </Tooltip>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle onClose={handleClose}>Synonyms Sync</DialogTitle>
      <DialogContent dividers style={{ minHeight: 400 }}>
        <Wrapper>
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Product</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!loading && !calculated.length && (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      No Products linked to DP Products to check for synonyms.
                    </TableCell>
                  </TableRow>
                )}
                {!loading &&
                  (calculated || []).map((m, i) => (
                    <TableRow key={m.equivId}>
                      <TableCell variant="head" width={100}>
                        <InlineContainer>
                          <Padding right>{i + 1}</Padding>
                          <VerticalContainer>
                            <SmallFont>DP Value</SmallFont>
                            <SmallFont>Raw Value</SmallFont>
                          </VerticalContainer>
                        </InlineContainer>
                      </TableCell>
                      <TableCell>
                        <InlineContainer>
                          <VerticalContainer>
                            <span>{m.manufacturerName}</span>
                            <span>{m.synonyms.manufacturerName}</span>
                          </VerticalContainer>
                          <Padding left>
                            {renderStatusIcon(m.manufacturerStatus)}
                          </Padding>
                        </InlineContainer>
                      </TableCell>
                      <TableCell>
                        <InlineContainer>
                          <VerticalContainer>
                            <span>{m.categoryNames.join(',')}</span>
                            <span>{m.synonyms.categoryName}</span>
                          </VerticalContainer>
                          <Padding left>
                            {renderStatusIcon(
                              getGeaterStatus(m.categoryStatus)
                            )}
                          </Padding>
                        </InlineContainer>
                      </TableCell>
                      <TableCell>
                        <InlineContainer>
                          <VerticalContainer>
                            <span>{m.productName}</span>
                            <span>{m.synonyms.productName}</span>
                          </VerticalContainer>
                          <Padding left>
                            {renderStatusIcon(m.productStatus)}
                          </Padding>
                        </InlineContainer>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Wrapper>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={handleSave}
          color="primary"
          disabled={hasToSync()}
        >
          Sync Synonyms
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SynonymSyncModal.defaultProps = {
  open: false,
};

SynonymSyncModal.propTypes = {
  open: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  synonyms: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
