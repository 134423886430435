/**
  Module for the definiton of Divisions

  @module types/DivisionManager
*/

import apit from '../../lib/api';
import DataCache from '../../lib/DataCache';

async function loadDivisions() {
  const response = await apit.get('/v2/division/');
  const body = await response.data;
  if (response.status !== 200) throw Error(body.message);

  return body.data;
}

const dataCache = DataCache(600 * 1000, loadDivisions);

export default class DivisionManager {
  constructor() {
    this.divisions = [
      { label: '00 - Unknown Division', value: '0' },
      { label: '3 - Concrete', value: '3' },
      { label: '4 - Masonry', value: '4' },
      { label: '5 - Metals', value: '5' },
      { label: '6 - Wood-Plastics-Composites', value: '6' },
      { label: '7 - Thermal-Moisture-Protection', value: '7' },
      { label: '8 - Openings', value: '8' },
      { label: '9 - Finishes', value: '9' },
      { label: '10 - Specialties', value: '10' },
      { label: '11 - Equipment', value: '11' },
      { label: '12 - Furnishings', value: '12' },
      { label: '21 - Fire-Suppression', value: '21' },
      { label: '22 - Plumbing', value: '22' },
      { label: '23 - HVAC', value: '23' },
      { label: '26 - Electrical', value: '26' },
      { label: '28 - Electronic-Safety-Security', value: '28' },
      { label: '32 - Exterior-Improvements', value: '32' },
    ];
  }

  getDivisionList() {
    return this.divisions;
  }

  getDivision(divisionId) {
    return this.divisions.find(d => d.value === divisionId);
  }

  groupMaterials(materials) {
    const groups = [];
    materials.forEach((material) => {
      const did = material.division;
      // eslint-disable-next-line eqeqeq
      let grp = groups.find(g => g.id == did);
      if (!grp) {
        const rootDiv = this.getDivision(did);
        grp = {
          id: rootDiv.value,
          label: rootDiv.label,
          options: [],
        };
        groups.push(grp);
      }
      if (grp) {
        const nest = { value: material.id, label: material.name };

        grp.options.push(nest);
      }
    });

    return groups;
  }
}

export async function getDivisionByCategoryId(catId) {
  const cats = await dataCache.getData();
  const match = cats.find(cat => cat.id === catId);
  if (match && match.division) {
    return parseInt(match.division, 10);
  }

  return '0';
}

export async function getCategoryLabels() {
  const cats = await dataCache.getData();
  return cats.map(cat => ({ label: `${cat.name} - ${cat.id}`, value: cat.id }));
}

export async function getCategoryLabel(catId) {
  const cats = await dataCache.getData();

  const match = cats.find(cat => cat.id === catId);
  if (match) {
    return { label: match.name, value: match.id };
  }

  return { label: '00 - Unknown Division', value: '0' };
}
