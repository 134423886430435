import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HistoryIcon from '@material-ui/icons/History';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertDialog from '../components/AlertDialog';

import { DangerMenuItem } from './ResourceActionsMenu.styles';
import resourcesService from '../services/resources';

export default function ResourceActionsMenu({
  status,
  projectId,
  fileId,
  fileName,
  onResourceChange,
  onHistoryClick,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const disabled = status === 'In Processing Queue';

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const open = Boolean(anchorEl);

  const rescan = async () => {
    try {
      await resourcesService.rescan(projectId, fileId);
      enqueueSnackbar('File was sent for rescanning', { variant: 'success' });
      onResourceChange && onResourceChange();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const reprocess = async () => {
    try {
      await resourcesService.reprocess(projectId, fileId);
      enqueueSnackbar('File was sent for reprocessing', {
        variant: 'success',
      });
      onResourceChange && onResourceChange();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const download = async () => {
    await resourcesService.download(projectId, fileId, fileName);
  };

  const remove = async () => {
    try {
      const removed = await resourcesService.remove(projectId, fileId);
      if (removed) {
        enqueueSnackbar('File was removed from the project', {
          variant: 'success',
        });
        onResourceChange && onResourceChange();
      } else {
        enqueueSnackbar('Failed to remove the file!', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const handleConfirmClose = (confirm) => {
    if (confirm) {
      remove();
    }
    setShowConfirm(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AlertDialog
        open={showConfirm}
        onClose={handleConfirmClose}
        title="Remove File"
        message="Are you sure you want to remove this file?"
      />
      <IconButton color="primary" onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          disabled={disabled}
          onClick={async () => {
            await rescan();
            handleClose();
          }}
        >
          <FingerprintIcon /> &nbsp; Rescan File
        </MenuItem>
        <MenuItem
          disabled={disabled}
          onClick={async () => {
            await reprocess();
            handleClose();
          }}
        >
          <FingerprintIcon /> &nbsp; Reprocess File
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await download();
            handleClose();
          }}
        >
          <PictureAsPdfIcon /> &nbsp; Download PDF
        </MenuItem>
        <MenuItem
          onClick={() => {
            onHistoryClick && onHistoryClick();
            handleClose();
          }}
        >
          <HistoryIcon /> &nbsp; History
        </MenuItem>
        <DangerMenuItem
          onClick={async () => {
            await remove();
            handleClose();
          }}
        >
          <DeleteIcon /> &nbsp; Remove File
        </DangerMenuItem>
      </Menu>
    </div>
  );
}

ResourceActionsMenu.propTypes = {
  ready: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  onResourceChange: PropTypes.func.isRequired,
  onHistoryClick: PropTypes.func.isRequired,
};
