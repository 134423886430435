import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  const { classes, onClose, selectedValue, ...other } = props;
  const yes = props.confirm || 'Yes';
  const no = props.refuse || 'No';
  const message = props.message || 'Do you want to continue this action?';
  const title = props.title || 'Confirm Action';

  function handleRefuse() { onClose(false); }
  function handleClose() { onClose(true); }

  return (
    <div>
      <Dialog onClose={handleRefuse} aria-labelledby="pickuser-dialog-title" {...other}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefuse} color="primary">
            {no}
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            {yes}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
