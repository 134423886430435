import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { ShortenTextPopover } from './ShortenTextPopover';
import { Thumbnail } from './Thumbnail';
import GotoLocation from './GotoLocation';
import { Checkbox } from '@material-ui/core';
import materialsService from '../services/materials';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export const MaterialRow = ({
  projectId,
  item,
  mappings,
  onEdit,
  onProductSearch,
  onProductChange,
  onBestGuessChange,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const hasValue = !!mappings[item.id]?.product?.id;

  const [bestGuess, setBestGuess] = useState(item.bestGuess);

  const hasMappings = (material) => {
    return (
      mappings[material.id] && Object.keys(mappings[material.id]).length > 0
    );
  };

  const saveBestGuess = (newBestGuess) => {
    const body = { bestGuess: newBestGuess };
    const response = materialsService.updateMaterialEquiv(item.id, body);
    if (response) {
      enqueueSnackbar('Best Guess Saved.', {
        variant: 'success',
      });
      setBestGuess(newBestGuess);
      onBestGuessChange({ ...item, bestGuess: newBestGuess });
    } else {
      enqueueSnackbar('An error occurred, please try again.', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <TableRow>
        <TableCell />
        <TableCell>
          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              onEdit(item);
            }}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell>{item.manufacturer}</TableCell>
        <TableCell width="250">
          {item.product ? item.product : 'Open Spec'}
        </TableCell>
        <TableCell>
          <Checkbox
            checked={bestGuess}
            onChange={(e) => saveBestGuess(e.target.checked)}
          />
        </TableCell>
        <TableCell>
          <ShortenTextPopover text={item.notes} />
        </TableCell>
        <TableCell>{item.isBasis ? 'Specified' : 'Approved'}</TableCell>
        <TableCell>{item.isNested ? 'Nested' : ''}</TableCell>
        <TableCell>{item.code}</TableCell>
        <TableCell>{item.dpCategoryName}</TableCell>
        <TableCell width="300">
          <div className={classes.vertical}>
            <div className={classes.inline}>
              <TextField
                fullWidth
                label={hasValue ? 'DP Product' : 'Search for product'}
                value={mappings[item.id]?.product?.id || ''}
                onClick={() =>
                  onProductSearch(
                    item,
                    mappings[item.id]?.product,
                    mappings[item.id]?.asset
                  )
                }
                InputProps={{
                  style: { cursor: !hasValue ? 'pointer' : null },
                  readOnly: true,
                  endAdornment: (
                    <>
                      {hasMappings(item) && (
                        <IconButton
                          variant="primary"
                          size="small"
                          onClick={() => onProductChange(item, null)}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                      <IconButton
                        variant="primary"
                        size="small"
                        // onClick={() => handleOpenSearchModal(item)}
                      >
                        <SearchIcon />
                      </IconButton>
                    </>
                  ),
                }}
              />
            </div>
            {hasMappings(item) && (
              <>
                <div style={{ marginBottom: 10 }} />
                <div className={classes.fontSmall}>
                  Name:&nbsp;
                  <a
                    style={{ color: '#3F51B5' }}
                    href={`https://www.designerpages.com/products/${
                      mappings[item.id]?.product?.id
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {mappings[item.id]?.product?.name}
                  </a>
                </div>
                <div className={classes.fontSmall}>
                  Brand:&nbsp;
                  {mappings[item.id]?.product?.manufacturer_name}
                </div>
                <div className={classes.fontSmall}>
                  Category:&nbsp;
                  {(mappings[item.id]?.product?.dp_category_names || []).join(
                    ','
                  )}
                </div>
              </>
            )}
          </div>
        </TableCell>
        <TableCell>
          {mappings[item.id]?.product && !mappings[item.id]?.asset && (
            <Thumbnail />
          )}
          {mappings[item.id]?.asset && (
            <a
              href={`https://www.designerpages.com/products/${
                mappings[item.id]?.product?.id
              }`}
              target="_blank"
              rel="noreferrer"
            >
              <Thumbnail
                name={
                  mappings[item.id]?.asset?.referenceOnly
                    ? 'Image for reference'
                    : mappings[item.id]?.asset?.name
                }
                url={mappings[item.id]?.asset?.url}
              />
            </a>
          )}
        </TableCell>
        <TableCell colSpan={3}>
          <GotoLocation
            projectId={projectId}
            brand={item.locations.brand}
            product={item.locations.product}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

MaterialRow.defaultProps = {
  onEdit: () => {},
  onProductSearch: () => {},
  onProductChange: () => {},
  onBestGuessChange: () => {},
};

MaterialRow.propTypes = {
  projectId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    manufacturer: PropTypes.string,
    product: PropTypes.string,
    notes: PropTypes.string,
    code: PropTypes.string,
    isBasis: PropTypes.bool,
    isNested: PropTypes.bool,
    bestGuess: PropTypes.bool,
    dpCategory: PropTypes.number,
    dpCategoryName: PropTypes.string,
    locations: PropTypes.shape({
      brand: PropTypes.shape({
        resourceId: PropTypes.string,
        page: PropTypes.number,
      }),
      product: PropTypes.shape({
        resourceId: PropTypes.string,
        page: PropTypes.number,
      }),
    }),
  }).isRequired,
  // eslint-disable-next-line react/require-default-props,react/forbid-prop-types
  mappings: PropTypes.any,
  onEdit: PropTypes.func,
  onProductSearch: PropTypes.func,
  onProductChange: PropTypes.func,
  onBestGuessChange: PropTypes.func,
};
