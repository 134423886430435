/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DupIcon from '@material-ui/icons/PlusOne';

import apit from '../lib/api';
import * as Logger from '../lib/logger';

import ExplorerSideBar from './ExplorerSidebar';
import ExplorerMain from './ExplorerMain';
import Header from '../components/Header';
import BrandDialog from '../Brand/BrandDialog';
import ProductDialog from '../Product/ProductDialog';
import '../style/explorer.css';

import * as ProjMats from '../Material/types/projectMaterials';
import { initialize as initializeMaterial } from '../Material/types/material';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  materialDrawerPaper: {
    maxHeight: '50%',
    maxWidth: '100%',
  },
  fullList: {
    width: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  toolbar: theme.mixins.toolbar,
  sidebar: {
    color: '#fff',
    backgroundColor: '#353535',
    height: '100%',
  },
  container: {
    paddingLeft: '0',
    paddingRight: '0',
  },

  mainContainer: {
    margin: 0,
    padding: 0,
    xbackgroundColor: '#404040',
    flexGrow: '1',
    overflow: 'auto',
    height: '100vh',
  },
  rightContainer: {
    margin: 0,
    padding: 0,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  pdfContainer: {
    backgroundColor: '#404040',
    margin: 0,
    padding: 0,
    flexGrow: 1,
    overflow: 'auto',
    position: 'relative',
  },

  editorContainer: {
    xborderTop: '#333 solid 10px',
    backgroundColor: '#ccc',
    margin: 0,
    padding: 0,
    xheight: '20vh', // -- this is set as an attribute
    overflow: 'auto',
    flex: '0 0 auto', // ensures vh is calculated properly
  },

  dragBar: {
    height: '10px',
    backgroundColor: '#c3c3c3f7',
    cursor: 'grab',
    borderTop: '#e0e0e0 10px dashed',
    zIndex: '100',
    position: 'sticky',
    top: 0,
    left: 0,
  },

  fabs: {
    position: 'absolute',
    bottom: '20px',
    left: '10px',
    zIndex: '343333',
    backgroundColor: '#404040',
    padding: '5px',
    border: '1px solid #ccc',
    color: '#fff',
    borderRadius: '5px',
  },

  fab: {
    margin: theme.spacing(1),
  },
}));

const getTags = (url) => {
  Logger.debug('getting', url);
  return apit
    .get(url)
    .then((response) => response.data)
    .then((responseJson) => {
      // TODO   handle this properly on api and here with msg
      if (responseJson.status === false) {
        return { sections: [], references: [], brands: [], products: [] };
      }
      return responseJson;
    })
    .catch((error) => {
      Logger.error(error);
    });
};

const getBrandData = async (bid) => {
  const response = await apit.get(`/v2/brand/${bid}/summary`);
  if (!response.data) {
    Logger.debug('brand not found! ');
    return null;
  }

  return response.data;
};

const getProductData = async (pid) => {
  const response = await apit.get(`/v2/product/${pid}/summary`);
  if (!response.data) {
    Logger.debug('product not found! ');
    return null;
  }

  return response.data;
};

// return the tags on a requested page.
function pageTags(tags, materials, page, resourceId) {
  const pt = [];
  Logger.debug('retrieving tags for page', { page, tags, materials });

  if (tags.sections) {
    tags.sections.forEach((tag, index) => {
      if (tag.start.page === page) {
        pt.push({
          text: tag.text,
          coords: tag.start.box,
          id: `${tag.id}`,
          type: 's',
          synonym: tag.synonym,
          scoreData: tag.scoreData,
        });
      }
    });
  }

  if (tags.brands) {
    tags.brands.forEach((tag, index) => {
      let location = tag.location;
      if (!location) {
        location = {
          page: tag.page,
          top: tag.top,
          bottom: tag.bottom,
          left: tag.left,
          right: tag.right,
        };
      }
      if (location.page === page) {
        pt.push({
          text: tag.text,
          coords: location,
          id: `${tag.id}`,
          type: 'b',
          synonym: tag.synonym,
          scoreData: tag.scoreData,
        });
      }
    });
  }

  if (tags.products) {
    tags.products.forEach((tag, index) => {
      let location = tag.location;
      if (!location) {
        location = {
          page: tag.page,
          top: tag.top,
          bottom: tag.bottom,
          left: tag.left,
          right: tag.right,
        };
      }
      if (location.page === page) {
        pt.push({
          text: tag.text,
          coords: location,
          id: `${tag.id}`,
          type: 'p',
          synonym: tag.synonym,
          scoreData: tag.scoreData,
        });
      }
    });
  }

  (materials || []).forEach((tag, index) => {
    if (tag.tag.page === page && tag.tag.resourceId === resourceId) {
      const coords = {
        left: tag.tag.left,
        right: tag.tag.right,
        bottom: tag.tag.bottom,
        top: tag.tag.top,
      };
      let type = 'mp';
      if (tag.type === 'brand') {
        type = 'mb';
      }
      if (tag.type === 'material') {
        type = 'ms';
      }
      pt.push({
        coords,
        id: tag.tag.id,
        type,
        synonym: tag.synonym,
        scoreData: tag.scoreData,
      });
    }
  });

  // console.log('PTs', pt);
  return pt;
}

//
//  Explorer is the 'App' level of the PDF Viewer / Editor
//  Therefore, it contains the primary data store.
//
//  There are a number of different items we store here:
//  1.  Project / Resource information - What we are working on.
//      (no need to save in state, since it is set only on component load)

//  2.  Application information

//  3.  Tags
//  4.  Materials

export default function Explorer(props) {
  Logger.debug('Explorer:constructor()');

  const classes = useStyles();
  const mainRef = useRef();

  // props:>
  //  project:  the projectid
  //  file:     the resourceid
  //  page: the page to initially go to. (optional)
  //  top: the position on the page to go to. (optional)
  const { project, file, page, top } = props.match.params;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [forceTextRender, setForceTextRender] = useState(false);
  const [showTags, setShowTags] = useState({
    bad: false,
    good: false,
    best: false,
    scores: false,
  });

  const [activeResource, setActiveResource] = useState({
    projectId: project,
    resourceId: file,
    projectName: 'loading resource...',
    resourceName: 'please wait...',
    document: `/api/v2/project/${project}/${file}`,
    tagFile: `/v2/project/${project}/${file}/data`,
  });

  // Tags / Materials
  const extractedData = {
    tags: {
      sections: [],
      references: [],
      brands: [],
      products: [],
    },
    materials: ProjMats.initialize(activeResource.projectId),
  };

  const [datastore, setDataStore] = useState(extractedData);

  const setMaterials = async (mats) => {
    // here, we merge the datastore on setDataStore, but we need to
    // model the shape of the outer object.. there should be a better way to do that... right?
    const edm = {
      materials: mats,
    };
    Logger.debug('*** settingMaterials', edm);
    setDataStore((prevDS) => {
      return { ...prevDS, ...edm };
    });
  };

  useEffect(() => {
    async function getAll() {
      const mats = await ProjMats.loadMats(activeResource.projectId);
      const tagdata = await getTags(activeResource.tagFile);

      // set the resource
      const upd = {
        resourceName: tagdata.resource,
        projectName: tagdata.project,
      };
      setActiveResource((prevAR) => ({ ...prevAR, ...upd }));

      // set the tag/material
      const ds = { materials: mats, tags: tagdata.tags };
      console.log('********************   THE DATASTORE IS SET', ds);
      setDataStore(ds);
      setDataLoaded(true);
    }

    Logger.debug('useEffect::materials');
    getAll();
    // getMats();
    // getTheTags();
  }, []);
  // useEffect(() => {
  //   Logger.debug('Explorer.hasData is false getting data');
  // }, []);

  const updateTheMaterial = async (currMat) => {
    await setMaterials(ProjMats.updateMaterial(datastore.materials, currMat));
  };
  const createNewMaterial = async () => {
    Logger.debug('creating new material');

    const newMaterial = initializeMaterial(project);

    newMaterial.title = 'Unknown Material';
    Logger.debug('**** NEW MAT IS: ', newMaterial);
    const updmat = ProjMats.updateMaterial(datastore.materials, newMaterial);
    setMaterials(ProjMats.setCurrentMaterial(updmat, newMaterial.id));
  };

  const duplicateMaterial = async (materialIdToDup) => {
    /// TODO: this does not actually save the material... nor does createNewMaterial
    // ... unless something is edited on it.

    const matToDup = ProjMats.getCurrentMaterial(datastore.materials);
    if (matToDup) {
      const newMat = ProjMats.duplicateMaterial(datastore.materials, matToDup);

      console.log('dupdata', { matToDup, newMat });
      const updmat = ProjMats.updateMaterial(datastore.materials, newMat);
      const mats = ProjMats.setCurrentMaterial(updmat, newMat.id);
      await setMaterials(mats);
    }
  };

  //
  // end material ops

  const gmtags = ProjMats.getMaterialTags(datastore.materials);
  const getPageTags = (pageNumber) => {
    console.log('called get page tags for page#', { pageNumber, datastore });
    return pageTags(datastore.tags, gmtags, pageNumber, file);
  };

  //
  // end tags ops

  const [modalState, setModalState] = useState({
    modalIsOpen: false,
    modelObject: {
      name: '',
    },
    showBrand: false,
    showProduct: false,
  });

  //
  //  only manages the material editor display.  yes/no
  const [showCurrentMaterial, setShowCurrentMaterial] = useState(false);

  const handleTraverseLocation = (location) => {
    Logger.info('HANDLE TRAVERSE', location);
    if (location.location) {
      // old vs new format
      mainRef.current.traverseLocation(location.location);
    } else {
      mainRef.current.traverseLocation(location);
    }
  };

  //  function handleTraverseLocation(location) { mainRef.current.traverseLocation(location); }
  function handleResize(typ, size) {
    mainRef.current.handleResize(typ, size);
  }

  // TODO: this needs to add the tag to our list of tags
  async function recordTag(tag) {
    Logger.debug('Explorer.recordTag() NOOP!');
    return null;
  } // we dont do this yet.. see old code that probably is no good anymore
  async function removeTag() {
    Logger.debug('Explorer.removeTag() NOOP!');
    return null;
  } // we dont do this yet.. see old code that probably is no good anymore
  function manageTag(tag) {
    Logger.debug('Explorer.manageTag() NOOP!');
    return null;
  }
  function getTag(tagdata) {
    Logger.debug('Explorer.getTag()', tagdata);
  }

  async function handleTagClick(tag) {
    const { tags } = datastore.tags;
    if (!tag || !tag.type) {
      Logger.debug('invalid tag given', tag);
      return '';
    }
    if (tag.type === 'p') {
      const t = tags.products.find((b) => b.id === tag.id);

      const resp = await getProductData(t.inspexId);
      Logger.debug('we got this product info', resp);
      return `${resp.brand.name},${resp.brand.dpId},${resp.name},${resp.dpId}`;
    }

    if (tag.type === 'b') {
      const t = tags.brands.find((b) => b.id === tag.id);
      const resp = await getBrandData(t.inspexId);
      Logger.debug('we got this brand info', resp);
      return `${resp.name},${resp.dpId}`;
    }

    Logger.debug('invalid tag given', tag);
    return '';
  }

  function onCloseBrand(e) {
    setModalState((prev) => {
      return { ...prev, showBrand: false };
    });
  }
  function onCloseProduct(e) {
    setModalState((prev) => {
      return { ...prev, showProduct: false };
    });
  }

  function onDocumentReady() {
    if (page && top) {
      handleTraverseLocation({
        page,
        box: { top, left: 0, bottom: 0, right: 0 },
      });
    }
  }

  // ProjMats.getCurrentMaterial(datastore.materials)

  Logger.debug('Explorer.render()');
  // TODO:  we may want to delay rendering until we have loaded our data..
  //    that way we reduce rerenders..
  if (!dataLoaded) {
    return <h1>Loading....</h1>;
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        title={`${activeResource.projectName} > ${activeResource.resourceName}`}
      />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.toolbar} />
        <div className={classes.sidebar}>
          <ExplorerSideBar
            tags={datastore.tags}
            projectId={activeResource.projectId}
            projectName={activeResource.projectName}
            fileName={activeResource.resourceName}
            onTraverse={handleTraverseLocation}
            onResize={handleResize}
            materials={datastore.materials}
            setMaterials={setMaterials}
            forceTextRender={forceTextRender}
            onForceTextRender={(value) => setForceTextRender(value)}
            showTags={showTags}
            onToggleTags={(value) => setShowTags(value)}
          />
        </div>
      </Drawer>
      <Container maxWidth={false} className={classes.mainContainer}>
        <Container maxWidth={false} className={classes.rightContainer}>
          <Box
            className={classes.pdfContainer}
            height="100vh"
            overflow="scroll"
          >
            <Box className={classes.fabs}>
              <Box>Current Material:</Box>
              <Box>
                {ProjMats.getCurrentMaterial(datastore.materials)
                  ? ProjMats.getCurrentMaterial(datastore.materials).title
                  : ''}
                <Fab
                  size="small"
                  color="primary"
                  aria-label="Create"
                  title="Create New Material"
                  className={classes.fab}
                  onClick={(e) => createNewMaterial()}
                >
                  <AddIcon />
                </Fab>
                <Fab
                  size="small"
                  color="primary"
                  aria-label="Create"
                  title="Duplicate Material"
                  className={classes.fab}
                  onClick={(e) => duplicateMaterial()}
                >
                  <DupIcon />
                </Fab>
                <Fab
                  size="small"
                  color="primary"
                  aria-label="Show"
                  title="Show Current Material"
                  className={classes.fab}
                  onClick={(e) => setShowCurrentMaterial(true)}
                >
                  <EditIcon />
                </Fab>
              </Box>
            </Box>
            <ExplorerMain
              forceTextRender={forceTextRender}
              showTags={showTags}
              resourceid={activeResource.resourceId}
              document={activeResource.document}
              key={activeResource.resourceId}
              ref={mainRef}
              recordTag={recordTag}
              manageTag={manageTag}
              getTag={getTag}
              handleTagClick={handleTagClick}
              gotoLocation={handleTraverseLocation}
              currentMaterial={ProjMats.getCurrentMaterial(datastore.materials)}
              updateMaterial={updateTheMaterial}
              getPageTags={getPageTags}
              showCurrentMaterial={showCurrentMaterial} // Material editor display state
              onShowMaterialChange={setShowCurrentMaterial} // Set the material editor display state
              materials={datastore.materials}
              setMaterials={setMaterials}
              onDocumentReady={onDocumentReady}
            />
          </Box>
        </Container>
      </Container>
      <BrandDialog
        open={modalState.showBrand}
        onRemove={removeTag}
        onClose={onCloseBrand}
        brand={modalState.modelObject}
        handleTagClick={handleTagClick}
      />
      <ProductDialog
        open={modalState.showProduct}
        onRemove={removeTag}
        onClose={onCloseProduct}
        product={modalState.modelObject}
        handleTagClick={handleTagClick}
      />
    </div>
  );
}
