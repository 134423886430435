import apit from '../lib/api';

const download = async (projectId, fileId, fileName) => {
  if (projectId) {
    await apit.download(
      `/v2/project/${projectId}/${fileId}`,
      `${fileName}.pdf`
    );
  }
};

const rescan = async (projectId, fileId) => {
  if (projectId && fileId) {
    const response = await apit.post(`/v2/project/${projectId}/${fileId}/scan`);
    const body = await response.data;
    if (response.status !== 200) {
      throw new Error(body.message);
    } else {
      return true;
    }
  }
};

const reprocess = async (projectId, fileId) => {
  if (projectId && fileId) {
    const response = await apit.post(
      `/v2/project/${projectId}/${fileId}/process`
    );
    const body = await response.data;
    if (response.status !== 200) {
      throw new Error(body.message);
    } else {
      return true;
    }
  }
};

const remove = async (projectId, fileId) => {
  if (projectId && fileId) {
    const stat = await apit.delete(`/v2/project/${projectId}/${fileId}`);
    if (stat && stat.data && !stat.data.errors) {
      return true;
    }
    return false;
  }
};

export default {
  download,
  rescan,
  reprocess,
  remove,
};
