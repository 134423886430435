import React, { useState } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Switch from '@material-ui/core/Switch';
import Visibility from '@material-ui/icons/Visibility';
import apit from '../lib/api';
import history from '../lib/history';

const tableIcons = {
  Add: AddBox,
  Check,
  Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage,
  LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn,
  Visibility,
};

//
//  ObjectList:  Shows a list of Products or Brands
//

let referenceId = '';
let objectType = 'brand';
let setState = null;

const updateIgnoredStatus = async (record, status) => {
  record.ignored = status;
  const response = await apit.put(`/v2/${objectType}/${record.id}`, record);
  const body = await response.data;
  if (body.status) {
    setState({});
  } else {
    alert(`Failed to update ${objectType} record`);
  }
};

const handleBrandClick = async (e, id) => {
  e.preventDefault();
  history.push(`/${objectType}/${id}`);
};

const handleQuery = async (query) => {
  console.log('queryis', query);
  return new Promise((resolve, reject) => {
    const params = {};
    query.filters.forEach((filter) => {
      if (filter.value.length > 0) {
        params[filter.column.field] = filter.value[0];
      }
    });
    if (query.search) {
      params.search = query.search;
    }
    if (referenceId && objectType === 'product') {
      params.referenceid = referenceId;
    }

    console.log('params are', params);

    apit.get(`/v2/${objectType}/list/${query.page}/${query.pageSize}`, params)
      .then(response => response.data)
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.count,
        });
      });
  });
};

const ObjectList = (props, context) => {
  [, setState] = useState(0);
  objectType = props.objectType;
  referenceId = props.referenceId;

  return (
    <MaterialTable
      icons={tableIcons}
      title={props.label}
      columns={[
        { title: 'Name', field: 'name', filtering: false },
        { title: 'Synonym',
          field: 'synonyms',
          render: rowData => (
            (rowData.parentId) ? 'Yes' : 'No'
          ),
          lookup: { 1: 'Include Synonyms' },
        },
        { title: 'Ignored',
          field: 'ignored',
          render: rowData => (
            <Switch
              checked={rowData.ignored}
              onChange={(event, stat) => { updateIgnoredStatus(rowData, stat, setState); }}
              value="checkedB"
              color="primary"
            />
          ),
          lookup: { 1: 'Ignored Only' },
        },
      ]}
      actions={[
        {
          icon: Visibility,
          tooltip: 'View Records',
          onClick: (event, rowData) => handleBrandClick(event, rowData.id),
        },
      ]}
      options={{
        actionsColumnIndex: -1,
        filtering: true,
        debounceInterval: 1000,
      }}
      data={query => handleQuery(query)}
    />
  );
};

export default ObjectList;
