import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import UploadIcon from '@material-ui/icons/CloudUpload';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Tooltip from '@material-ui/core/Tooltip';
import HelpDialog from './HelpDialog';
import Auth from '../lib/Auth';
import history from '../lib/history';

import { ButtonStyled } from './Header.styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  link: {
    color: '#333',
    'text-decoration': 'none',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1001,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showHelp, setShowHelp] = React.useState(false);
  const { title } = props;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Auth.logout();
  };

  const handleClick = (event, to) => {
    history.push(to);
  };

  const handleHelp = (event) => {
    setShowHelp(true);
  };

  const onCloseHelp = (event) => {
    setShowHelp(false);
  };

  const isArchiveQueue = location.pathname === '/';
  const isProjectAudit = location.pathname.includes('search');

  const isMenuOpen = Boolean(anchorEl);

  const usermgmt = Auth.hasManager() ? (
    <MenuItem onClick={(event) => handleClick(event, '/usermgmt')}>
      User Management
    </MenuItem>
  ) : (
    ''
  );
  const jobmgmt = Auth.hasManager() ? (
    <MenuItem onClick={(event) => handleClick(event, '/jobs')}>
      Active Jobs
    </MenuItem>
  ) : (
    ''
  );
  const brandmgmt = Auth.hasManager() ? (
    <MenuItem onClick={(event) => handleClick(event, '/brands')}>
      Brands
    </MenuItem>
  ) : (
    ''
  );
  const productmgmt = Auth.hasManager() ? (
    <MenuItem onClick={(event) => handleClick(event, '/products')}>
      Products
    </MenuItem>
  ) : (
    ''
  );
  const archivemgmt = Auth.hasManager() ? (
    <MenuItem onClick={(event) => handleClick(event, '/archived')}>
      Archived
    </MenuItem>
  ) : (
    ''
  );
  const historymgmt = Auth.hasManager() ? (
    <MenuItem onClick={(event) => handleClick(event, '/projecthistory')}>
      Proj History
    </MenuItem>
  ) : (
    ''
  );

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {usermgmt}
      {jobmgmt}
      {brandmgmt}
      {productmgmt}
      {archivemgmt}
      {historymgmt}
      {/* <MenuItem onClick={this.handleClose}>Profile</MenuItem>
      <MenuItem onClick={this.handleClose}>My account</MenuItem> */}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  let uploadbtn = '';
  if (Auth.hasManager()) {
    uploadbtn = (
      <Tooltip title="Create Project">
        <IconButton
          color="inherit"
          onClick={(event) => handleClick(event, '/create')}
        >
          <UploadIcon />
        </IconButton>
      </Tooltip>
    );
  }

  // TODO:  zIndex 1201 is a hack get get appbar to the top,
  //        figure out why the class doesnt define properly.
  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ zIndex: '1201' }}
      >
        <Toolbar className={classes.toolbar}>
          <div style={{ maxWidth: 600 }}>
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
              noWrap
            >
              {`Inspex :: ${title}`}
            </Typography>
          </div>
          <div>
            {Auth.hasManager() && (
              <>
                <ButtonStyled
                  active={isProjectAudit}
                  color="inherit"
                  onClick={(event) => handleClick(event, '/searches')}
                >
                  Projects Audit
                </ButtonStyled>
                <ButtonStyled
                  active={isArchiveQueue}
                  color="inherit"
                  onClick={(event) => handleClick(event, '/')}
                >
                  Archive Queue
                </ButtonStyled>
              </>
            )}
            {/* {uploadbtn} */}
            {Auth.hasManager() && (
              <Tooltip title="Dictionary">
                <IconButton
                  color="inherit"
                  onClick={(event) => handleClick(event, '/dictionary')}
                >
                  <MenuBookIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Help">
              <IconButton
                color="inherit"
                onClick={(event) => handleHelp(event)}
              >
                <HelpIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Settings / User Management">
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : null}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <div>
          {renderMenu}
          <HelpDialog open={showHelp} onClose={onCloseHelp} />
        </div>
      </AppBar>
      <div style={{ marginTop: '64px' }} />
    </>
  );
}

PrimarySearchAppBar.propTypes = {
  title: PropTypes.string.isRequired,
};
