import format from 'date-fns/format';
import apit from '../lib/api';

const getAll = async (options) => {
  const { page, perPage } = options;
  const response = await apit.get(`/v2/searches`, {
    page: page || null,
    perPage: perPage || null,
  });
  const body = await response.data;
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body;
};

const getSearch = async (searchId) => {
  const response = await apit.get(`/v2/searches/${searchId}`);
  const body = await response.data;
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body;
};

const getMatches = async (searchId, options) => {
  if (searchId) {
    const { page, perPage, status, dateRange, orderBy, query } = options;
    let startDate = null;
    let endDate = null;

    if (dateRange) {
      startDate = format(dateRange.startDate, 'yyyy-MM-dd');
      endDate = format(dateRange.endDate, 'yyyy-MM-dd');
    }

    const response = await apit.get(`/v2/searches/${searchId}/matches`, {
      page: page || null,
      perPage: perPage || null,
      startDate: startDate || null,
      endDate: endDate || null,
      status: status || null,
      orderBy: orderBy || null,
      query: query || null,
    });
    const body = await response.data;
    if (response.status !== 200) {
      throw new Error(body.message);
    }
    return body;
  }
};

export default {
  getAll,
  getSearch,
  getMatches,
};
