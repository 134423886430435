import React from 'react';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  tagSelect: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  outer: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: '16px',
    marginLeft: '5px',
    width: '100%',
    maxWidth: '1000px',
  },

  label: {
    position: 'relative',
    top: 0,
    left: 0,
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    marginBottom: '5px',
  },
}));


const selectStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', fontSize: '14px' }),
  option: styles => ({ ...styles, fontSize: '14px', padding: '5px 10px' }),
};


function getByIds(matIds, definedMaterials) {
  const matches = matIds.map((matId) => {
    const refMat = definedMaterials.materialIds[matId];
    if (refMat) {
      return {
        label: refMat.title,
        value: refMat.id,
      };
    }
    return null;
  }).filter(gmat => (gmat));
  return matches;
}

function getMaterialList(materials) {
  const items = [];
  Object.keys(materials.materialIds).map((k) => {
    if (k !== materials.currentMaterialId) {
      const mat = materials.materialIds[k];
      items.push({ label: mat.title, value: mat.id})
    }
  });
  return items;
}


//
//  No state needed on this component
//
export default function MaterialList(props) {
  const { label, existing, references, materials, onChange } = props;
  const classes = useStyles();

  let currentList = [];
  // we get in a list of ids, we need to find the id name as well
  if (existing && Array.isArray(existing)) {
    currentList = existing.map(tag => ({ label: tag, value: tag }));
  }
  currentList = getByIds(existing, materials);
  const tagList = getMaterialList(materials);

  const handleChange = (newValue: any, actionMeta: any) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();

    const list = (newValue) ? newValue.map(v => v.value) : [];

    onChange(list);
  };
  return (
    <FormControl className={classes.tagSelect}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <Select
        styles={selectStyles}
        options={tagList}
        defaultValue={currentList}
        isMulti
        onChange={handleChange}
        menuPlacement="top" // auto does not seem to work
      />
    </FormControl>
  );
}


MaterialList.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  references: PropTypes.arrayOf(PropTypes.number),
  materials: PropTypes.array,
};

MaterialList.defaultProps = {
  references: [],
  materials: [],
};
