/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const ButtonStyled = styled(Button)`
  position: relative;
  display: 'inline-block';
  margin-left: 16px !important;

  &:after {
    position: absolute;
    content: ' ';
    display: ${(props) => (props.active ? 'block' : 'none')};
    height: 3px;
    width: 95%;
    bottom: 5px;
    background-color: #fff;
  }
`;
