import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AlertDialog from '../components/AlertDialog';
import ResourceActionsMenu from './ResourceActionsMenu';
import projectsService from '../services/projects';
import resourcesService from '../services/resources';
import Auth from '../lib/Auth';

import {
  ProjectResourcesContainer,
  ProjectResourcesTable,
} from './ProjectResources.styles';

export default function ProjectResources({ projectId, onHistoryClick }) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const isStatusReady = (row) => {
    return row && (row.status_message || '').includes('READY');
  };

  const handleOnPreview = (row) => {
    // status: 2 means READY
    if (row && projectId && row.id && isStatusReady(row)) {
      // eslint-disable-next-line no-undef
      window.open(`/#/explorer/projects/${projectId}/${row.id}`, '_blank');
    }
  };

  const isRowSelected = (row) => {
    return row && !!selected.find((s) => s.id === row.id);
  };

  const handleSelectRow = (row) => {
    if (isStatusReady(row)) {
      const exists = selected.find((s) => row.id === s.id);
      if (!exists) {
        setSelected([...selected, { id: row.id, fileName: row.name }]);
      } else {
        setSelected(selected.filter((s) => s.id !== row.id));
      }
    }
  };

  const hasSelected = () => {
    return !!selected.length;
  };

  const isAllSelected = () => {
    return selected.length === resources.length;
  };

  const handleSelectAll = () => {
    if (isAllSelected()) {
      setSelected([]);
    } else {
      const newSelected = resources
        .filter(isStatusReady)
        .map((r) => ({ id: r.id, fileName: r.name }));
      setSelected(newSelected);
    }
  };

  const handleBulkDownload = () => {
    if (selected.length) {
      enqueueSnackbar('File download was requested, and should begin shortly', {
        variant: 'success',
      });
      selected.forEach((file) => {
        resourcesService.download(projectId, file.id, file.fileName);
      });
      setSelected([]);
    }
  };

  const handleBulkRemove = async (confirm) => {
    if (confirm && selected.length) {
      const promises = selected.map((file) =>
        resourcesService.remove(projectId, file.id)
      );
      try {
        await Promise.all(promises);
        enqueueSnackbar('Files removed succesfully', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      }
      setSelected([]);

      await fetchProject();
    }
    setShowAlert(false);
  };

  const fetchProject = async () => {
    setLoading(true);
    if (projectId) {
      try {
        const response = await projectsService.get(projectId);
        setResources(response?.files || []);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        throw e;
      }
    }
  };

  useEffect(() => {
    (async () => {
      await fetchProject();
    })();
  }, []);

  return (
    <>
      <AlertDialog
        open={showAlert}
        onClose={(confirm) => handleBulkRemove(confirm)}
        confirm="Remove"
        refuse="Cancel"
        message="You are about to remove files, this action cannot be undo. Are you sure you want to remove them?"
        title="Remove Files"
      />
      <ProjectResourcesContainer>
        {loading && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '30px auto',
            }}
          >
            <CircularProgress disableShrink />
          </Box>
        )}
        {!loading && (
          <>
            <ProjectResourcesTable>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!hasSelected()}
                      onClick={handleBulkDownload}
                    >
                      Download Selected
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={!hasSelected()}
                      onClick={() => setShowAlert(true)}
                    >
                      Remove Selected
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={hasSelected() && !isAllSelected()}
                      checked={isAllSelected()}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Select All</TableCell>
                  <TableCell align="center">Processed</TableCell>
                  <TableCell align="center">DocType</TableCell>
                  <TableCell align="center"># Pages</TableCell>
                  <TableCell align="center"># Sections</TableCell>
                  <TableCell align="center"># Brands</TableCell>
                  <TableCell align="center"># Products</TableCell>
                  <TableCell align="center">
                    {Auth.hasManager() ? 'Preview/Manage' : 'Preview'}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resources.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isRowSelected(row)}
                        onChange={() => handleSelectRow(row)}
                        disabled={!isStatusReady(row)}
                      />
                    </TableCell>
                    <TableCell>
                      {isStatusReady(row) ? (
                        <span>{row.name}</span>
                      ) : (
                        <span style={{ color: 'gray' }}>{row.name}</span>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {isStatusReady(row) ? (
                        <span style={{ color: 'green' }}>
                          {row.status_message}
                        </span>
                      ) : (
                        <span style={{ color: 'gray' }}>
                          {row.status_message}
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {isStatusReady(row) ? row.metadata.doctype : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      {isStatusReady(row) ? row.metadata.pages : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      {isStatusReady(row) ? row.metadata.actions : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      {isStatusReady(row) ? row.metadata.brands : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      {isStatusReady(row) ? row.metadata.products : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ display: 'flex' }}>
                        <IconButton
                          color={isStatusReady(row) ? 'primary' : 'default'}
                          disabled={!isStatusReady(row)}
                          onClick={() => handleOnPreview(row)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        {Auth.hasManager() && (
                          <ResourceActionsMenu
                            projectId={projectId}
                            fileId={row.id}
                            fileName={row.name}
                            status={row.status_message}
                            onResourceChange={async () => {
                              await fetchProject();
                            }}
                            onHistoryClick={() =>
                              onHistoryClick && onHistoryClick(row.id)
                            }
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </ProjectResourcesTable>
          </>
        )}
      </ProjectResourcesContainer>
    </>
  );
}

ProjectResources.propTypes = {
  projectId: PropTypes.string.isRequired,
  onHistoryClick: PropTypes.func.isRequired,
};
