import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import PDFViewer from './PDFViewer';
import PdfLoader from '../components/PDFLoader';
import * as Logger from '../lib/logger';

//
//  ExplorerMain contains the pdf viewer, and the pdf loader
//
const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
}));

export default forwardRef((props, ref) => {
  const classes = useStyles();
  const viewer = useRef();

  const viewerReady = () => {
    return viewer.current;
  };

  useImperativeHandle(ref, () => ({
    // these get pressed on the Explorer
    traverseLocation(location) {
      Logger.debug('ExplorerMain::traverseLocation()');
      if (viewerReady()) {
        Logger.debug(
          'ExplorerMain::traverseLocation() traversing to',
          location
        );
        viewer.current.scroller(location);
        viewer.current.highlighter(location);
      }
    },

    handleResize(typ, size) {
      if (viewerReady()) {
        viewer.current.handleResize(typ, size);
      }
    },
  }));

  const { document, resource, resourceid } = props;

  //
  // this is used for material editor
  //
  function addContentAsTag(tag, type) {
    Logger.debug('ExplorerMain.addContentAsTag', { tag, type });
    const thetag = {
      pageNumber: tag.page,
      boundingBox: {
        left: tag.left,
        right: tag.right,
        bottom: tag.bottom,
        top: tag.top,
      },
      name: tag.text,
      type,
    };
    addPageTag(thetag);
    viewer.current.renderHighlights(tag.page);
  }
  // TODO:
  function onHighlightClick(tag) {
    const { manageTag } = props;
    manageTag(tag);
  }

  async function addPageTag(tag) {
    const { recordTag } = props;

    Logger.debug('Explorer.addPageTag()', tag);

    let typ = '';
    if (tag.type === 'brand') {
      typ = 3;
    } else if (tag.type === 'product') {
      typ = 4;
    } else if (tag.type === 'material') {
      typ = 1;
    } else {
      typ = tag.type;
    }

    // if (tag.type === 1) // CSI.
    // if (tag.type === 2) { // Part
    if (typ === 3) {
      // brand
      Logger.debug('Adding Brand');
      await recordTag(tag);
      // have to rerender the sidebar.
    } else if (typ === 4) {
      // product
      Logger.debug('Adding Product');
      await recordTag(tag);
      // have to rerender the sidebar.
    } else {
      Logger.debug('Unsupported tag type', tag);
    }
  }

  Logger.debug('ExplorerMain::render()');
  return (
    <PdfLoader
      key={document}
      url={document}
      beforeLoad={
        <CircularProgress
          style={{
            position: 'absolute',
            color: 'white',
            top: '50%',
            left: '50%',
          }}
        />
      }
    >
      {(pdfDocument) => (
        <PDFViewer
          forceTextRender={props.forceTextRender}
          showTags={props.showTags}
          resource={resource}
          resourceid={resourceid}
          pdfDocument={pdfDocument}
          ref={viewer}
          getPageTags={props.getPageTags}
          currentScaleValue="auto"
          onHighlightClick={onHighlightClick}
          onHandleTagClick={props.handleTagClick}
          getTag={props.getTag}
          updateMaterial={props.updateMaterial}
          gotoLocation={props.gotoLocation}
          currentMaterial={props.currentMaterial}
          showCurrentMaterial={props.showCurrentMaterial}
          addContentAsTag={addContentAsTag}
          onShowMaterialChange={props.onShowMaterialChange}
          materials={props.materials}
          setMaterials={props.setMaterials}
          onDocumentReady={props.onDocumentReady}
        />
      )}
    </PdfLoader>
  );
});
