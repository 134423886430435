/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* global window, document */
import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import ReactDom from 'react-dom';

import 'pdfjs-dist/web/pdf_viewer.css';

import MaterialEditor from '../Material/MaterialEditor';
import { getPageFromRange } from '../lib/pdfjsdom';
import getBoundingRect from '../lib/get-bounding-rect';
import getClientRects from '../lib/get-client-rects';
import panner from '../lib/pan';

const PDFJSviewer = require('pdfjs-dist/web/pdf_viewer.js');

const logger = require('../lib/logger');

function expandSelection(originalText, selection) {
  //
  // the highlights of selections do not always match the actual
  // text below it.  This is due to fonts with variable character width.

  // to combat this, we do the following...
  // testing...
  // see if the start of the word is cut off...
  const range = selection.getRangeAt(0);
  let chkPos = range.startOffset;
  const theNode = range.startContainer;
  let prepend = '';
  while (chkPos > 0) {
    chkPos -= 1;
    const ch = theNode.textContent[chkPos];
    if (/^\w/.test(ch)) {
      prepend = ch + prepend;
    } else {
      break;
    }
  }

  //
  // we also want the end of the text
  //
  let endPos = range.endOffset;
  const theEndNode = range.endContainer;
  let postpend = '';
  while (endPos < theEndNode.length) {
    const ch = theEndNode.textContent[endPos];
    if (/^\w/.test(ch)) {
      postpend += ch;
    } else {
      break;
    }
    endPos += 1;
  }
  return prepend + originalText + postpend;
}
//
//  Gets any text selected by the browser window
//
function getSelectionText() {
  let txt = '';
  if (window.getSelection) {
    // this should be all browsers except IE before v9
    const selection = window.getSelection();
    txt = selection.toString();

    txt = expandSelection(txt, selection);
  } else if (document.selection && document.selection.type !== 'Control') {
    txt = document.selection.createRange().text;
    logger.debug('document.selection(): ', txt);
  }
  return txt.replace('\n', ' ');
}

//
// copies current selection (if any) to the clipboard
//
function copySelectionToClipboard() {
  const stat = document.execCommand('copy');
  if (!stat) {
    logger.warn('copySelectionToClipboard:: Failed to copy to clipboard.');
    return false;
  }
  return true;
}

//
//  copies text to the clipboard.
//  this must be triggered by user interaction, and
//  will not work if progammatically triggered, e.g post ajax
//
function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  copySelectionToClipboard();
  document.body.removeChild(el);
}

//
// convert our pdf coordinates to screen coordinates.
// viewport handles most of this, except the top/bottom coordinate differences
// after converting, we need to change from pdf coords (bottom-up) to canvas coords (top-down)
//
function pdfToViewport(box, pageView) {
  const { viewport, canvas } = pageView;

  // fsck.. this is so inconsistent.
  let bx = box;
  if (box.box) {
    bx = box.box;
  }

  const pdfBox = [bx.left, bx.top, bx.right, bx.bottom];

  // logger.debug('**** PDFViewer:pdfToViewport()', { pdfBox, viewport, canvas });

  // if our document is landscape oriented we have to calculate positioning a bit different
  const landscape = viewport.rotation > 0;
  if (viewport.rotation !== 0 && viewport.rotation !== 90) {
    logger.warn(
      'VIEWPORT rotation is not 0 or 90! I want to see this document!',
      viewport.rotation
    );
  }

  let top = 0;
  let left = 0;
  let bottom = 0;
  let right = 0;
  let realTop = 0;
  if (landscape) {
    [top, left, bottom, right] = viewport.convertToViewportRectangle(pdfBox);
    realTop = top;
  } else {
    [left, top, right, bottom] = viewport.convertToViewportRectangle(pdfBox);
    realTop = canvas.height - top;
  }
  const height = Math.abs(top - bottom);
  const width = right - left;

  return { left, top: realTop, width, height };
}

//
// Convert screen coordinates to PDF coordinates
//
function viewportToPdfPoint(box, pageView) {
  const { viewport, canvas } = pageView;
  const [left, top] = viewport.convertToPdfPoint(
    box.left,
    canvas.height - box.top
  );
  const r = box.left + box.width;
  const b = canvas.height - (box.top + box.height);
  const [right, bottom] = viewport.convertToPdfPoint(r, b);

  return { left, top, right, bottom };
}

function findOrCreateContainerLayer(container, className) {
  let layer = container.querySelector(`.${className}`);

  if (!layer) {
    layer = document.createElement('div');
    layer.className = className;
    container.appendChild(layer);
  }

  return layer;
}

const DEFAULT_SCALE_DELTA = 1.1;
const MAX_SCALE = 10.0;
const MIN_SCALE = 0.1;

export default forwardRef((props, ref) => {
  logger.debug('PDFViewer.constructor()', props);
  const highlightRef = useRef();

  const [state, setState] = useState({
    highlight: null,
    popperAnchorEl: null,
    selectionText: '',
    selectionLocation: {},
  });

  const [hasTextSelection, setHasTextSelection] = useState(false);
  const [highlightedTag, setHighlightedTag] = useState(null);

  let containerNode = React.createRef();

  const { pdfDocument, currentScaleValue, forceTextRender, showTags } = props;

  const linkService = null;

  let viewer = null;
  // console.log('****** VIEWER STATE IS NULL', viewerState)
  const [viewerState, setViewer] = useState(null);

  // zooom/setscale are also in explorer... only one please!
  const zoomIn = (ticks) => {
    const vs = viewer || viewerState; // WTF is this?? why is this the case?
    let newScale = vs.currentScale;
    do {
      newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
      newScale = Math.ceil(newScale * 10) / 10;
      newScale = Math.min(MAX_SCALE, newScale);
    } while (--ticks > 0 && newScale < MAX_SCALE);
    vs.currentScaleValue = newScale;
  };

  const zoomOut = (ticks) => {
    const vs = viewer || viewerState; // WTF is this?? why is this the case?
    let newScale = vs.currentScale;
    do {
      newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
      newScale = Math.floor(newScale * 10) / 10;
      newScale = Math.max(MIN_SCALE, newScale);
    } while (--ticks > 0 && newScale > MIN_SCALE);
    vs.currentScaleValue = newScale;
  };

  //
  //  renderNudge
  //  try to move to the coordinates to better fit the content
  const renderNudge = (pageNumber, pdfCoords) => {
    console.log('*********** VIEWER STATE RENDERNUDGE', viewerState);
    const pageView = viewerState.getPageView(pageNumber - 1);

    if (!pageView.canvas) {
      logger.debug(
        '***** We do not have the canvas on the page.  cant fine-tune our position'
      );
      return;
    }
    logger.debug('coords are ', { pdfCoords, pageView });
    const viewCoords = pdfToViewport(pdfCoords, pageView);

    logger.debug('The pv is:', { pageView, viewCoords });

    const vb = pageView.viewport.viewBox;
    const coords = { top: vb[0], left: vb[1], height: vb[2], width: vb[3] };

    const ppt = viewportToPdfPoint(coords, pageView);

    const diff = viewCoords.top - ppt.bottom;
    if (ppt.bottom < viewCoords.top) {
      viewerState.container.scrollBy(0, diff);
    }
  };

  //
  // future:  pass the position on the page as well.  We need to convert our boundingBox values
  //   to a pdfPoint first to get it to work right (I think)
  // this.viewer.scrollPageIntoView({pageNumber, destArray: [null, {name: 'XYZ'}, 0, top]});
  const onScroll = (coords) => {
    if (!coords.page || coords.page < 1) {
      return;
    }

    logger.debug('************ scrolling to', { coords, viewerState });
    viewerState.scrollPageIntoView({ pageNumber: coords.page });

    // since we are, of course, fscked everywhere... make sure we've got a good object.
    let { box } = coords;
    if (!box && coords.top) {
      box = coords;
    }

    if (!box) {
      logger.debug('trying to onScroll to coords, but we have no box!', coords);
    } else {
      setTimeout(() => {
        renderNudge(coords.page, box);
      }, 500);
    }
  };

  useImperativeHandle(ref, () => ({
    // these get pressed on the Explorer
    scroller(location) {
      logger.debug('PDFViewer::scroller()', viewerState);

      if (viewerState) {
        logger.debug(
          'ExplorerMain::traverseLocation() traversing to',
          location
        );
        onScroll(location);
      }
    },

    highlighter(tag) {
      logger.debug('PDFViewer::highlighter()', tag);
      setHighlightedTag(tag);
    },

    handleResize(typ, size) {
      logger.debug('PDFViewer:handleResize()');
      if (typ === 'zoomin') {
        zoomIn(size);
      } else if (typ === 'zoomout') {
        zoomOut(size);
      } else if (typ === 'scale') {
        setScaleSize(size);
      } else {
        window.alert('Unknown resize value');
      }
    },

    renderHighlights(pageNumber) {
      renderPageHighlights(pageNumber);
    },
  }));

  useEffect(() => {
    if (highlightedTag) {
      renderPageHighlights(highlightedTag.page);
    }
  }, [highlightedTag]);

  useEffect(() => {
    //
    // Note: textLayerMode: must be set to 2 in order to be able to
    // actually trigger enhanceTextSelection setting
    // (TextLayerBuilder only uses eTS if this is set to 2)
    //
    logger.debug('****** CONTAINER NODE', containerNode);
    viewer = new PDFJSviewer.PDFViewer({
      container: containerNode,
      removePageBorders: true,
      linkService,
      textLayerMode: 2, // * see note above
      enhanceTextSelection: true,
    });

    viewer.setDocument(pdfDocument);
    // debug  (references to this probably need to be passed to this component instead)
    // ???????????????????????? window.PdfViewer = this;

    viewer.currentScaleValue = currentScaleValue;

    panner(viewer.container);
    console.log('************ THIS IS THE VIEWER', viewer);
    setViewer(viewer);
    //
    // test
    logger.debug('PDFViewer.componentDidMount()', containerNode);
    // end test

    if (containerNode) {
      containerNode.addEventListener('pagesinit', () => {
        onDocumentReady();
      });

      containerNode.addEventListener('textlayerrendered', onTextLayerRendered);
      containerNode.addEventListener('wheel', onWheel, { passive: true });
      // used to see if the click event occurred over a highlighted area
      containerNode.addEventListener('click', handleClick);
    }
  }, []);

  // end compdid mount

  //
  // once the text layer is rendered, we need to highlight our text items
  //
  const onTextLayerRendered = (e) => {
    renderPageHighlights(e.detail.pageNumber);
  };

  //
  //  TODO:  does this only need to be set if it is not set already?
  //
  const onDocumentReady = () => {
    logger.debug('PDFViewer.onDocumentReady()');
    viewer.currentScaleValue = 'auto';
    props.onDocumentReady();
  };

  const onWheel = (e) => {
    // console.log('Wheel Scroll', e)
    if (e.altKey) {
      if (e.deltaY < 0) {
        zoomIn();
      } else if (e.deltaY > 0) {
        zoomOut();
      }
    }
  };

  const closePopper = () => {
    props.onShowMaterialChange(false);
  };

  //
  // This click is over the entire document
  // we want to see if the user has done a couple of things:
  // 1. selected text
  // 2. clicked a highlight-layer item
  //
  // If either occurred, then they are trying to interact with the content
  // The altKey determines how we are interacting...
  //  NO ALTKEY:  The content is copied to the clipboard
  //  ALTKEY:     We want to see the info about the content.
  //
  const handleClick = (e) => {
    logger.debug('PDFViewer:handleClick()', e);
    // was a click made on highlighted area?
    const selectedTxt = getSelection();
    if (selectedTxt) {
      if (e.altKey) {
        logger.debug('SEARCH FOR THE TEXT CONTENT...');
      } else {
        logger.debug('User has selected: ', selectedTxt);
        copySelectionToClipboard();
      }

      // POPPER related
      const selection = window.getSelection();
      const location = getContentLocation(selection);

      const getBoundingClientRect = () =>
        selection.getRangeAt(0).getBoundingClientRect();

      // TODO:   we actually probably DO NOT want to auto open the materialeditor
      if (e.ctrlKey) {
        setState({
          selectionText: selectedTxt,
          selectionLocation: location,
          popperAnchorEl: {
            clientWidth: getBoundingClientRect().width,
            clientHeight: getBoundingClientRect().height,
            getBoundingClientRect,
          },
        });
        props.onShowMaterialChange(true);

        logger.debug('setting the popper state', state.popperAnchorEl);
      } else {
        logger.debug(
          'ALERT: you need to hold the ctrl key down to display editor'
        );
      }
    } else {
      // check if a tag was clicked.
      const hl = checkHighlightByPos(e.clientX, e.clientY);
      if (hl) {
        logger.debug('handleclick', { e, hl });
        // TODO: not sure what this is... we already have a mouse event...
        // we want to show the tag info...
        //  can we get the tag id?
        // if so, can we tie it to the material?
        // otherwise, can we just make it part of the material?
        logger.debug(
          '********************  TODO:  we clicked a tag, make it a material or show the material!!!!'
        );
        // eslint-disable-next-line no-undef
        const event = new MouseEvent('click', {
          altKey: e.altKey,
          bubbles: true,
          cancelable: true,
          view: window,
        });
        logger.debug('handleclick event', event);
        hl.dispatchEvent(event);
      } else {
        // no tag clicked, and no selection
        logger.debug('No selection, setting popper state to closed');
        closePopper();
      }
    }
  };

  const getSelection = () => {
    const selection = window.getSelection();

    if (selection.isCollapsed) {
      logger.debug('getSelection() no selection');
      return null;
    }
    const range = selection.getRangeAt(0);
    if (!range) {
      logger.debug('no range');
      return null;
    }
    return getSelectionText();
  };

  const setScaleSize = (val) => {
    console.log('*********** SETTING SCALE SIZE VIEWERSTATE', viewerState);
    viewerState.currentScaleValue = val;
  };

  const getContentLocation = (selection) => {
    const loc = {
      box: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
      page: 0,
      resource: '',
    };

    const range = selection.getRangeAt(0);

    if (!range) {
      logger.debug('no range');
      return loc;
    }

    const page = getPageFromRange(range);

    if (!page) {
      logger.debug('no page');
      return loc;
    }

    const pageNumber = page.number;

    const rects = getClientRects(range, page.node);

    if (rects.length === 0) {
      logger.debug('no rects');
      return loc;
    }

    const boundingRect = getBoundingRect(rects);
    const pageView = viewer.getPageView(pageNumber - 1);
    // const { left, top, right, bottom } = viewportToPdfPoint(boundingRect, pageView);
    loc.box = viewportToPdfPoint(boundingRect, pageView);
    loc.page = pageNumber;
    loc.resource = props.resourceid;
    return loc;
  };

  const findOrCreateLayer = (pageNumber, layerName) => {
    const vs = viewer || viewerState;
    const { textLayer } = vs.getPageView(pageNumber - 1);

    if (!textLayer) {
      return null;
    }
    const parent = textLayer.textLayerDiv.parentElement;
    return findOrCreateContainerLayer(parent, layerName);
  };

  const getHighlightLayer = (pageNumber) => {
    logger.debug('PDFViewer.getHighlightLayer', pageNumber);
    return findOrCreateLayer(pageNumber, 'highlight-layer');
  };

  const checkHighlightByPos = (x, y) => {
    const hl = getHighlightLayer(viewer.currentPageNumber);
    const allElements = hl.getElementsByClassName('highlight_part');
    const match = Array.from(allElements).find((elm) => {
      const rect = elm.getBoundingClientRect();
      return (
        y >= rect.top && y <= rect.bottom && x >= rect.left && x <= rect.right
      );
    });
    // if (match) {
    //   console.log('checkHighlightByPos::matched ',match);
    // }
    return match;
  };

  //
  // this was implmented for material editor.
  // TODO: hightlighting overall can be cleaned up
  //
  const hightlightSelection = (content) => {
    logger.debug('PDFViewer.hightlightSelection()', content);

    props.addContentAsTag(content.tag, content.type);

    // const pdfPageNumber = content.location.page;
    // const highlight = this.findOrCreateHighlightLayer(pdfPageNumber);
    // const pageView = this.viewer.getPageView(pdfPageNumber);

    // // TODO: needs to ADD to the list, not replace it.
    // ReactDom.render(this.modernRenderTag(content, content.location.box, pageView), highlight);
  };

  //
  //  renders a single tag
  //
  const renderTag = (tag, pageView) => {
    if (!tag.coords) {
      return;
    }
    const coords = pdfToViewport(tag.coords, pageView);
    let hlType = 'highlight_section';

    const score = JSON.parse(tag.scoreData || '{}')?.score || 0;

    if (tag.type === 'b') {
      if (tag.synonym && score < 50) {
        hlType = 'highlight_brand-synonym--bad';
      } else if (tag.synonym && score >= 50 && score < 85) {
        hlType = 'highlight_brand-synonym--good';
      } else if (tag.synonym && score >= 85) {
        hlType = 'highlight_brand-synonym--best';
      } else {
        hlType = 'highlight_brand';
      }
    } else if (tag.type === 'p') {
      hlType = 'highlight_product';
    } else if (tag.type === 'mb') {
      hlType = 'highlight_materialbrand';
    } else if (tag.type === 'mp') {
      hlType = 'highlight_materialproduct';
    } else if (tag.type === 'ms') {
      hlType = 'highlight_materialtitle';
    }

    if (tag.coords.bottom - tag.coords.top > 100) {
      return null;
    }

    // eslint-disable-next-line consistent-return
    return (
      <div
        key={tag.id}
        data-tag={tag.text}
        className={`highlight_part ${hlType} ${
          tag.id === highlightedTag?.id ? 'focused' : ''
        }`}
        style={coords}
        // onMouseOver={(e) => { this.handleTest(tag, e); }}
        onClick={(e) => handleTagClick(tag, e)}
      >
        {/* {tag.text} */}
        {tag.synonym && <span>{score}%</span>}
      </div>
    );
  };

  const findOrCreateHighlightLayer = (pageNumber) => {
    logger.debug('PDFViewer.findOrCreateHighlightLayer', pageNumber);
    return findOrCreateLayer(pageNumber, 'highlight-layer');
  };

  //
  //  Given a set of tags, render them to the screen
  //
  const renderPageTags = (tagsForPage, pageNumber) => {
    logger.debug(
      `***************** PDFViewer.renderPageTags ${pageNumber}`,
      tagsForPage
    );
    // no need to render if there are no tags on this page
    if (tagsForPage.length < 1) {
      return;
    }

    const highlight = findOrCreateHighlightLayer(pageNumber);
    if (highlight) {
      const vs = viewer || viewerState;
      const pageView = vs.getPageView(pageNumber - 1);

      ReactDom.render(
        <div ref={highlightRef}>
          {tagsForPage.map((tag) => renderTag(tag, pageView))}
        </div>,
        highlight
      );
    }
  };

  //
  //  given a page, get and render the highlight tags
  //
  const renderPageHighlights = (pageNumber) => {
    const { pdfDocument, getPageTags } = props;
    // can't render for non-existant page
    if (pageNumber <= 0 || pageNumber > pdfDocument.numPages) {
      return;
    }

    const tagsForPage = getPageTags(pageNumber);
    renderPageTags(tagsForPage, pageNumber);
  };

  const handleTagClick = async (tag, e) => {
    // console.log('HandleTagClick:: ', e);
    if (e.altKey) {
      e.preventDefault();
      props.onHighlightClick(tag);
    } else {
      const t = props.getTag(tag);
      if (t) {
        // console.log('tag data is ', t);
        copyToClipboard(t.name);
      } else {
        // console.log('did not find our tag');
      }
    }
  };

  const { selectionText, selectionLocation } = state;
  logger.debug('PDFViewer.render()', props);

  const handleSelectionChange = (node) => {
    containerNode = node;
    if (node) {
      const handleSelectionEnd = () => {
        setHasTextSelection(false);
        node.removeEventListener('mouseup', handleSelectionEnd);
      };

      const handleSelectionStart = () => {
        setHasTextSelection(true);
        node.addEventListener('mouseup', handleSelectionEnd);
        node.removeEventListener('selectstart', handleSelectionStart);
      };

      node.addEventListener('selectstart', handleSelectionStart);
    }
  };

  const getClasses = () => {
    const selection =
      window.getSelection && window.getSelection().type === 'Range';
    let classes = '';
    if (forceTextRender) {
      classes += ' forceTextRender';
    }
    if (hasTextSelection || selection) {
      classes += ' hideAllTags';
    }
    if (!showTags.bad) {
      classes += ' hideBadTags';
    }
    if (!showTags.good) {
      classes += ' hideGoodTags';
    }
    if (!showTags.best) {
      classes += ' hideBestTags';
    }
    if (!showTags.scores) {
      classes += ' hideTagsScores';
    }

    return classes;
  };

  return (
    <div
      ref={handleSelectionChange}
      className={`PdfAnnotator ${getClasses()}`} // TODO: make this css height == 100% - toolbar height.   width == 100% - sidebar
    >
      <div className="pdfViewer" />
      {props.currentMaterial && props.currentMaterial.id && (
        <MaterialEditor
          key={props.currentMaterial.id}
          {...props}
          isOpen={props.showCurrentMaterial}
          content={selectionText}
          location={selectionLocation}
          handleClose={closePopper}
          hightlightSelection={hightlightSelection}
        />
      )}
    </div>
  );
});
