
function ex2obj(e) {
  if (e instanceof Error) {
    return { message: e.message, stack: e.stack };
  }
  return e;
}

function mergeObj(jData, exception) {
  let ret = jData;
  let ex = exception;

  if (jData instanceof Error) { ex = jData; ret = {}; }
  if (ex instanceof Error) { ret.exception = ex2obj(ex); }

  return ret;
}

function output(message, obj, func) {
  if (obj) {
    func(message, obj);
  } else {
    func(message);
  }
}

function info(message, jData = null) {
  output(`INFO: ${message}`, jData, console.info); // eslint-disable-line
}
function warn(message, jData = null) {
  output(`WARN: ${message}`, jData, console.warn); // eslint-disable-line
}
function error(message, jData = null, exception = null) {
  output(`ERROR: ${message}`, mergeObj(jData, exception), console.error); // eslint-disable-line
}
function fatal(message, jData = null, exception = null) {
  output(`FATAL: ${message}`, mergeObj(jData, exception), console.error); // eslint-disable-line
}
function debug(message, jData = null) {
  if (process.env.NODE_ENV === 'development') {
    output(`DEBUG: ${message}`, jData, console.log); // eslint-disable-line
  }
}
function log(message, ...optionalParams) {
  console.log(message, ...optionalParams); // eslint-disable-line
}

// eslint-disable-next-line
exports = module.exports = {
  debug,
  info,
  warn,
  error,
  fatal,
  log,
};
