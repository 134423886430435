import apit from '../lib/api';

const getAll = async (options) => {
  const { type, page, perPage, search } = options;
  const response = await apit.get(`/v2/synonym`, {
    type: type || null,
    page: page || 1,
    perPage: perPage || 50,
    search: search || null,
  });
  const body = await response.data;
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body;
};

const getInconsistencies = async (options) => {
  const { page, perPage, search } = options;
  const response = await apit.get(`/v2/synonym/inconsistencies`, {
    page: page || 1,
    perPage: perPage || 50,
    search: search || null,
  });
  const body = await response.data;
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body;
};

const getMetadata = async () => {
  const response = await apit.get(`/v2/synonym/metadata`);
  const body = await response.data;
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body;
};

const calculate = async (projectId, materials) => {
  const response = await apit.post(`/v2/synonym/${projectId}/calc`, materials);
  const body = await response.data;
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body;
};

const save = async (projectId, synonyms) => {
  const response = await apit.post(`/v2/synonym/${projectId}`, { synonyms });
  const body = await response.data;
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body;
};

export default {
  getAll,
  getInconsistencies,
  getMetadata,
  calculate,
  save,
};
