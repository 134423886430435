/*

  A simple data caching object.

  Given a cache ttl in milliseconds, and a data loader callback,
  cache the returned data for the specified ttl.

  For example:  Cache a list of users for 10 seconds.
  ----------------------------
  const milliseconds = 10000;
  function loader() {
    return ['username@gmail.com', 'user02@gmail.com'];
  }

  const DataCache = require('datacache');
  const dc = DataCache(milliseconds, loader());
  const arrOfUsers = dc.getData();  // will call loader() to get the data
  const arrOfUsers2 = dc.getData();  // will return cached data
  setTimeout(milliseconds*2, () => { // wait for the cache to time out
    const arrOfUsers = dc.getData();  // will call loader() again to get the data
  })
  ----------------------------

*/
const logger = require('./logger');

class DataCache {
  constructor(ttl, callback) {
    this.ttl = ttl;
    this.callback = callback;
    this.reset();
  }

  reset() {
    this.data = null;
    this.cacheDate = new Date(0);
  }

  isExpired() {
    return this.cacheTTL() <= 0;
  }

  // in ms
  cacheTTL() {
    const now = new Date();
    const diff = (now - this.cacheDate);
    const remain = this.ttl - diff;
    return remain;
  }

  async getData() {
    // 3 phases: active, loading, expired
    if (this.isExpired()) {
      this.data = this.callback();
      this.cacheDate = new Date();
    } else {
      logger.debug(`Got our data already.  Caching remains for ${this.cacheTTL() / 1000}seconds`);
    }

    return this.data;
  }
}

export default (ms, callback) => {
  logger.debug('Constructing datacache:', callback.name);
  return new DataCache(ms, callback);
};
