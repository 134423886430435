import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: 'space-between';
`;

export const TableContainer = styled.div`
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
`;

export const InlineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  & > div {
    align-self: center;
  }
`;

export const VerticalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Padding = styled.div`
  padding-left: ${(props) => (props.left ? '20px' : 0)};
  padding-right: ${(props) => (props.right ? '20px' : 0)};
`;

export const SmallFont = styled.span`
  font-size: 12px;
`;
