import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  border-width: ${(props) => (props.selected ? '2px' : '1px')};
  border-style: solid;
  border-color: ${(props) => (props.selected ? '#3F51B5' : '#ccc')};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 100px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
`;

const Caption = styled.div`
  color: #666;
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  max-width: 100px;
  word-wrap: break-word;
`;

export const Thumbnail = ({ url, name, selected, onSelect }) => {
  return (
    <Container selected={selected} onClick={onSelect}>
      <Image
        src={url || `${process.env.PUBLIC_URL}/noimage.png`}
        alt={!url ? 'No Image' : name}
      />
      <Caption>{!url ? 'No Image' : name || 'No Caption'}</Caption>
    </Container>
  );
};

Thumbnail.defaultProps = {
  url: '',
  name: '',
  selected: false,
  onSelect: () => {},
};

Thumbnail.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};
