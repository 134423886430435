import apit from '../lib/api';

const getAll = async (projectId) => {
  const response = await apit.get(
    `/v2/material/${projectId}/xls/?download=false`
  );
  const body = await response.data;
  if (response.status !== 200) throw Error(body.message);

  return body;
};

const updateProductId = async (projectId, materialId, productId) => {
  const response = await apit.put(
    `/v2/material/${projectId}/saveproductid/${materialId}/${productId}`,
    { materialequiv: materialId, productid: productId }
  );
  if (!response.data.data) {
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return false;
  }

  return true;
};

const updateMappings = async (equivId, mappings) => {
  const response = await apit.put(
    `/v2/material/equiv/${equivId}/mappings`,
    mappings
  );
  if (!response.data.mappings) {
    return false;
  }

  return true;
};

const updateMaterialEquiv = async (equivId, materialEquiv) => {
  const response = await apit.put(
    `/v2/material/equiv/${equivId}/material`,
    materialEquiv
  );
  if (response.status !== 200) {
    return false;
  }

  return true;
};

const download = async (projectId, fileName) => {
  await apit.download(
    `/v2/material/${projectId}/xls/?dl=1`,
    `${fileName}.xlsx`
  );
};

export default {
  getAll,
  updateProductId,
  updateMappings,
  updateMaterialEquiv,
  download,
};
