import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  tagSelect: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  outer: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: '16px',
    marginLeft: '5px',
    width: '100%',
    maxWidth: '1000px',
  },

  label: {
    position: 'relative',
    top: 0,
    left: 0,
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    marginBottom: '5px',
  },
}));


const selectStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', fontSize: '14px' }),
  option: styles => ({ ...styles, fontSize: '14px', padding: '5px 10px' }),
};


function getByIds(matIds, definedMaterials) {
  const matches = matIds.map((matId) => {
    // eslint-disable-next-line eqeqeq
    const match = definedMaterials.find(m => m.id == matId);
    if (match) {
      return {
        label: match.name,
        value: match.id,
      };
    }
    return null;
  }).filter(gmat => (gmat));
  return matches;
}


//
//  No state needed on this component
//
export default function TagList(props) {
  const { existing, references, materials, onChange } = props;
  const classes = useStyles();

  let currentList = [];
  if (existing && Array.isArray(existing)) {
    currentList = existing.map(tag => ({ label: tag, value: tag }));
  }

  const tagList = [
    { label: 'No CSI Given', value: 'No CSI Given' },
    { label: 'Best Guess', value: 'Best Guess' },
    { label: 'Schedule', value: 'Schedule' },
    { label: 'Specifications', value: 'Specifications' },
  ];


  const handleChange = (newValue: any, actionMeta: any) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();

    const list = (newValue) ? newValue.map(v => v.value) : [];
    onChange(list);
  };
  return (
    <FormControl className={classes.tagSelect}>
      <FormLabel className={classes.label}>Tag List</FormLabel>
      <CreatableSelect
        styles={selectStyles}
        options={tagList}
        defaultValue={currentList}
        isMulti
        onChange={handleChange}
      />
    </FormControl>
  );
}


TagList.propTypes = {
  onChange: PropTypes.func.isRequired,
  references: PropTypes.arrayOf(PropTypes.number),
  materials: PropTypes.array,
};

TagList.defaultProps = {
  references: [],
  materials: [],
};
