import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import { getCategoryLabels } from './types/DivisionManager';

const useStyles = makeStyles(theme => ({
  categorySelect: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  outer: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: '16px',
    marginLeft: '5px',
    width: '100%',
    maxWidth: '1000px',
  },

  label: {
    position: 'relative',
    top: 0,
    left: 0,
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    marginBottom: '5px',
  },
}));


const selectStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', fontSize: '14px' }),
  option: styles => ({ ...styles, fontSize: '14px', padding: '5px 10px' }),
};

export default function CategoryComponent(props) {
  const classes = useStyles();
  const { categoryLabel, category, onChange } = props;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function getCats() {
      setOptions(await getCategoryLabels());
    }
    getCats();
  }, []);


  const handleChange = (option) => {
    onChange(option.value, option.label);
  };

  return (
    <FormControl className={classes.categorySelect}>
      <FormLabel className={classes.label}>Category</FormLabel>
      <Select
        styles={selectStyles}
        options={options}
        defaultValue={{ label: categoryLabel, value: category }}
        value={{ label: categoryLabel, value: category }}
        onChange={e => handleChange(e)}
      />
    </FormControl>
  );
}

CategoryComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  category: PropTypes.number,
  categoryLabel: PropTypes.string.isRequired,
};

CategoryComponent.defaultProps = {
  category: 0,
};
