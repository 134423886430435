import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import DPLinkComponent from './DPLinkComponent';
import SiteLinkComponent from './SiteLinkComponent';


const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const AttributeComponent = (props, context) => {
  const { row, classes, dpType } = props;

  let data = '';

  switch (row.attributeTypeId) {
    case 'designerpages_link':
      data = <DPLinkComponent type={dpType} dpid={row.value} />;
      break;
    case 'site_url':
      data = <SiteLinkComponent url={row.value} />;
      break;
    default:
      data = row.value;
  }

  return (
    <TableRow className={classes.row} key={row.attributeTypeId}>
      <CustomTableCell>
        {row.attributeTypeId}
      </CustomTableCell>
      <CustomTableCell>
        {data}
      </CustomTableCell>
    </TableRow>
  );
};

export default AttributeComponent;
