import apit from '../lib/api';

const get = async (scope = null, global = false) => {
  const response = await apit.get(`/v2/settings`, { scope, global });
  const body = await response.data;
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body.settings;
};

const save = async (scope = null, settings, global = false) => {
  const body = [];
  Object.keys(settings).forEach((key) => {
    body.push({ key, value: settings[key] });
  });
  const params = new URLSearchParams();
  params.set('scope', scope);
  params.set('global', global ? 'true' : 'false');
  const response = await apit.post(`/v2/settings?${params.toString()}`, {
    settings: body,
  });
  if (response.status !== 200) {
    throw new Error();
  }
  return true;
};

export default {
  get,
  save,
};
