/* global localStorage */

import React from 'react';
import { Link } from 'react-router-dom';

export default function Logout() {
  localStorage.removeItem('jwtToken');
  return (
    <div className="container">
    You have been logged out.
      <Link to="/login/">Log in again</Link>
    </div>
  );
}
