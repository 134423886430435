import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import history from '../lib/history';
import StyledDropzone from '../components/FU2';
import apit from '../lib/api';
import Header from '../components/Header';

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '95%',
    margin: '0 auto',
  },
  para: {
    color: '#333',
    margin: '0 auto',
    width: '500px',
    padding: '20px',
  },
});


class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: null,
      response: [],
      projtitle: '',
      folderid: '',
      value: false,
    }
  }

  componentDidMount() {
  }

  makeProject = async (e) => {
    e.preventDefault();
    try {
      var response = await apit.post('/v2/project/', {'name': this.state.projtitle });      
      if (!response.data.status) {
        if (response.data.message) {
          alert(`Failure: ${response.data.message}`);
        } else if (response.data.errors) {
          alert(`Failure: Validation Error`);
          console.log(response.data.errors);
        } else {
          alert('Failure:  Unknown Error');
          console.log(response.data);
        }
        return;
      }
      var json = await response.data.json;
      history.push(`/project/${json.id}`);
    }catch(error) {
      alert('Received an error creating the project.');
      console.log(error);
    }
  }

  makeGoogleProject = async (e) => {
    e.preventDefault();
    try {
      var response = await apit.post('/v2/project/', {'gfolderid': this.state.folderid });
      if (!response.data.status) {
        alert(`Failure: ${response.data.message}`);
        return;
      }
      var json = await response.data.json;
      history.push(`/project/${json.id}`);
    }catch(error) {
      console.log(error);
      alert('Received an error creating the project.');
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleEntry = (e) => {
    const { target: { name, value }} = e; 
    console.log(`FOLDER ID ${name} ${value}`)
    this.setState({ 
      [name]: value 
    }); 
  }


  handleProjectClick = (e, id) => {
    this.setState({ redirectTo: `/v2/project/${id}` });
  }

  render() {
    const { redirectTo, value, folderid, projtitle } = this.state;
    const { classes } = this.props;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    const renderFileUploader = <StyledDropzone project={null} createProject={true} />;

    return (
      <div>
        <Header title="Create A Project" />
        <div>
          <div className={classes.root}>
            <h3 className={classes.para}>Creating a project</h3>
            <p className={classes.para}>
              You can create a project in one of three ways.
              Manually, via a Google Drive folder, or uploading a zip file
            </p>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Manual Upload" />
                <Tab label="via Google Drive" />
                <Tab label="Upload ZIP / Create Project" />
              </Tabs>
            </AppBar>
            {value === 0 && (
              <TabContainer dir="inherit">
                <h1>Create a project manually</h1>
                <p>
                  Enter the project name you wish to use
                </p>
                <form onSubmit={this.makeProject}>
                  <input type="text" name="projtitle" value={projtitle.value} placeholder="New Project name here" onChange={this.handleEntry} />
                  <input type="submit" value="Submit" />
                </form>
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer dir="inherit">
                <h1>Download Project From Google Drive</h1>
                <p>
                  To add a project, enter the Google Drive ID.
                  <br />
                  For Example look at the browser url (e.g https://drive.google.com/drive/u/1/folders/13E8ACnEv0TEwaQQ2diVV7P1gbwmvk5PL)
                  <br />
                  The Google Drive ID is
                  <b>
                    13E8ACnEv0TEwaQQ2diVV7P1gbwmvk5PL
                  </b>
                  The project name will be the google folder name
                </p>
                <form onSubmit={this.makeGoogleProject}>
                  <input type="text" name="folderid" value={folderid.value} placeholder="Folder ID Here" onChange={this.handleEntry} />
                  <input type="submit" value="Submit" />
                </form>
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer dir="inherit">
                <h1>Create A Project by Dropping ZIP file</h1>
                <p>
                  This will create a project based on the Filename of the file added.
                  <br />
                  e.g. Drop a file named: <b>this is a project.zip</b>,
                  and a project will be created named: <b>this is a project</b>.
                  <br />
                  The contents of the zip file will be uploaded to it.
                  <br />
                  <br />
                  <b>
                    Please be patient, once the file is uploaded,
                     you will be redirected to the project page.
                  </b>
                </p>
                {renderFileUploader}
              </TabContainer>
            )}
          </div>
        </div>
      </div>
    );
  }
}


Create.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Create);
