/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Header from '../components/Header';
import SearchMatchesFilters from './SearchMatchesFilters';
import SearchMatchRow from './SearchMatchRow';
import searchesService from '../services/searches';
import SearchMatchesRowHeader from './SearchMatchesRowHeader';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '50px auto',
    display: 'flex',
    justifyContent: 'center',
  },
  actionBar: {
    margin: '20px auto',
    width: '95%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  createBtn: {
    alignSelf: 'center',
  },
  table: {
    margin: '20px auto',
    width: '95%',
  },
  tableHead: {
    backgroundColor: '#f0f0f0',
  },
  checkbox: {
    maxWidth: 25,
  },
  pagination: {
    margin: '30px auto',
    display: 'flex',
    justifyContent: 'center',
  },
  rowSuccess: {
    backgroundColor: fade(theme.palette.success.light, 0.3),
  },
  rowError: {
    backgroundColor: fade(theme.palette.error.light, 0.3),
  },
  rowInfo: {
    backgroundColor: fade(theme.palette.info.light, 0.3),
  },
  rowDisabled: {
    backgroundColor: fade(theme.palette.action.disabled, 0.3),
  },
  textInfo: {
    color: theme.palette.info.dark,
  },
  textSuccess: {
    color: theme.palette.success.dark,
  },
  textWarning: {
    color: theme.palette.warning.dark,
  },
  textError: {
    color: theme.palette.error.dark,
  },
}));

export default function SearchMatches() {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const searchId = params.searchid;

  const [search, setSearch] = useState({});
  const [searches, setSearches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [onPage, setOnPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [status, setStatus] = useState();
  const [timeframe, setTimeframe] = useState();
  const [sort, setSort] = useState();
  const [direction, setDirection] = useState();
  const [query, setQuery] = useState();

  const fetchSearch = async () => {
    try {
      const response = await searchesService.getSearch(searchId);
      setSearch(response || {});
    } catch (e) {
      setSearch({});
    }
  };

  const fetchSearchMatches = async (
    _onPage,
    _status,
    _timeframe,
    _sort,
    _direction,
    _query
  ) => {
    setLoading(true);
    try {
      const response = await searchesService.getMatches(searchId, {
        page: _onPage,
        perPage,
        status: _status !== 'All' ? _status : null,
        dateRange: _timeframe,
        orderBy: _sort && _direction ? `${_sort}:${_direction}` : null,
        query: _query || null,
      });
      setSearches(response.data || []);
      setPagination(response.pagination || {});
    } catch (e) {
      setSearches([]);
      setPagination({});
    }
    setLoading(false);
  };

  const onFiltersChange = async (
    newPage,
    newStatus,
    newTimeframe,
    newQuery
  ) => {
    setOnPage(newPage);
    setStatus(newStatus);
    setTimeframe(newTimeframe);
    setQuery(newQuery);

    await fetchSearchMatches(
      newPage,
      newStatus,
      newTimeframe,
      sort,
      direction,
      newQuery
    );
  };

  const onSortChange = async (newSort, newDirection) => {
    setSort(newSort);
    setDirection(newDirection);

    await fetchSearchMatches(onPage, status, timeframe, newSort, newDirection);
  };

  const handleStatusUpdate = (projectId, projectStatus) => {
    const updatedSearches = searches.map((s) => {
      if (s.project_id === projectId) {
        return { ...s, project_import_status: projectStatus };
      }
      return s;
    });
    setSearches(updatedSearches);
  };

  useEffect(() => {
    (async () => {
      await fetchSearch();
      await onFiltersChange(1, null, null, null);
    })();
  }, []);

  return (
    <>
      <Header
        title={`Search Matches ${search.name ? `:: ${search.name}` : ''}`}
      />

      <TableContainer component={Paper}>
        <div className={classes.actionBar}>
          <SearchMatchesFilters
            statusChange={(s) => onFiltersChange(onPage, s, timeframe, query)}
            dateRangeChange={(t) => onFiltersChange(onPage, status, t, query)}
            queryChange={(q) => onFiltersChange(onPage, status, timeframe, q)}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.createBtn}
            onClick={() => history.push('/create')}
          >
            Create Project
          </Button>
        </div>

        {loading && (
          <Box className={classes.box}>
            <CircularProgress disableShrink />
          </Box>
        )}
        {!loading && (
          <>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <SearchMatchesRowHeader
                  sort={sort}
                  direction={direction}
                  onSortChange={onSortChange}
                />
              </TableHead>
              <TableBody>
                {!(searches || []).length && (
                  <TableRow>
                    <TableCell colSpan={20} align="center">
                      No results
                    </TableCell>
                  </TableRow>
                )}
                {(searches || []).map((row, index) => {
                  return (
                    <SearchMatchRow
                      key={index}
                      index={index}
                      classes={classes}
                      row={row}
                      onStatusUpdate={handleStatusUpdate}
                    />
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              color="primary"
              className={classes.pagination}
              count={pagination.pages || 1}
              page={pagination.onPage || 1}
              onChange={(event, page) =>
                onFiltersChange(page, status, timeframe)
              }
            />
          </>
        )}
      </TableContainer>
    </>
  );
}
