import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { IconButton, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import DateRange from '../components/DateRange';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    justifyContent: 'start',
    boxShadow: '0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)',
    padding: '10px 15px',
    borderRadius: 10,
  },
  filtersLabel: {
    color: '#B4B4B4',
    textTransform: 'uppercase',
    alignSelf: 'center',
    marginRight: 20,
  },
  item: {
    display: 'flex',
  },
  itemLabel: {
    alignSelf: 'center',
    marginRight: 5,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function SearchMatchesFilters({
  statusChange,
  dateRangeChange,
  queryChange,
}) {
  const classes = useStyles();

  const [dateRange, setDateRange] = useState();
  const [status, setStatus] = useState('All');
  const [query, setQuery] = useState('');

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    statusChange && statusChange(event.target.value);
  };

  const handleDateRangeChange = (event) => {
    setDateRange(event);
    dateRangeChange && dateRangeChange(event);
  };

  const handleQueryChange = useCallback(
    _.debounce((q) => {
      queryChange && queryChange(q);
    }, 1000),
    []
  );

  return (
    <div className={classes.filters}>
      <div className={classes.filtersLabel}>Filters</div>

      <div className={classes.item}>
        <span className={classes.itemLabel}>Project Name: </span>
        <div style={{ marginTop: 8 }}>
          <TextField
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              handleQueryChange(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <>
                  {!!(query || '').length && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setQuery('');
                        queryChange && queryChange('');
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => queryChange && queryChange(query)}
                  >
                    <SearchIcon />
                  </IconButton>
                </>
              ),
            }}
          />
        </div>
      </div>

      <div className={classes.item}>
        <span className={classes.itemLabel}>Timeframe: </span>
        <DateRange onChange={handleDateRangeChange} />
      </div>

      <div className={classes.item} style={{ marginLeft: 10 }}>
        <span className={classes.itemLabel}>Status: </span>
        <FormControl className={classes.formControl}>
          <Select
            value={status}
            onChange={handleStatusChange}
            style={{ width: 150 }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Unassigned">Unassigned</MenuItem>
            <MenuItem value="Ignored">Ignored</MenuItem>
            <MenuItem value="Importing">Importing</MenuItem>
            <MenuItem value="Ready To Archive">Ready To Archive</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

SearchMatchesFilters.propTypes = {
  statusChange: PropTypes.func.isRequired,
  dateRangeChange: PropTypes.func.isRequired,
  queryChange: PropTypes.func.isRequired,
};
