import apit from '../lib/api';

const getAll = async (options) => {
  const { userId, page, perPage, orderBy, search } = options;
  const response = await apit.get('/v2/project', {
    page: page || null,
    perPage: perPage || null,
    orderBy: orderBy || null,
    showUser: userId || null,
    search: search || null,
  });
  const body = await response.data;
  if (response.status !== 200) {
    if (response.status !== 200) throw Error(body.error || body.message);
  }
  return body;
};

const get = async (projectId) => {
  if (projectId) {
    const response = await apit.get(`/v2/project/${projectId}`);
    const body = await response.data;
    if (response.status !== 200) throw Error(body.error || body.message);
    return body.project;
  }
};

const getNicaUser = async (projectId) => {
  if (projectId) {
    const response = await apit.get(`/v2/project/${projectId}/nica_user`, {
      automap: true,
    });
    const body = await response.data;
    if (response.status !== 200) throw Error(body.error || body.message);
    return body.nicaUser;
  }
};

const saveNicaUser = async (projectId, nicaUserId) => {
  if (projectId && nicaUserId) {
    const response = await apit.post(`/v2/project/${projectId}/nica_user`, {
      nicauserid: nicaUserId,
    });
    const body = await response.data;
    if (response.status !== 200 || body.errors)
      throw Error(body.error || body.message);
    return body;
  }
};

const archive = async (projectId) => {
  if (projectId) {
    const response = await apit.delete(`/v2/project/${projectId}`);
    if (response && response.data && !response.data.errors) {
      return true;
    }
    return false;
  }
};

const assign = async (projectId, userId) => {
  if (projectId && userId) {
    const response = await apit.put(`/v2/project/${projectId}`, {
      assignedto: userId,
    });
    if (response && response.data && !response.data.errors) {
      return true;
    }
    return false;
  }
};

const uploadFiles = async (projectId, files = []) => {
  if (projectId && files.length) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('project', projectId);
    files.forEach((file) => {
      data.append('file', file);
    });

    const response = await apit.post(`/v2/project/${projectId}`, data);
    const body = await response.data;
    if (response.status !== 200) throw Error(body.error || body.message);
    return body;
  }
};

const changeStatus = async (projectId, status, resources = []) => {
  if (projectId && status) {
    const response = await apit.post(`/v2/project/${projectId}/status`, {
      status,
      resources,
    });
    const body = await response.data;
    if (response.status !== 200) throw Error(body.error || body.message);
    return body;
  }
};

export default {
  getAll,
  get,
  getNicaUser,
  saveNicaUser,
  archive,
  assign,
  uploadFiles,
  changeStatus,
};
