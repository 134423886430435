import apit from '../lib/api';

const getAll = async () => {
  const response = await apit.get('/v2/account/');
  const body = await response.data;
  if (response.status !== 200) throw Error(body.message);

  return body.accounts;
};

export default {
  getAll,
};
