/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReadyIcon from '@material-ui/icons/CloudDone';
import QueuedIcon from '@material-ui/icons/CloudDownload';
import ProjectResources from './ProjectResources';
import ProjectActionsMenu from './ProjectActionsMenu';

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#f0f0f0',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CustomButton = withStyles(() => ({
  root: {
    textTransform: 'none',
    textAlign: 'left',
    alignSelf: 'center',
  },
}))(Button);

export function ProjectTableRow(props) {
  const {
    classes,
    row,
    uploadDate,
    matCount,
    errorBadge,
    onHistoryClick,
    onProjectChange,
  } = props;
  const { id, name, assigned_name, resources } = row;

  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow
        className={classes.row}
        // style={{ cursor: 'pointer' }}
        key={`${id}-collapsed`}
      >
        <CustomTableCell style={{ borderBottomWidth: isExpanded ? 0 : 1 }}>
          <CustomButton
            color="primary"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {name}
          </CustomButton>
        </CustomTableCell>
        <CustomTableCell style={{ borderBottomWidth: isExpanded ? 0 : 1 }}>
          {assigned_name}
        </CustomTableCell>
        <CustomTableCell style={{ borderBottomWidth: isExpanded ? 0 : 1 }}>
          {uploadDate}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          {resources.total}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          {matCount}
        </CustomTableCell>
        <CustomTableCell style={{ borderBottomWidth: isExpanded ? 0 : 1 }}>
          <div style={{ display: 'flex' }}>
            <Tooltip title={`${resources.ready} files are ready to work on`}>
              <Badge
                className={classes.margin}
                badgeContent={resources.ready}
                color="primary"
              >
                <ReadyIcon />
              </Badge>
            </Tooltip>
            {resources.queued > 0 && (
              <Tooltip
                title={`${resources.queued} files are queued for processing`}
              >
                <Badge
                  className={classes.margin}
                  badgeContent={resources.queued}
                  color="primary"
                >
                  <QueuedIcon />
                </Badge>
              </Tooltip>
            )}
            {errorBadge}
          </div>
        </CustomTableCell>
        <CustomTableCell align="center">
          <ProjectActionsMenu
            projectId={id}
            assignedUserName={assigned_name}
            onHistoryClick={(historyId) =>
              onHistoryClick && onHistoryClick(historyId)
            }
            onProjectArchived={(projectId) => {
              onProjectChange && onProjectChange(projectId);
            }}
            onFileUploaded={() => {
              if (isExpanded) {
                setIsExpanded(false);
              }
            }}
            onAssignedToChange={(user) => {
              onProjectChange && onProjectChange(user.name);
            }}
          />
        </CustomTableCell>
      </TableRow>
      {isExpanded && (
        <TableRow key={`${id}-expanded`}>
          <TableCell colSpan={7}>
            <ProjectResources
              projectId={id}
              onHistoryClick={(historyId) =>
                onHistoryClick && onHistoryClick(historyId)
              }
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

ProjectTableRow.propTypes = {
  classes: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  uploadDate: PropTypes.string.isRequired,
  matCount: PropTypes.number.isRequired,
  errorBadge: PropTypes.element.isRequired,
  onHistoryClick: PropTypes.func.isRequired,
  onProjectChange: PropTypes.func.isRequired,
};
