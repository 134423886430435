/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import MaterialNavigation from '../components/MaterialNavigation';
import TagNavigation from '../components/TagNavigation';
import PDFSizeControls from '../components/PDFSizeControls';

function TabContainer(props) {
  const { children } = props;
  return <Typography component="div">{children}</Typography>;
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

//
// The explorer sidebar
//
export default function ExplorerSideBar(props) {
  const [value, setValue] = React.useState(0);
  const {
    projectId,
    projectName,
    fileName,
    materials,
    setMaterials,
    onTraverse,
  } = props;

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div>
      {/* <div className="project-nav">
        <Link to={`/project/${projectId}`}>
          &lt; Project Page
        </Link>
      </div>
      <div className="title-nav">
        <h4>{projectName}</h4>
        <h5>{fileName}</h5>
      </div> */}

      <PDFSizeControls {...props} />

      <Tabs value={value} onChange={handleChange}>
        <Tab label="Tags" />
        <Tab label="Products" />
      </Tabs>

      {value === 0 && (
        <TabContainer>
          <TagNavigation {...props} onTraverse={onTraverse} />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <MaterialNavigation
            materials={materials}
            setMaterials={setMaterials}
            onTraverse={onTraverse}
          />
        </TabContainer>
      )}
    </div>
  );
}

ExplorerSideBar.propTypes = {
  onTraverse: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
  setMaterials: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  tags: PropTypes.shape({
    sections: PropTypes.array,
    brands: PropTypes.array,
    products: PropTypes.array,
  }).isRequired,
  materials: PropTypes.shape({}).isRequired,
};
