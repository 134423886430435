import React, { useState } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Switch from '@material-ui/core/Switch';
import Visibility from '@material-ui/icons/Visibility';
import apit from '../lib/api';
import history from '../lib/history';

const tableIcons = {
  Add: AddBox,
  Check,
  Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage,
  LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn,
  Visibility,
};

//
//  ObjectList:  Shows a list of Products or Brands
//

let objectId = '';
let objectType = 'brand';
let setState = null;
let state = null;

const createSynonym = async (record) => {
  return new Promise((resolve, reject) => {
    apit.post(`/v2/${objectType}`, record)
    .then((response) => {
      const body = response.data;
      if (body.status) {
        setState({});
        resolve();
      } else {
        alert(`Failed to add ${objectType} record`);
        reject();
      }  
    });
  });
}

const updateSynonym = async (newRecord, oldRecord) => {
  if (newRecord.name === oldRecord.name) {
    console.log('nothing different, nothing to update');
    return Promise.resolve();
  }

  const upd = {
    name: newRecord.name,
    ignored: !!(newRecord.ignored),
  };
  if (objectType === 'brand') {
    upd.brandid = newRecord.id;
  } else {
    upd.productid = newRecord.id;
  }

  return new Promise((resolve, reject) => {
    apit.put(`/v2/${objectType}/${newRecord.id}`, upd)
      .then((response) => {
        const body = response.data;
        if (body.status) {
          setState({});
          resolve();
        } else {
          alert(`Failed to update ${objectType} record`);
          reject();
        }
      });
  });
};

const handleQuery = async (query) => {
  console.log('queryis', query);
  return new Promise((resolve, reject) => {
    const params = {};
    query.filters.forEach((filter) => {
      if (filter.value.length > 0) {
        params[filter.column.field] = filter.value[0];
      }
    });
    if (query.search) {
      params.search = query.search;
    }

    params.parent = objectId;
    console.log('params are', params);

    apit.get(`/v2/${objectType}/list/${query.page}/${query.pageSize}`, params)
      .then(response => response.data)
      .then((result) => {
        resolve({
          data: result.data,
          page: query.page,
          totalCount: result.count,
        });
      });
  });
};

const SynonymList = (props, context) => {
  [state, setState] = useState(0);
  objectType = props.objectType;
  objectId = props.objectId;

  return (
    <MaterialTable
      icons={tableIcons}
      title={props.label}
      columns={[
        { title: 'Name', field: 'name', filtering: false },
      ]}
      // actions={[
      //   {
      //     icon: Visibility,
      //     tooltip: 'View Records',
      //     onClick: (event, rowData) => handleBrandClick(event, rowData.id),
      //   },
      // ]}
      options={{
        actionsColumnIndex: -1,
  //      filtering: true,
      }}
      data={query => handleQuery(query)}
      editable={{
        onRowAdd: (newData) => {
          newData.parent = objectId;
          return createSynonym(newData);
        },
        onRowUpdate: (newData, oldData) => {
          console.log('old and new', { oldData, newData });
          return updateSynonym(newData, oldData);
        },
        // onRowDelete: oldData => {
        // }
      }}
    />
  );
};

export default SynonymList;
