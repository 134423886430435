import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import settingsService from '../services/settings';

const DictionarySettings = ({ type }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [enableLookup, setEnableLookup] = React.useState(false);
  const [threshold, setThreshold] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getSettings = async () => {
    setLoading(true);
    const response = await settingsService.get(`Dictionary|${type}`, true);
    const enabledSetting = response.find(
      (s) => s.key === 'enableSynonymsLookup'
    );
    const thresholdSetting = response.find(
      (s) => s.key === 'synonymsLookupThreshold'
    );
    setEnableLookup(enabledSetting ? enabledSetting.value === '1' : false);
    setThreshold(thresholdSetting ? thresholdSetting.value : '1');
    setLoading(false);
  };

  const saveSettings = async () => {
    const response = await settingsService.save(
      `Dictionary|${type}`,
      {
        enableSynonymsLookup: enableLookup,
        synonymsLookupThreshold: threshold,
      },
      true
    );

    if (response) {
      handleClose();
      enqueueSnackbar('Settings Saved', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar('Error saving settings, please try again', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (open) {
      getSettings();
    }
  }, [open]);

  if (type !== 'Manufacturer') {
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <Divider orientation="vertical" style={{ margin: '0 4px 0 12px' }} />
      <IconButton style={{ marginTop: 7 }} onClick={handleClickOpen}>
        <SettingsIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Dictionary Settings</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                checked={enableLookup}
                onClick={(e) => setEnableLookup(e.target.checked)}
              />
            }
            label="Include Synonyms in PDF processing"
          />
          <TextField
            autoFocus
            fullWidth
            label="Threshold"
            margin="dense"
            type="number"
            variant="standard"
            value={threshold}
            disabled={loading}
            onChange={(e) => setThreshold(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Number of times a synonym needs to appear to be taken in account in the PDF processing"
                    placement="top"
                  >
                    <HelpIcon
                      style={{ width: 20, height: 20, fill: '#757575' }}
                    />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="neutral">
            Cancel
          </Button>
          <Button disabled={loading} onClick={saveSettings} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DictionarySettings.propTypes = {
  type: PropTypes.string.isRequired,
};

export default DictionarySettings;
