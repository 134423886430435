import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import apit from '../lib/api';
import Header from '../components/Header';
import * as Perms from '../lib/perms';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class Account extends React.Component {
  state = {
    accounts: [],
    dpaccounts: [],
  }

  constructor(props) {
    super(props);
    this.userHandler = this.userHandler.bind(this);
    this.dpUserHandler = this.dpUserHandler.bind(this);
  }

  componentDidMount() {
    this.callApi()
      .then(res => this.setState({ accounts: res.accounts }))
      .catch(err => console.log(err));
  }

  callApi = async () => {
    const response = await apit.get('/v2/account/');
    const body = await response.data;
    if (response.status !== 200) throw Error(body.message);

    // dp users...
    const dpr = await apit.get('/v2/account/dpaccounts/');
    const dpb = await dpr.data;

    dpb.accounts = dpb.accounts.map((acct) => {
      return { id: acct.user_id, name: acct.name, is_active: 1, permissions: 0 };
    });
    this.setState({ dpaccounts: dpb.accounts });

    return body;
  }

  handlePermissionChange = (event, record) => {
    record.permissions = Perms.StringToPerms(event.target.value);
    this.updateRecord(record);
  }

  handleAccessChange = (event, record) => {
    record.is_active = event.target.checked;
    this.updateRecord(record);
  }

  updateRecord = async (record) => {
    const response = await apit.put(`/v2/account/${record.id}`, record);
    const body = await response.data;
    if (body.status) {
      const accts = this.state.accounts;
      for (let i = 0; i < accts.length; i += 1) {
        if (accts[i].id === record.id) {
          accts[i].is_active = record.is_active;
          break;
        }
      }
      this.setState({ accounts: accts });
    } else {
      alert('Failed to update user record');
    }
  }

  userHandler = (e, record) => {
    alert(`need to be able to edit user: ${record.id}`);
  }

  dpUserHandler = async (e, record) => {
    //  this.setState({ redirectTo: `/explorer/projects/${this.state.project}/${id}` });;
    // alert(`need to copy dp user ${id} to be an inspex user`);
    const data = { dp_user_id: record.id, dp_user_name: record.name };
    console.log(data);
    await apit.post('/v2/account/dpenable', data);
    // const back = await res.data;
    this.componentDidMount();
  }

  render() {
    const { classes } = this.props;
    const { redirectTo } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <div>
        <Header title="Accounts" />
        <div className="accounts">
          <h3>Inspex Accounts</h3>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell>Name</CustomTableCell>
                  <CustomTableCell>Permissions</CustomTableCell>
                  <CustomTableCell>Active</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.accounts.map((row) => {
                  return (
                    <TableRow className={classes.row} key={row.id}>
                      <CustomTableCell component="th" scope="row">
                        {row.name}
                      </CustomTableCell>
                      <CustomTableCell>
                        <Select
                          value={Perms.PermsToString(row.permissions)}
                          onChange={event => this.handlePermissionChange(event, row)}
                          inputProps={{
                            name: 'age',
                            id: 'age-simple',
                          }}
                        >
                          {Perms.Permissions.map(perm => (
                            <MenuItem key={perm} value={perm}>{perm}</MenuItem>
                          ))}
                        </Select>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Switch
                          checked={row.is_active}
                          onChange={event => this.handleAccessChange(event, row)}
                          value="checkedB"
                          color="primary"
                        />
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>

        </div>
        <div className="accounts">
          <h3>DP Accounts</h3>
          <p>Click user name to add to inspex system</p>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell>Name</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.dpaccounts.map((row) => {
                  return (
                    <TableRow className={classes.row} key={row.id} onClick={event => this.dpUserHandler(event, row)}>
                      <CustomTableCell component="th" scope="row">
                        {row.name}
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Account);
