/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Header from '../components/Header';
import ProjectTable from './ProjectTable';
import history from '../lib/history';
import Auth from '../lib/Auth';
import projectsService from '../services/projects';
import accountsService from '../services/accounts';
import HomeFilters from './HomeFilters';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      onPage: 1,
      perPage: 50,
      sort: '',
      direction: '',
      search: '',
      projects: [],
      pagination: {},
      accounts: [],
      selectedAccount: '',
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.fethData();

    if (Auth.hasManager()) {
      const accounts = await accountsService.getAll();
      this.setState({ accounts });
    }
  }

  async onProjectChange() {
    await this.fethData();
  }

  handleProjectClick = (e, id) => {
    history.push(`/project/${id}`);
  };

  handleAccountChange = (accountId) => {
    this.setState({ selectedAccount: accountId }, () =>
      this.handlePageChange(undefined, 1)
    );
  };

  handleSearchChange = (search) => {
    this.setState({ search }, () => this.handlePageChange(undefined, 1));
  };

  handleSortChange(newSort, newDirection) {
    this.setState({ sort: newSort, direction: newDirection }, () =>
      this.handlePageChange(undefined, 1)
    );
  }

  handlePageChange(event, page) {
    this.setState({ onPage: page }, async () => {
      await this.fethData();
    });
  }

  async fethData() {
    try {
      this.setState({ loading: true }, async () => {
        let orderBy = '';
        if (this.state.sort && this.state.direction) {
          orderBy = `${this.state.sort}:${this.state.direction}`;
        }

        const response = await projectsService.getAll({
          page: this.state.onPage,
          perPage: this.state.perPage,
          orderBy,
          userId: this.state.selectedAccount,
          search: this.state.search,
        });

        this.setState({
          projects: response.projects,
          pagination: response.pagination,
          loading: false,
        });
      });
    } catch (e) {
      this.setState({
        projects: [],
        pagination: {},
        loading: false,
      });
    }
  }

  render() {
    const {
      loading,
      projects,
      pagination,
      accounts,
      selectedAccount,
      sort,
      direction,
    } = this.state;

    return (
      <Paper>
        <Header title={Auth.hasManager() ? 'Archive Queue' : 'Your Projects'} />
        <>
          <div className="projects">
            <br />
            {Auth.hasManager() && (
              <HomeFilters
                accountId={selectedAccount}
                accounts={accounts}
                // eslint-disable-next-line react/jsx-no-bind
                onSearchChange={this.handleSearchChange.bind(this)}
                // eslint-disable-next-line react/jsx-no-bind
                onAccountChange={this.handleAccountChange.bind(this)}
              />
            )}
            <ProjectTable
              loading={loading}
              sort={sort}
              direction={direction}
              handleClick={this.handleProjectClick}
              records={projects || []}
              pagination={pagination}
              // eslint-disable-next-line react/jsx-no-bind
              onPageChange={this.handlePageChange.bind(this)}
              // eslint-disable-next-line react/jsx-no-bind
              onSortChange={this.handleSortChange.bind(this)}
              // eslint-disable-next-line react/jsx-no-bind
              onProjectChange={this.onProjectChange.bind(this)}
            />
          </div>
        </>
      </Paper>
    );
  }
}

export default Home;
