import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Sort from '../components/Sort';

const SearchMatchesRowHeader = ({ sort, direction, onSortChange }) => {
  return (
    <TableRow>
      <TableCell>Project Name</TableCell>
      <TableCell>
        <Sort
          label="Matched Date"
          field="match_date"
          sort={sort}
          direction={direction}
          onSortChange={onSortChange}
        />
      </TableCell>
      <TableCell align="center">
        <Sort
          label="# Matches"
          field="total_matches"
          sort={sort}
          direction={direction}
          onSortChange={onSortChange}
        />
      </TableCell>
      <TableCell align="center"># Manufacturers</TableCell>
      <TableCell align="center">
        <Sort
          label="Project Size"
          field="project_size"
          sort={sort}
          direction={direction}
          onSortChange={onSortChange}
        />
      </TableCell>
      <TableCell align="center">PA</TableCell>
      <TableCell align="center">DP</TableCell>
      <TableCell align="center">Source</TableCell>
      <TableCell>
        <Sort
          label="Bid Date"
          field="bid_date"
          sort={sort}
          direction={direction}
          onSortChange={onSortChange}
        />
      </TableCell>
      <TableCell align="center">
        <Sort
          label="# Files"
          field="total_assets"
          sort={sort}
          direction={direction}
          onSortChange={onSortChange}
        />
      </TableCell>
      {/* <TableCell align="center">Score</TableCell> */}
      <TableCell align="center">Status</TableCell>
      <TableCell align="right">Actions</TableCell>
    </TableRow>
  );
};

SearchMatchesRowHeader.defaultProps = {
  sort: '',
  direction: 'DESC',
  onSortChange: () => {},
};

SearchMatchesRowHeader.propTypes = {
  sort: PropTypes.string,
  direction: PropTypes.string,
  onSortChange: PropTypes.func,
};

export default SearchMatchesRowHeader;
