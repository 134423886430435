import React from 'react';

import Header from '../components/Header';
import ObjectList from '../components/ObjectList';

const Products = (props, context) => {
  return (
    <div>
      <Header title="Products" />
      <div>
        <ObjectList objectType="product" label="Products" />
      </div>
    </div>
  );
};

export default Products;
