/* global localStorage */
import Auth from './Auth';
import history from './history';

const axios = require('axios');
const FileDownload = require('js-file-download');
const logger = require('./logger');

let jwt = null;
let instance = null;

const apiPath = process.env.REACT_APP_API_PATH || '//localhost:4000/api';
const apiAuthPath =
  process.env.REACT_APP_API_AUTH_PATH || '//localhost:4000/auth/login';

function setInstance() {
  jwt = Auth.getToken();
  instance = axios.create({
    // TODO: Revert this change
    baseURL: apiPath,
    // baseURL: '//api.inspex.dev.designerpages.com/api',
    timeout: 350000,
    headers: { Authorization: `Bearer ${jwt}` },
  });
}

const apit = {
  auth: async (email, password) => {
    const ret = {
      success: false,
      response: null,
    };

    return (
      axios
        // TODO: Revert this change
        .post(apiAuthPath, { email, password })
        // .post('//api.inspex.dev.designerpages.com/auth/login', {
        //   email,
        //   password,
        // })
        .then((response) => {
          if (response.status !== 200) {
            // TODO: this is no good   if 200 is the only status we allow,
            // then other statuses need to be handled in a safe and consistent manner
            const err = new Error();
            err.status = response.status;
            err.message = `Unexpected Error ${err.status}`;
            throw err;
          }
          ret.success = true;
          ret.response = response.data;
          localStorage.setItem('jwtToken', response.data.token);
          setInstance();

          return ret;
        })
        .catch((error) => {
          const resp = error.response;
          if (!resp.status) {
            ret.response = 'network error';
          } else if (resp.status === 401) {
            ret.response = 'email or password is incorrect';
          } else {
            logger.error('unexpected status');
            ret.response = error.message;
          }

          return ret;
        })
    );
  },

  updateInstance: () => {
    setInstance();
  },

  get: async (url, parms = {}) => {
    try {
      if (!instance) {
        setInstance();
      }

      const response = await instance.get(url, { params: parms });
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          logger.debug('redirecting');
          history.push('/login');
        }
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        logger.error('Failed to call get()', {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers,
        });

        // return error.response;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        logger.error('Request Error', error.request);
        // return error;
      } else {
        // Something happened in setting up the request that triggered an Error
        logger.error('Error', error.message);
      }
      // return error;
      throw error;
    }
  },

  getjson: async (url) => {
    try {
      const response = await apit.get(url);
      // we expect data to be an json object
      return response.data;
    } catch (error) {
      logger.error(error);
      throw new Error('Error calling api');
    }
  },

  //
  // handle downloading the pdf
  //
  download: async (url, filename) => {
    try {
      // need arraybuffer to download properly.
      const response = await instance.get(url, { responseType: 'arraybuffer' });
      //      const response = await apit.get(url, { responseType: 'arraybuffer' });
      FileDownload(response.data, filename);
    } catch (error) {
      logger.error(error);
      throw new Error('Error Downloading File');
    }
  },

  put: async (url, data) => {
    try {
      logger.debug('API::PUT()', { url, data });

      if (!instance) {
        setInstance();
      }
      const response = await instance.put(url, data);
      if (response.status === 401) {
        logger.debug('RESTRICTLING ');
        history.push('/login');
      }

      if (response.status !== 200) {
        logger.error('ERROR, got an error but not auth');
        logger.error(response.statusText);
        return response;
      }
      logger.debug('API::PUT returned', response);
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          history.push('/login');
        }
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        logger.debug(error.response.data);
        logger.debug(error.response.status);
        logger.debug(error.response.headers);
        return error.response;
      }

      if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        logger.debug(error.request);
        return error.request;
      }

      // Something happened in setting up the request that triggered an Error
      logger.error('Error', error.message);
      return error;
    }
  },

  post: async (url, data) => {
    try {
      if (!instance) {
        setInstance();
      }
      const response = await instance.post(url, data);
      if (response.status === 401) {
        logger.debug('RESTRICTLING ');
        history.push('/login');
      }

      if (response.status !== 200) {
        logger.debug('ERROR, got an error but not auth');
        logger.debug(response.statusText);
        return response;
      }

      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          history.push('/login');
        }
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        logger.debug(error.response.data);
        logger.debug(error.response.status);
        logger.debug(error.response.headers);
        return error.response;
      }

      if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        logger.debug(error.request);
        return error.request;
      }

      // Something happened in setting up the request that triggered an Error
      logger.debug('Error', error.message);
      return error;
    }
  },

  delete: async (url, data) => {
    try {
      if (!instance) {
        setInstance();
      }
      const response = await instance.delete(url, data);
      if (response.status === 401) {
        history.push('/login');
      }

      if (response.status !== 200) {
        logger.debug('ERROR, got an error but not auth');
        logger.debug(response.statusText);
        return response;
      }

      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          history.push('/login');
        }
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        logger.debug(error.response.data);
        logger.debug(error.response.status);
        logger.debug(error.response.headers);
        return error.response;
      }

      if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        logger.debug(error.request);
        return error.request;
      }

      // Something happened in setting up the request that triggered an Error
      logger.debug('Error', error.message);
      return error;
    }
  },

  //
  //  Handles loading a PDF file into pdfjs.  ensure the auth token exists
  //
  pdfload: (url) => ({
    // TODO: Revert this change
    url: `${apiPath}/..${url}`,
    // url: `//api.inspex.dev.designerpages.com/..${url}`,
    httpHeaders: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
    },
  }),
};

export default apit;
