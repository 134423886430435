import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TagContainer = styled.div`
  color: black;
  display: inline-block;
  background-color: ${({ color }) => color};
  padding: 0 5px;
  border-radius: 2px;
`;

export const TagLabel = ({ label, color }) => {
  return <TagContainer color={color}>{label}</TagContainer>;
};

TagLabel.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
