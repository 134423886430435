import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AppBar, Paper, Tab, Tabs } from '@material-ui/core';
import SynonymService from '../services/synonyms';
import Header from '../components/Header';
import { TabPanel } from '../components/TabPanel';
import { Tab as DictionaryTab } from './Tab';

const TotalCount = styled.div`
  align-self: center;
  flex-grow: 1;
  text-align: right;
  padding-right: 30px;
`;

export const Dictionary = () => {
  const [tab, setTab] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await SynonymService.getMetadata();
        setCount(response.projectsCount);
      } catch (err) {
        setCount(0);
      }
    })();
  }, []);

  return (
    <>
      <Header title="Synonyms Dictionary" />
      <Paper>
        <AppBar color="secondary" position="static">
          <Tabs
            value={tab}
            onChange={(e, newValue) => setTab(newValue)}
            aria-label="Dictionary"
          >
            <Tab label="Categories" />
            <Tab label="Brands" />
            <Tab label="Products" />
            <Tab label="Inconsistencies" />
            <TotalCount>Total Projects: {count}</TotalCount>
          </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
          <DictionaryTab type="Category" />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <DictionaryTab type="Manufacturer" />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <DictionaryTab type="Product" />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <DictionaryTab type="Inconsistency" />
        </TabPanel>
      </Paper>
    </>
  );
};
