import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import apit from '../lib/api';

export default class ProductDialog extends React.Component {
  state = {
    open: false,
    product_id: 0,
    product: {
      name: '',
      dp_id: 0,
    },
  };

  constructor(props) {
    super(props);

//    this.state.brand = props.brand
    this.state.open = props.open;
    this.state.onClose = props.onClose;
    this.state.onRemove = props.onRemove;
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.state.onClose();
  };

  handleRemoveTag = () => {
    this.state.onRemove();
    this.handleClose();
  }

  componentDidUpdate = (prevProps) => {
    if (!this.props.open) { return; }

    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open,
      });
    }

    if (prevProps.product.inspexId !== this.props.product.inspexId) {
      this.setState({
        product_id: this.props.product.inspexId 
      });
      this.props.handleTagClick({type: 'p', id: this.props.product.id }).then((r) => { this.setState({ copytxt: r })});

      apit.getjson(`/v2/product/${this.props.product.inspexId}`)
      .then((res) => {
        this.setState({ product: res.product });
      }).catch(err => {
        console.log('product::errorr');
        console.log(err);
      })
    }
  };

  render() {

    const { product, copytxt } = this.state;
    if (!this.state.open) {
      return (<div/>);
    }
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{product.name}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This product is a product.  This info is not nec. accurate yet.
            </DialogContentText>
            <p style={{color:'#333'}}>
              DesignerPages Product:
              <a style={{color:'#333'}} target='_dpwindow' href={`https://www.designerpages.com/products/${product.dp_id}`}>{product.dp_id}</a>
            </p>
            <p style={{color:'#333'}}>
              Inspex Product Info:
              <a style={{color:'#333'}} target='_dpwindow' href={`/product/${product.id}`}>{product.id}</a>
            </p>

            <p style={{color:'#333'}}>
            Copyable Data:<br />
            BrandName, BrandId, ProductName, ProductId<br />
            {copytxt}
              {/* Show:
              <br/>
              The Highlighted Text.
              <br/>
              The Inspex Brand
              <br />
              The DP Product Title + ID + Link
              The DP BRAND title + ID + Link
              <br />
              Ability to remove tag
              <br />
              Ability to close dialog
              <br/>
              Ability to map to inspex brand.
              <br/>
              Ability to map to inspex product (synonym)
              <br/>
              Ability to 'fix' highlight... e.g. off by 1 char, how to fix easily? */}

            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleRemoveTag} color="primary">
              Remove Tag
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}