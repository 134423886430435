/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withSnackbar } from 'notistack';
import apit from '../lib/api';
import Header from '../components/Header';
import ResourceTable from '../components/ResourceTable';
import StyledDropzone from '../components/FU2';
import PickUserDialog from '../components/PickUser';
import Auth from '../lib/Auth';
import history from '../lib/history';
import AlertDialog from '../components/AlertDialog';
import HistoryDialog from '../components/HistoryDialog';

const logger = require('../lib/logger');

const styles = (theme) => ({
  btn: {},
});

class Project extends React.Component {
  state = {
    project: '',
    proj: {
      name: '',
      creator: 'Unknown',
      assigned: '',
      archived: false,
      files: [],
    },
    openSelectUser: false,
    openConfirm: false,
    openConfirmArchive: false,
    // eslint-disable-next-line react/no-unused-state
    assignedUserName: 'Not set yet',

    anchorEl: null,
    showHistory: false,
  };

  constructor(props) {
    super(props);

    this.state.project = this.props.match.params.project;

    this.stateHandler = this.stateHandler.bind(this);
    this.reprocess = this.reprocess.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.loadResources = this.loadResources.bind(this);
    this.onCloseHistory = this.onCloseHistory.bind(this);
  }

  componentDidMount() {
    this.loadResources();
  }

  callApi = async () => {
    try {
      logger.debug('Calling Project.callApi()');
      const response = await apit.get(`/v2/project/${this.state.project}`);
      const body = await response.data;
      logger.debug('Post body');
      if (response.status !== 200) throw Error(body.message);
      logger.debug('did nt thow');
      return body;
    } catch (e) {
      throw e;
    }
  };

  reprocess = async (e, file) => {
    const { enqueueSnackbar } = this.props;
    const { project } = this.state;

    e.preventDefault();
    logger.debug(`called reprocess for ${file}`);

    const response = await apit.post(`/v2/project/${project}/${file}/process`);
    const body = await response.data;
    if (response.status !== 200) {
      enqueueSnackbar(body.message, { variant: 'error' });

      throw Error(body.message);
    } else {
      enqueueSnackbar('File was sent for reprocessing', { variant: 'success' });
    }

    this.componentDidMount();
  };

  rescan = async (e, file) => {
    const { enqueueSnackbar } = this.props;
    const { project } = this.state;

    e.preventDefault();
    logger.debug(`called rescan for ${file}`);

    const response = await apit.post(`/v2/project/${project}/${file}/scan`);
    const body = await response.data;
    if (response.status !== 200) {
      enqueueSnackbar(body.message, { variant: 'error' });

      throw Error(body.message);
    } else {
      enqueueSnackbar('File was sent for rescanning', { variant: 'success' });
    }

    this.componentDidMount();
  };

  handleRemove = async (e, file) => {
    logger.debug('setting openconfirm to true', file);
    this.setState({
      openConfirm: true,
      removeFileId: file,
    });
  };

  handleRemoveConfirm = async (status) => {
    const { enqueueSnackbar } = this.props;

    logger.debug(`Remove file confirmation returned: ${status}`);

    if (status) {
      const stat = await apit.delete(
        `/v2/project/${this.state.project}/${this.state.removeFileId}`
      );
      if (stat && stat.data && !stat.data.errors) {
        console.log('FILE WAS REMOVED', stat);
        enqueueSnackbar('File was removed from the project', {
          variant: 'success',
        });
        this.componentDidMount();
      } else {
        console.log('NO FILE REMOVED', stat);
        enqueueSnackbar('Failed to remove the file!', { variant: 'error' });
      }
    }

    this.setState({ openConfirm: false, removeFileId: '' });
  };

  handleFileClick = (e, id) => {
    const { project } = this.state;
    e.preventDefault();
    history.push(`/explorer/projects/${project}/${id}`);
  };

  handleDownload = async (e, id, fileName) => {
    const { enqueueSnackbar } = this.props;
    const { project } = this.state;
    console.log('you want to download', id);
    enqueueSnackbar('File download was requested, and should begin shortly', {
      variant: 'success',
    });
    e.preventDefault();
    await apit.download(`/v2/project/${project}/${id}`, `${fileName}.pdf`);
  };

  handleResourceHistory = async (e, id) => {
    e.preventDefault();
    this.setState({ historyId: id });
    this.setState({ showHistory: true });
  };

  handleAssignUser = () => {
    this.setState({
      openSelectUser: true,
    });
  };

  handleCloseAssignUser = async (value) => {
    const { enqueueSnackbar } = this.props;
    const { project } = this.state;
    console.log('user assigned:', value);
    this.setState({ openSelectUser: false });
    if (value) {
      const pro = this.state.proj;
      pro.assigned = value.name;
      this.setState({ proj: pro });

      const stat = await apit.put(`/v2/project/${project}`, {
        assignedto: value.id,
      });
      if (stat && stat.data && !stat.data.errors) {
        enqueueSnackbar('User Assigned', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to assign user', { variant: 'error' });
        console.log(stat);
      }
    }
    this.handleProjectManagementClose();
  };

  handleArchiveProject = async () => {
    this.setState({ openConfirmArchive: true });
  };

  handleCloseArchiveProject = async (status) => {
    const { enqueueSnackbar } = this.props;
    const { project } = this.state;
    this.setState({ openConfirmArchive: false });
    if (status) {
      const stat = await apit.delete(`/v2/project/${project}`);
      if (stat && stat.data && !stat.data.errors) {
        enqueueSnackbar('Project Archived!', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to archive project', { variant: 'error' });
      }
    }
    this.handleProjectManagementClose();
  };

  handleProjectManagementOpen = (event) => {
    console.log(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget });
  };

  handleProjectManagementClose = () => {
    this.setState({ anchorEl: null });
  };

  handleShowHistory = () => {
    console.log('showing history', this.state.proj.id);
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ historyId: this.state.proj.id });
    this.setState({ showHistory: true });
  };

  onCloseHistory = (event) => {
    this.setState({ showHistory: false });
  };

  handleShowMaterials = () => {
    const { project } = this.state;
    history.push(`/project/${project}/materials`);
  };

  stateHandler(e) {
    this.componentDidMount();
  }

  loadResources() {
    this.callApi()
      .then((res) => this.setState({ proj: res.project }))
      .catch((err) => logger.error('Caught error in CDM: ', err));
  }

  render() {
    // const { classes } = this.props;
    const { anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);

    // let assignUser = '';
    let manageProject = '';
    let renderFileUploader = '';
    const refreshProject = (
      <Tooltip title="Refresh Page">
        <IconButton onClick={this.loadResources} color="inherit">
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    );
    const archiveProject = Auth.hasManager() ? (
      <MenuItem onClick={(event) => this.handleArchiveProject()}>
        Archive Project
      </MenuItem>
    ) : (
      ''
    );
    const assignUserM = Auth.hasManager() ? (
      <MenuItem onClick={(event) => this.handleAssignUser()}>
        Assign User
      </MenuItem>
    ) : (
      ''
    );
    const projectHistory = Auth.hasManager() ? (
      <MenuItem onClick={(event) => this.handleShowHistory()}>
        Show History
      </MenuItem>
    ) : (
      ''
    );
    const showMaterials = Auth.hasEditor() ? (
      <MenuItem onClick={(event) => this.handleShowMaterials()}>
        Show Products
      </MenuItem>
    ) : (
      ''
    );
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleProjectManagementClose}
      >
        {archiveProject}
        {assignUserM}
        {projectHistory}
        {showMaterials}
      </Menu>
    );

    let renderArchived = (
      <tr>
        <td />
      </tr>
    );

    if (this.state.proj.archived) {
      renderArchived = (
        <tr>
          <td>This project has been archived</td>
        </tr>
      );
    }

    let renderUserList = '';
    let renderConfirm = '';
    let renderConfirmArchive = '';

    if (Auth.hasEditor()) {
      manageProject = (
        <Tooltip title="Project Management">
          <IconButton
            aria-owns={isMenuOpen ? 'material-appbar' : null}
            aria-haspopup="true"
            onClick={this.handleProjectManagementOpen}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      );

      renderFileUploader = (
        <StyledDropzone project={this.state.project} createProject={false} />
      );

      // <FileUploader project={this.state.project} stateHandler={this.stateHandler} />

      renderUserList = (
        <PickUserDialog
          title="Assign User"
          selectedValue={this.state.proj.assigned}
          open={this.state.openSelectUser}
          onClose={this.handleCloseAssignUser}
        />
      );

      renderConfirm = (
        <AlertDialog
          open={this.state.openConfirm}
          onClose={this.handleRemoveConfirm}
          title="Remove File"
          message="Are you sure you want to remove this file?"
        />
      );

      renderConfirmArchive = (
        <AlertDialog
          open={this.state.openConfirmArchive}
          onClose={this.handleCloseArchiveProject}
          title="Archive Project"
          message="Are you sure you want to archive this project? It will no longer be accessible"
        />
      );
    }

    return (
      <div>
        <Header title={this.state.proj.name} />
        <div className="projects">
          <table>
            <tbody>
              {renderArchived}
              <tr>
                <td>
                  Created By: {this.state.proj.creator}
                  <br />
                  Assigned To: {this.state.proj.assigned}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {refreshProject}
                  {manageProject}
                </td>
              </tr>
            </tbody>
          </table>
          <ResourceTable
            handleRescan={this.rescan}
            handleReprocess={this.reprocess}
            handleClick={this.handleFileClick}
            handleRemove={this.handleRemove}
            handleDownload={this.handleDownload}
            handleHistory={this.handleResourceHistory}
            records={this.state.proj.files}
          />
          {renderFileUploader}
        </div>
        {renderMenu}
        {renderUserList}
        {renderConfirm}
        {renderConfirmArchive}
        <HistoryDialog
          open={this.state.showHistory}
          onClose={this.onCloseHistory}
          historyId={this.state.historyId}
        />
      </div>
    );
  }
}

Project.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSnackbar(Project));
