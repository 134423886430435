/**
  A module that defines a single `Equivilent` Type
  This is a product or brand

  @module types/equivilentType
*/

import * as Logger from '../../lib/logger';
import * as MaterialTag from './materialTag';

const equivTypeState = {
  text: '',
  tag: null,
  dpId: null,
};


/**
  Initialize a new equivilentType

  @return {types/equivilentType} The initialized equivilentType
*/
export function initialize() {
  return {
    ...equivTypeState,
  };
}

export function duplicateEquivilentType(inTyp) {
  return {
    ...inTyp,
    tag: MaterialTag.duplicateTag(inTyp.tag),
  };
}

/**
  Convert an EquivilentType to an API payload

  @param {types/equivilentType} state - The equivilentType to convert to api format
  @return {object} The equivilentType formatted for API consumption
*/
export function toAPI(state = equivTypeState) {
  // console.log('********************************************   TO API EQUIVTYPE', state);

  if (!state.text && !state.tag && !state.dpId) {
    return null;
  }

  let ttt = null;
  // no tag is ok (e.g. manual typed text), but MT.toAPI uses the 
  // default state if the tag is undefined here...
  // this is *probably* a bug
  if (state.tag) {
    ttt = MaterialTag.toAPI(state.tag);
  }

  return {
    text: state.text,
    tag: ttt,
    dpId: (state.dpId) ? state.dpId : null,
  };
}

/**
  Convert an API payload to an equivilentType

  @param {object} payload - An equivilentType object received from the API
  @returns {types/equivilentType} The equivilentType state object
 */
export function fromAPI(payload) {
  if (!payload) { return null; }

  // Logger.debug('equivTypeFromAPI', payload);
  return {
    ...equivTypeState,
    text: payload.text,
    dpId: payload.dpId,
    tag: MaterialTag.fromAPI(payload.tag),
  };
}

/**
  Updates the equivilentType text/tag values

  @param {types/equivilentType} state - An equivilentType state object
  @param {object} payload - An equivilentType object
  @returns {types/equivilentType} The equivilent state object after modification
 */
export function equivTypeUpdate(state = equivTypeState, payload) {
  // console.log('********************************************   EQUIVTYPE UPDATE', { state, payload });

  if (!payload) { return state; }

  const stag = (state) ? state.tag : null;

  Logger.debug('equivTypeUpdate', { state, payload });
  if (!payload.resourceId) {
    return {
      ...state,
      text: payload.text,
    };
  }
  return {
    ...state,
    text: payload.text,
    tag: MaterialTag.materialTagUpdate(stag, payload),
  };
}

// export function materialEquivUpdate(state = equivTypeState, payload) {
//   return {
//     ...state,
//     text: payload.text,
//   };
// }
