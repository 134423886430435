import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  pill: {
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: '20px',
    display: 'flex',
    padding: '0 4px',
    flexWrap: 'wrap',
    fontSize: '0.75rem',
    minWidth: '20px',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Pill(props) {
  const classes = useStyles();
  const { count } = props;

  return (
    <span className={classes.pill}>{count}</span>
  );
}


Pill.propTypes = {
  count: PropTypes.number.isRequired,
};
