/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import { isValid, parseISO, formatRelative } from 'date-fns';
import ErrorIcon from '@material-ui/icons/Error';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import HistoryDialog from '../components/HistoryDialog';
import { ProjectTableRow } from './ProjectTableRow';
import ProjectTableRowHeader from './ProjectTableRowHeader';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F5F5F5',
    },
  },
  margin: {
    margin: theme.spacing(2),
  },
  box: {
    margin: '50px auto',
    display: 'flex',
    justifyContent: 'center',
  },
  pagination: {
    margin: '30px auto',
    display: 'flex',
    justifyContent: 'center',
  },
});
function CustomizedTable(props) {
  const {
    classes,
    loading,
    sort,
    direction,
    records,
    pagination,
    onPageChange,
    onSortChange,
    onProjectChange,
  } = props;
  const now = new Date();

  const [historyId, setHistoryId] = useState('');
  const [showHistory, setShowHistory] = useState(false);

  const handleHistoryClick = (projectId) => {
    setHistoryId(projectId);
    setShowHistory(true);
  };

  return (
    <Paper className={classes.root}>
      <HistoryDialog
        open={showHistory}
        onClose={() => setShowHistory(false)}
        historyId={historyId}
      />
      {loading && (
        <Box className={classes.box}>
          <CircularProgress disableShrink />
        </Box>
      )}
      {!loading && (
        <>
          <Table className={classes.table}>
            <TableHead>
              <ProjectTableRowHeader
                sort={sort}
                direction={direction}
                onSortChange={(newSort, newDirection) => {
                  onSortChange && onSortChange(newSort, newDirection);
                }}
              />
            </TableHead>
            <TableBody>
              {!records.length && (
                <TableCell colSpan={7} align="center">
                  No records found
                </TableCell>
              )}
              {records.map((row) => {
                let uploadDate = 'Unknown';
                if (isValid(parseISO(row.updated_at))) {
                  const p = parseISO(row.updated_at);
                  uploadDate = formatRelative(p, now);
                }

                let errorBadge = '';
                if (row.resources.error > 0) {
                  errorBadge = (
                    <Tooltip title={`${row.resources.error} files have errors`}>
                      <Badge
                        className={classes.margin}
                        badgeContent={row.resources.error}
                        color="secondary"
                      >
                        <ErrorIcon />
                      </Badge>
                    </Tooltip>
                  );
                }

                const matCount = row.material_count ? row.material_count : 0;

                return (
                  <ProjectTableRow
                    classes={classes}
                    row={row}
                    uploadDate={uploadDate}
                    matCount={matCount}
                    errorBadge={errorBadge}
                    onHistoryClick={handleHistoryClick}
                    onProjectChange={onProjectChange}
                  />
                );
              })}
            </TableBody>
          </Table>
          {pagination && (
            <Pagination
              color="primary"
              className={classes.pagination}
              count={pagination.pages || 1}
              page={pagination.onPage || 1}
              onChange={onPageChange}
            />
          )}
        </>
      )}
    </Paper>
  );
}

CustomizedTable.defaultProps = {
  sort: '',
  direction: '',
};

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  sort: PropTypes.string,
  direction: PropTypes.string,
  records: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onProjectChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomizedTable);
