import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const Container = styled.div`
  display: flex;

  span,
  svg {
    align-self: center;
  }

  svg {
    cursor: pointer;
    margin-left: 4px;
  }
`;

const iconStyles = {
  fontSize: '1.2em',
};

const Sort = ({ label, field, sort, direction, onSortChange }) => {
  if (!label || !field) {
    return null;
  }

  return (
    <Container>
      <span>{label}</span>
      {sort !== field && (
        <UnfoldMoreIcon
          style={iconStyles}
          onClick={() => {
            onSortChange(field, 'DESC');
          }}
        />
      )}
      {sort === field && direction === 'DESC' && (
        <ArrowDropDownIcon
          style={iconStyles}
          onClick={() => {
            onSortChange(field, 'ASC');
          }}
        />
      )}
      {sort === field && direction !== 'DESC' && (
        <ArrowDropUpIcon
          style={iconStyles}
          onClick={() => {
            onSortChange(field, 'DESC');
          }}
        />
      )}
    </Container>
  );
};

Sort.defaultProps = {
  sort: '',
  direction: '',
  onSortChange: () => {},
};

Sort.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sort: PropTypes.string,
  direction: PropTypes.string,
  onSortChange: PropTypes.func,
};

export default Sort;
