import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';

import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

import * as mateq from './types/equiv';
import { initialize as initializeMaterialTag } from './types/materialTag';
import CategoryComponent from './CategoryComponent';
import { getCategoryLabel } from './types/DivisionManager';


const Logger = require('../lib/logger');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  table: {
    xminWidth: 650,
  },
  createNewRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ccc',
    },
  },
  tablecell: {
    padding: '2px 2px 2px 2px',
  },

  textFieldCode: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50px',
  },

  categoryHead: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '250px',
  },

  rowCategorySelect: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '500px',
  },


  textFieldPB: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '150px',
  },


}));

//
// we want a forwardRef so we can pass in the keypresses from the editor.
// like everything else react.. there is probably a right way to do this.
export default forwardRef((props, ref) => {
  const classes = useStyles();
  const { records, onChange, equiv_if_approved,
    onChangeEquiv, gotoLocation, acceptAssignment, updateDocumentWithSelection } = props;

  const [state, setState] = useState(records);

  useEffect(() => {
    async function handleCats() {
      const equivs = state;
      for (let i = 0; i < equivs.length; i += 1) {
        const eqv = equivs[i];
        const lbl = await getCategoryLabel(eqv.categoryId);
        if (lbl.value === eqv.categoryId) {
          eqv.categoryLabel = lbl.label;
        } else {
          eqv.categoryLabel = 'Please Choose a Category';
        }
      }
      setState(equivs);
    }
    handleCats();
  }, []);


  // this *only* exists to force a draw due to absymal understanding of react.
  // since state object contains class methods,
  // we can't use prevState=> ({...prevstate, ...newState})
  // just calling setState() doesn't trigger the redraw.
  // so, incomes our fake state...
  // this screams developer-implmentation-error...
  const [fixme, setFixMe] = useState(0);

  useImperativeHandle(ref, () => ({
    // these get pressed on the MaterialEditor
    keyHandler(theKey, theContent) {
      Logger.debug('ProductTable.KEYHANDLER', { theKey, theContent });
      if (theKey === 'r') {
        onRowAdd();
      } else {
        let rrr = null;
        const rowIndex = state.length - 1;
        const theRow = state[rowIndex];
        const theTag = initializeMaterialTag();
        theTag.text = theContent.content;
        theTag.resourceId = theContent.location.resource;
        theTag.page = theContent.location.page;
        theTag.top = theContent.location.box.top;
        theTag.left = theContent.location.box.left;
        theTag.bottom = theContent.location.box.bottom;
        theTag.right = theContent.location.box.right;

        let part = '';
        Logger.debug('SETTING TO:', { theKey, theTag, rowIndex });
        if (theKey === 'b') {
          rrr = mateq.updateBrandChanges(theRow, theTag);
          part = 'brand';
        } else if (theKey === 'p') {
          rrr = mateq.updateProductChanges(theRow, theTag);
          part = 'product';
        } else if (theKey === 'n') {
          rrr = mateq.appendNote(theRow, theContent.content);
        } else {
          Logger.debug('unknown producttable key, did you add something to the editor and not the productable?');
        }
        if (rrr) {
          updateDocumentWithSelection(theTag, part);
          onRowUpdate(rrr, rowIndex);
        }
        acceptAssignment();
      }
    },
  }));

  const changeState = (data) => {
    setState(data);
    setFixMe(Date.now);

    return onChange(data);
  };

  const changeEquiv = (bl) => {
    Logger.debug('equiv chnage to ', bl);
    onChangeEquiv(bl);
    setFixMe(Date.now);
  };

  const onRowAdd = (newData) => {
    const data = state;

    const newRow = mateq.initialize();
    newRow.categoryLabel = 'Please Choose a Category';
    data.push(newRow);

    return changeState(data);
  };

  const onRowUpdate = (newData, index) => {
    const data = state;
    data[index] = newData;

    return changeState(data);
  };

  const onRowDelete = (index) => {
    const oldData = state[index];
    const data = state;
    const idx = data.indexOf(oldData);
    data.splice(idx, 1);
    return changeState(data);
  };

  const onRowGoto = (index) => {
    const row = state[index];
    console.log('onGoTOLocation', row)
    // this too is bullshit
    if (row && row.product && row.product.tag && row.product.tag.page !== 0) {
      gotoLocation(row.product.tag);
    } else if (row && row.brand && row.brand.tag && row.brand.tag.page !== 0) {
      gotoLocation(row.brand.tag);
    } else {
      Logger.debug('gotolocation, can go, bad/no location (can be ok)', row);
    }
  };

  const changeBasisOfDesign = (row, index, newVal) => {
    // this allows any number of products to be specified
    onRowUpdate(mateq.setIsBasis(row, newVal), index);

    // this only allowed one product to be specified
    // const newData = state.map((record, idx) => {
    //   // set the chosen record on/off, all others must be off
    //   Logger.debug('** BASIS', record);
    //   return mateq.setIsBasis(record, (index === idx) ? newVal : false);
    // });

    //return changeState(newData);
  };

  const changeIsNested = (row, index, newVal) => {

  }
  //
  // content has been dropped onto a table-row's column
  //
  const dropper = ((e, f) => {
    const rowIndex = f.index;
    const { part } = f;
    const theRow = state[rowIndex];

    const content = e.dataTransfer.getData('text');
    const obj = JSON.parse(content);
    const theTag = initializeMaterialTag();
    theTag.text = obj.content;
    theTag.resourceId = obj.location.resource;
    theTag.page = obj.location.page;
    theTag.top = obj.location.box.top;
    theTag.left = obj.location.box.left;
    theTag.bottom = obj.location.box.bottom;
    theTag.right = obj.location.box.right;

    //
    // we can either drop on the NOTE, the Product, or the Brand.
    //
    let rrr = null;
    if (part === 'product') {
      rrr = mateq.updateProductChanges(theRow, theTag);
      updateDocumentWithSelection(theTag, part);
    } else if (part === 'brand') {
      rrr = mateq.updateBrandChanges(theRow, theTag);
      updateDocumentWithSelection(theTag, part);
    } else if (part === 'note') {
      rrr = mateq.appendNote(theRow, obj.content);
      Logger.debug('************   APPENDED NOTE NOW', rrr);
    } else if (part === 'code') {
      rrr = mateq.setCode(theRow, obj.content);
    } else {
      Logger.error('ProductTable.dropper()  UNKNOWN TABLE COL', f);
    }

    if (rrr) {
      onRowUpdate(rrr, rowIndex);
    }
    acceptAssignment();
  });


  //
  // If someone types in their value, we update via this method.
  //  We KEEP any existing location data the row may have.
  //  This means, if someone dropped a highlighted term, then types new text in,
  //  we KEEP the TagLocation information.  Of course, for instances where the typed
  //  text completely changes the Tag will be wrong.
  //  We expect this will be mostly used to correct text that was dropped.
  //  (e.g. dropped "armstron" and corrected to "Armstrong")
  //
  const updateValue = ((e, part, index) => {
    const content = e.target.value;
    const theRow = state[index];
    let rrr = null;

    if (part === 'product') {
      //Logger.debug('PRODUCT TYPING', theRow);
      // we use this tag, if there was not a tag entered before.  we want to record where the 
      // value was updated (for brand/product) so we can get the document where it was typed in.
      const theTag = initializeMaterialTag();
      theTag.resourceId = props.resourceId;
      theTag.text = content;
      // if dont have it already
      if (!theRow.product || !theRow.product.tag || !theRow.product.tag.resourceId) {
        //console.log('UODATING WITH TAG');
        rrr = mateq.updateProductChanges(theRow, theTag);
      } else {
        //console.log('UDATING WITH OUT TAG')
        rrr = mateq.updateProductText(theRow, content);
      }
    } else if (part === 'brand') {
      //Logger.debug('BRAND TYPING', theRow);
      // we use this tag, if there was not a tag entered before.  we want to record where the 
      // value was updated (for brand/product) so we can get the document where it was typed in.
      const theTag = initializeMaterialTag();
      theTag.resourceId = props.resourceId;
      theTag.text = content;
      if (!theRow.brand || !theRow.brand.tag || !theRow.brand.tag.resourceId) {
        //console.log('UODATING WITH TAG');
        rrr = mateq.updateBrandChanges(theRow, theTag);
      } else {
        //console.log('UDATING WITH OUT TAG');
        rrr = mateq.updateBrandText(theRow, content);
      }
    } else if (part === 'note') {
      rrr = mateq.updateNote(theRow, content);
      Logger.debug('************   UPDATED NOTE NOW', rrr);
    } else if (part === 'isNested') {
      rrr = mateq.setIsNested(theRow, e.target.checked);
      Logger.debug('************   UPDATED NESTED NOW', rrr);
    } else if (part === 'code') {
      rrr = mateq.setCode(theRow, content);
    } else if (part === 'categoryid') {
      rrr = mateq.setCategoryId(theRow, content);
    } else {
      Logger.error('ProductTable.updateValue()  UNKNOWN TABLE COL', part);
    }

    if (rrr) {
      onRowUpdate(rrr, index);
    }
  });

  const updateRowCategory = (index, value, label) => {
    const theRow = state[index];
    const rrr = mateq.setCategoryId(theRow, value);
    rrr.categoryLabel = label;

    onRowUpdate(rrr, index);
  };

  const createRow = ((row, index) => {
    const rowKey = `row-${index}`;
    const rowIndex = index + 1;
    // Logger.debug('THIS IS THE TABLE ROW', row);

    return (
      <TableRow key={rowKey}>
        <TableCell className={classes.tablecell}>{rowIndex}</TableCell>
        <TableCell className={classes.tablecell}>
          <Input
            id="product"
            value={(row.product && row.product.text) ? row.product.text : ''}
            className={classes.textFieldPB}
            onChange={(e => updateValue(e, 'product', index))}
            onDragOver={e => e.preventDefault()}
            onDrop={e => dropper(e, { rowKey, index, part: 'product' })}
            title={(row.product) ? row.product.text : ''}
          />
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Input
            id="brand"
            className={classes.textFieldPB}
            value={(row.brand && row.brand.text) ? row.brand.text : ''}
            onChange={(e => updateValue(e, 'brand', index))}
            onDragOver={e => e.preventDefault()}
            onDrop={e => dropper(e, { rowKey, index, part: 'brand' })}
            title={(row.brand) ? row.brand.text : ''}
          />
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Input
            id="note"
            xlabel="Note"
            xvariant="outlined"
            className={classes.textField}
            value={row.note}
            onChange={(e => updateValue(e, 'note', index))}
            margin="none"
            title={row.note}
            onDragOver={e => e.preventDefault()}
            onDrop={e => dropper(e, { rowKey, index, part: 'note' })}
          />
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Checkbox
            checked={row.isBasis}
            onChange={e => changeBasisOfDesign(row, index, e.target.checked)}
            value="checkedA"
            color="primary"
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
          />
        </TableCell>
        {/* <TableCell className={classes.tablecell}>
          <Checkbox
            checked={row.isNested}
            onChange={(e => updateValue(e, 'isNested', index))}
            value="checkedA"
            color="primary"
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
          />
        </TableCell> */}
        <TableCell className={classes.tablecell}>
          <Input
            id="code"
            className={classes.textFieldCode}
            value={row.code}
            onChange={(e => updateValue(e, 'code', index))}
            onDragOver={e => e.preventDefault()}
            onDrop={e => dropper(e, { rowKey, index, part: 'code' })}
          />
        </TableCell>
        <TableCell className={[classes.tablecell]}>
          <CategoryComponent
            // className={classes.rowCategorySelect}
            categoryLabel={row.categoryLabel}
            category={row.categoryId}
            onChange={(a, b) => { updateRowCategory(index, a, b); }}
          />
        </TableCell>
        <TableCell className={classes.tablecell}>
          <IconButton className={classes.button} aria-label="Delete" onClick={() => onRowDelete(index)}>
            <DeleteIcon />
          </IconButton>
          <IconButton className={classes.button} aria-label="Goto Location" onClick={() => onRowGoto(index)}>
            <VisibilityIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  // Logger.debug('ProductTable.render update state', { state, equiv_if_approved });
  Logger.debug('RENDERING PRODUCT TABLE UPDATE');
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tablecell} />
            <TableCell className={[classes.tablecell, classes.textFieldPB]} align="center">Product</TableCell>
            <TableCell className={[classes.tablecell, classes.textFieldPB]} align="center">Brand</TableCell>
            <TableCell className={classes.tablecell} align="center">Notes</TableCell>
            <TableCell className={classes.tablecell} align="center">Specified</TableCell>
            {/* <TableCell className={classes.tablecell} align="center">Nested</TableCell> */}
            <TableCell className={[classes.tablecell, classes.textFieldCode]} align="center">Code</TableCell>
            <TableCell className={[classes.tablecell, classes.categoryHead]} align="center">Category</TableCell>
            <TableCell className={classes.tablecell} align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.map((row, index) => createRow(row, index))}

          {/* This is the Create Row */}
          <TableRow key="newrow">
            <TableCell
              className={classes.createNewRow}
              align="center"
              colSpan={7}
              onClick={onRowAdd}
            >
              Create New Equivilent Row
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div>
        <Checkbox
          checked={equiv_if_approved}
          onChange={e => changeEquiv(e.target.checked)}
          value="equivIfApproved"
          color="primary"
          inputProps={{
            'aria-label': 'primary checkbox',
          }}
        />
        Equiv. if approved by Architect.
      </div>
    </Paper>
  );
});
