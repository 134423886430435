/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import apit from '../lib/api';
import Header from '../components/Header';
import ProjectTable from './ProjectTable';
import history from '../lib/history';

// this is the same as home, but shows archived content
class ArchiveList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      onPage: 1,
      perPage: 50,
      response: [],
      pagination: {},
    };
  }

  componentDidMount() {
    this.callApi()
      .then((res) =>
        this.setState({ response: res.projects, pagination: res.pagination })
      )
      .catch((err) => console.log(err));
  }

  callApi = async () => {
    this.setState({ loading: true });
    const { onPage, perPage } = this.state;

    let queryParams = '';
    if (onPage && perPage) {
      queryParams = `?page=${onPage}&perPage=${perPage}`;
    }

    // eslint-disable-next-line prefer-template
    const response = await apit.get('/v2/project' + queryParams, {
      archived: true,
    });
    const body = await response.data; // .json();
    if (response.status !== 200) {
      history.push('/login/');
    }
    this.setState({ loading: false });
    return body;
  };

  handlePageChange(event, page) {
    this.setState({ onPage: page }, () => {
      this.callApi().then((res) =>
        this.setState({ response: res.projects, pagination: res.pagination })
      );
    });
  }

  render() {
    return (
      <Paper>
        <Header title="Your Archived Projects" />
        <div>
          <br />
          <br />
          <div className="projects">
            <ProjectTable
              loading={this.state.loading}
              handleClick={this.handleProjectClick}
              records={this.state.response}
              pagination={this.state.pagination}
              // eslint-disable-next-line react/jsx-no-bind
              onPageChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
      </Paper>
    );
  }
}

export { ArchiveList };
