import React from 'react';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import history from '../lib/history';
import apit from '../lib/api';
import Header from '../components/Header';
import AttributeComponent from '../components/AttributeComponent';
import SynonymList from '../components/SynonymList';
import ObjectList from '../components/ObjectList';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    color: theme.palette.common.black,
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },

  queryable: {
    width: '100%',
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
});


class Brand extends React.Component {
  state = {
    id: '',
    brand: {
      name: '',
      id: '',
    },
    attributes: [],
    synonyms: [],
    products: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.brandid,
      brand: {
        name: '',
      },
      attributes: [],
      synonyms: [],
      products: [],
    }
  }

  componentDidMount() {
    this.loadDataset();
  }

  callApi = async () => {
    const params = {}
    const response = await apit.get(`/v2/brand/${this.state.id}`);
    console.log('got our response')
    const body = await response.data;
    console.log('set our response')
    if (response.status !== 200) {
      console.log('Throwing an error due to !200')
      throw Error(body.message);
    }

    console.log('sending data')
    return body;
  }
  
  handleIgnoreBrand = (event) => {
    event.preventDefault();
    const { brand } = this.state;
    brand.ignored = event.target.checked;
    console.log('brand ignore is now', brand.ignored);
    this.setState({brand});
    this.updateRecord(brand);
  }
 updateRecord = async (record) => {
   console.log('Updating record')
   const response = await apit.put(`/v2/brand/${record.id}`, record);
    const body = await response.data;
    if (body.status) {
      console.log('brand ignore status updated');
    } else {
      alert('Failed to update brand record');
    }
  }

  loadDataset = async() => {
    try {
      const data = await this.callApi();
      console.log('and our data');
      await this.setState({ brand: data.brand, synonyms: data.synonyms, attributes: data.attributes, products: data.products })
    } catch (err) {
      console.log(err)
    }
  }

  handleProductClick = async (e, id) => {
    e.preventDefault();
    history.push(`/product/${id}`);
  }


  render() {
    const { classes } = this.props;
    const { redirectTo, brand, attributes, synonyms, products, id } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    let ignoredStatus = '';
    if (brand.ignored) {
      ignoredStatus = '! This brand is ignored !';
    }
    console.log('thebran', id)
    return (
      <div>
        <Header title="Brand" />
        <Paper className={classes.root}>
          <h3>{brand.name}</h3>
          <FormControlLabel
            control={(
              <Switch
                checked={(!!brand.ignored)}
                onChange={this.handleIgnoreBrand}
                value="checked"
                color="primary"
              />
            )}
            label="Ignore Status"
          />
          <h4>{ignoredStatus}</h4>
          <div className={classes.attrbutes}>
            Attributes: {attributes.length}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attributes.map((row) => {
                  return (<AttributeComponent classes={classes} row={row} dpType="manufacturers" />);
                })}
              </TableBody>
            </Table>
          </div>
          <div className={classes.synonyms}>
            <SynonymList objectId={id} objectType="brand" label="Brand Synonyms" />
          </div>
          <div className={classes.synonyms}>
            <ObjectList referenceId={id} objectType='product' label={brand.name + ' Products'} />
          </div>
        </Paper>
      </div>
    );
  }
}


 export default withStyles(styles)(Brand);
