/* global document */
const logger = require('./logger');

function createPan(domElement, options) {
  options = options || {};

  domElement.draggable = true;
  // const el = domElement;

  let moving = false;
  let oldX = 0;
  listenForEvents();

  function listenForEvents() {
    logger.debug('createPan() starting up');
    // domElement.addEventListener('keydown', shifty);
    // domElement.addEventListener('mousedown', dragStartMethod);
    // domElement.addEventListener('mouseup', dragEndMethod);
    // domElement.addEventListener('mousemove', dragMethod);

    logger.debug('createPan()  add ds');
    domElement.addEventListener('dragstart', dragStartMethod);
    // domElement.addEventListener('dragend', dragEndMethod);
    // domElement.addEventListener('drag', dragMethod);
  }

  // function shifty(e) {
  //   logger.debug('createPan() keypress event', e);
  // }

  function dragStartMethod(e) {
    // always hide that damn drag image
    const dragIcon = document.createElement('img');
    dragIcon.src = 'blank.png';
    dragIcon.width = 100;
    e.dataTransfer.setDragImage(dragIcon, -10, -10);

    if (!e.altKey) {
    // e.preventDefault();
    // e.stopPropagation();
      domElement.draggable = false;
      return false;
    }
    domElement.draggable = true;
    oldX = e.x;
    moving = true;
    logger.debug('createPan() drag start', e);

    logger.error('createPan() rm ds')
    logger.debug('createPan() rm ds');
    domElement.removeEventListener('dragstart', dragMethod);
    logger.debug('createPan() add d');
    domElement.addEventListener('drag', dragMethod);
    logger.debug('createPan() add de');
    domElement.addEventListener('dragend', dragEndMethod);
    // e.preventDefault();
    // e.stopPropagation();
    // e.preventDefault();
    // e.stopPropagation();
  }
  function dragEndMethod(e) {
    moving = false;
    logger.debug('createPan() drag end', e);

    logger.debug('createPan() add ds');
    domElement.addEventListener('dragstart', dragStartMethod);
    logger.debug('createPan() rm d');
    domElement.removeEventListener('drag', dragMethod);
    logger.debug('createPan() rm de');
    domElement.removeEventListener('dragend', dragEndMethod);
  }

  function dragMethod(e) {
    if (!moving) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    logger.debug('createPan() dragging');
    // TODO: this scroll sucks.. but it works
    if (oldX > e.x) {
      scroll(-50);
    } else {
      scroll(50);
    }
    oldX = e.x;
    logger.debug(e);
    return false;
  }

  function scroll(delta) {
    logger.debug(`createPan() scrolling by ${delta}`);
    domElement.scrollBy(delta, 0);
  }
}

module.exports = createPan;
