import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TableCell,
  TextField,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import divisionsService from '../services/divisions';
import materialsService from '../services/materials';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const MaterialEditModal = ({
  materialEquiv,
  open,
  onSave,
  onCancel,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bestGuess, setBestGuess] = useState(materialEquiv?.bestGuess || false);
  const [notes, setNotes] = useState(materialEquiv?.notes || '');
  const [code, setCode] = useState(materialEquiv?.code || '');
  const [isBasis, setIsBasis] = useState(
    materialEquiv?.isBasis ? 'true' : 'false'
  );
  const [category, setCategory] = useState(materialEquiv?.dpCategory);

  const handleSaveButton = async () => {
    const body = {
      bestGuess,
      code,
      isBasis: isBasis === 'true',
      notes,
      categoryId: category,
    };
    const response = await materialsService.updateMaterialEquiv(
      materialEquiv.id,
      body
    );
    if (response) {
      enqueueSnackbar('Product saved successfully.', {
        variant: 'success',
      });
      const savedItem = {
        id: materialEquiv.id,
        ...body,
        dpCategory: category,
        dpCategoryName: (
          categories.find((c) => c.id === category) || { name: '' }
        ).name,
      };
      onSave(savedItem);
    } else {
      enqueueSnackbar('An error occurred, please try again.', {
        variant: 'error',
      });
    }
  };

  const fetchDivisions = async () => {
    setLoading(true);
    const newDivisions = await divisionsService.getDivisions();
    setCategories(newDivisions || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchDivisions();
  }, []);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onCancel}>
      <DialogTitle id="customized-dialog-title" onClose={onCancel}>
        Edit Product
      </DialogTitle>
      <DialogContent dividers>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Product</TableCell>
              <TableCell>{materialEquiv.product}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Manufacturer</TableCell>
              <TableCell>{materialEquiv.manufacturer}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">BestGuess</TableCell>
              <TableCell>
                <Checkbox
                  checked={bestGuess}
                  onChange={(e) => setBestGuess(e.target.checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Notes</TableCell>
              <TableCell>
                <TextField
                  multiline
                  fullWidth
                  variant="outlined"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Status</TableCell>
              <TableCell>
                <RadioGroup
                  value={isBasis}
                  onChange={(e) => {
                    setIsBasis(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Specified"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="Approved"
                  />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Code</TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Category</TableCell>
              <TableCell>
                {loading && <CircularProgress size="small" />}
                {!loading && (
                  <Select
                    variant="outlined"
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    <MenuItem value={0}>Please Chose a Category</MenuItem>
                    {categories.map((div) => (
                      <MenuItem key={div.id} value={div.id}>
                        {div.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleSaveButton} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MaterialEditModal.defaultProps = {
  open: false,
};

MaterialEditModal.propTypes = {
  open: PropTypes.bool,
  materialEquiv: PropTypes.shape({
    id: PropTypes.string,
    manufacturer: PropTypes.string,
    product: PropTypes.string,
    notes: PropTypes.string,
    code: PropTypes.string,
    isBasis: PropTypes.bool,
    bestGuess: PropTypes.bool,
    dpCategory: PropTypes.number,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
