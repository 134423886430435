import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { withSnackbar } from 'notistack';
import LinearProgress from '@material-ui/core/LinearProgress';
import apit from '../lib/api';
import history from '../lib/history';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

async function handleCreateProject(projName) {
  var response = await apit.post('/v2/project/', {'name': projName });
  if (!response.data.status) {
    if (response.data.message) {
      alert(`Failure: ${response.data.message}`);
    } else if (response.data.errors) {
      alert(`Failure: Validation Error`);
      console.log(response.data.errors);
    } else {
      alert('Failure:  Unknown Error');
      console.log(response.data);
    }
    return -1;
  }
  var json = await response.data.json;
  return json.id;
}

async function onDropHandler(acceptedFiles, props, loadingFunc) {//projectId, doCreateProject) {
  const { project, createProject, enqueueSnackbar } = props;

  const doCreateProject = createProject;
  let projectId = project;

  if (acceptedFiles.length < 1) {
    console.log('no files');
    return false;
  }
  console.log('the project id is ', projectId);

  loadingFunc(true);

  let reloader = () => { window.location.reload(); };

  if (doCreateProject) {
    // create the project -- note, can only create 1
    console.log('Create the project');
    if (acceptedFiles.length > 1) {
      alert('Can only upload 1 zip file at a time');
      return false;
    }

    const projectName = acceptedFiles[0].name.replace(/.zip/gi, '');
    projectId = await handleCreateProject(projectName);
    if (projectId === -1) {
      enqueueSnackbar('Failed to create Project', { variant: 'error' });
      return false;
    }

    enqueueSnackbar('Project Created, you will be taken there shortly...', { variant: 'success' });
    reloader = () => { history.push(`/project/${projectId}`); };
    console.log('created ', projectId);
  }

  const data = new FormData();
  data.append('project', projectId);
  acceptedFiles.forEach((file) => {
    data.append('file', file);
  });

  const response = await apit.post(`/v2/project/${projectId}`, data);
  if (response.status !== 200) {
    if (response.status === 301) {
      enqueueSnackbar('Timeout while uploading.  Try reloading the page, it may have worked. sorry', { variant: 'error' });
    } else {
      const body = await response.data;
      enqueueSnackbar(`Error Uploading: ${body.message} `, { variant: 'error' });
      throw Error(body.message);
    }
  } else {
    setTimeout(() => { reloader(); }, 15000);
    enqueueSnackbar('File was uploaded.  Page will refresh in about 15 seconds', { variant: 'success', autoHideDuration: 15000 });
  }
}

function StyledDropzone(props) {
  let accept = '';
  const [ loading, setLoading] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop: (af) => { onDropHandler(af, props, setLoading); },
  });

  return (
    <div className="container">
      <br />
      <br />
      {loading && <LinearProgress />}
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>Drag and drop some files here, or click to select files</p>
      </Container>
      <p style={{ textAlign: 'center' }}>Once you drop files, please wait for the page to refresh automatically</p>
    </div>
  );
}

export default withSnackbar(StyledDropzone);
