import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({}));

function linkBuilder(projectId, info) {
  if (info && info.resourceId && info.page > 0) {
    return `/#/explorer/projects/${projectId}/${info.resourceId}/${info.page}/${info.top}`;
  }

  return null;
}

export default function GotoLocation(props) {
  const classes = useStyles();
  const { projectId, brand, product } = props;

  const [brandPage, setBrandPage] = React.useState(
    linkBuilder(projectId, brand)
  );
  const [productPage, setProductPage] = React.useState(
    linkBuilder(projectId, product)
  );

  const bb = brandPage ? (
    <Link target="_locwin" href={brandPage}>
      Goto Brand
    </Link>
  ) : (
    ''
  );

  const pp = productPage ? (
    <Link target="_locwin" href={productPage}>
      Goto Product
    </Link>
  ) : (
    ''
  );

  return (
    <>
      {bb}
      <br />
      {pp}
    </>
  );
}

GotoLocation.propTypes = {
  projectId: PropTypes.string.isRequired,
  brand: PropTypes.shape({
    resourceId: PropTypes.string,
    page: PropTypes.number,
  }),
  product: PropTypes.shape({
    resourceId: PropTypes.string,
    page: PropTypes.number,
  }),
};

GotoLocation.defaultProps = {
  brand: null,
  product: null,
};
