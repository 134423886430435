/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { FiltersContainer } from './DictionaryFilters.styles';
import DictionarySettings from './DictionarySettings';

export const DictionaryFilters = ({ type, onFiltersChange }) => {
  const [search, setSearch] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && search.length) {
      onFiltersChange && onFiltersChange(search);
    }
  };

  return (
    <FiltersContainer>
      <div>&nbsp;</div>
      <div style={{ display: 'flex' }}>
        <TextField
          label="Search keyword or synonym"
          style={{ width: 300 }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {!!search.length && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearch('');
                      onFiltersChange && onFiltersChange('');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  onClick={() => {
                    onFiltersChange && onFiltersChange(search);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <DictionarySettings type={type} />
      </div>
    </FiltersContainer>
  );
};

DictionaryFilters.propTypes = {
  type: PropTypes.string.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

export default DictionaryFilters;
