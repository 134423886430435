import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AlertDialog from '../components/AlertDialog';
import projectsService from '../services/projects';
import remoteAssetsService from '../services/remoteAssets';

import {
  SearchMatchResourcesContainer,
  SearchMatchResourcesTable,
} from './SearchMatchResources.styles';

export default function SearchMatchResources({
  projectId,
  status,
  onStatusChange,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showAlert, setShowAlert] = useState();

  const disableImport = status === 'Ready To Archive' || status === 'Importing';

  const handleOnPreview = (url) => {
    if (url) {
      // eslint-disable-next-line no-undef
      window.open(url, '_blank');
    }
  };

  const isRowSelected = (row) => {
    return row && !!selected.find((s) => s.id === row.id);
  };

  const handleSelectRow = (row) => {
    const exists = selected.find((s) => row.id === s.id);
    if (!exists) {
      setSelected([...selected, { id: row.id, fileName: row.name }]);
    } else {
      setSelected(selected.filter((s) => s.id !== row.id));
    }
  };

  const hasSelected = () => {
    return !!selected.length;
  };

  const isAllSelected = () => {
    return selected.length === resources.length;
  };

  const handleSelectAll = () => {
    if (isAllSelected()) {
      setSelected([]);
    } else {
      const newSelected = resources.map((r) => ({
        id: r.id,
        fileName: r.url,
      }));
      setSelected(newSelected);
    }
  };

  const handleDownload = () => {
    if (selected.length) {
      enqueueSnackbar('File download was requested, and should begin shortly', {
        variant: 'success',
      });
      selected.forEach((f) => {
        remoteAssetsService.download(f.id, f.fileName);
      });
      setSelected([]);
    }
  };

  const handleImport = async () => {
    if (selected.length) {
      try {
        const response = await projectsService.changeStatus(
          projectId,
          'Importing',
          selected.map((s) => s.id)
        );
        if (response.status === 'Sucess') {
          setSelected([]);
          onStatusChange && onStatusChange('Importing');
          enqueueSnackbar('Status Updated', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Status Updated', {
            variant: 'error',
          });
        }
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      }
    }
  };

  const handleOnConfirm = (confirm) => {
    if (confirm) {
      handleImport();
    }
    setShowAlert(false);
  };

  const fetchProject = async () => {
    setLoading(true);
    if (projectId) {
      try {
        const response = await remoteAssetsService.getAll(projectId);
        setResources(response || []);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        throw e;
      }
    }
  };

  useEffect(() => {
    (async () => {
      await fetchProject();
    })();
  }, []);

  return (
    <>
      <SearchMatchResourcesContainer>
        {loading && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '30px auto',
            }}
          >
            <CircularProgress disableShrink />
          </Box>
        )}
        {!loading && (
          <>
            <SearchMatchResourcesTable>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!hasSelected() || disableImport}
                      onClick={() => setShowAlert(true)}
                    >
                      Import Selected
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!hasSelected()}
                      onClick={handleDownload}
                    >
                      Download Selected
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={hasSelected() && !isAllSelected()}
                      checked={isAllSelected()}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Preview</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resources.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isRowSelected(row)}
                        onChange={() => handleSelectRow(row)}
                      />
                    </TableCell>
                    <TableCell>
                      <span style={{ color: 'gray' }}>{row.name}</span>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <IconButton
                          color="primary"
                          onClick={() => handleOnPreview(row.url)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </SearchMatchResourcesTable>
          </>
        )}
      </SearchMatchResourcesContainer>
      <AlertDialog
        open={showAlert}
        onClose={handleOnConfirm}
        confirm="Import"
        refuse="Cancel"
        message={`You are about to import this project with ${selected.length} file(s), once started the import cannot be stoped. Are you sure you want to import it?`}
        title="Confirmation"
      />
    </>
  );
}

SearchMatchResources.propTypes = {
  projectId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};
