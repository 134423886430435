import React from 'react';

import Header from '../components/Header';
import ObjectList from '../components/ObjectList';

const Brands = (props, context) => {
  return (
    <div>
      <Header title="Brands" />
      <ObjectList objectType="brand" label="Brands" />
    </div>
  );
};

export default Brands;
