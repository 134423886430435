import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { parseISO, formatRelative, isValid } from 'date-fns';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import GearIcon from '@material-ui/icons/Settings';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import WarningIcon from '@material-ui/icons/Warning';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Auth from '../lib/Auth';


const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: '8px',
  },
  body: {
    fontSize: 14,
    padding: '8px',
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});


class ResourceTable extends React.Component {

  state = {
    anchorEl: null,
    resourceId: null,
    fileName: '',
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  handleResourceManagementOpen = (event, resourceId, fileName) => {
    console.log(event.currentTarget);
    console.log('resource id is ', resourceId);
    this.setState({ anchorEl: event.currentTarget, resourceId, fileName });
  }

  handleResourceManagementClose = () => {
    this.setState({ anchorEl: null, resourceId: null, fileName: '' });
  }


  test = (event) => {
    console.log('test', this.state.resourceId);
  }


  render() {
    const { classes, records, handleClick, handleRescan, handleReprocess, handleRemove, handleDownload, handleHistory } = this.props;
    const { anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const now = new Date();

    let actionMenu = '';
    if (Auth.hasManager()) {
      actionMenu = (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={this.handleResourceManagementClose}
        >
          <MenuList>
            <MenuItem onClick={(event) => { this.handleResourceManagementClose(); handleRescan(event, this.state.resourceId); }}>
              <ListItemIcon className={classes.icon}>
                <FingerprintIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} inset primary="Rescan File" />
            </MenuItem>
            <MenuItem onClick={(event) => { this.handleResourceManagementClose(); handleReprocess(event, this.state.resourceId); }}>
              <ListItemIcon className={classes.icon}>
                <FingerprintIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} inset primary="Reprocess File (Complete)" />
            </MenuItem>
            <MenuItem onClick={(event) => { this.handleResourceManagementClose(); handleDownload(event, this.state.resourceId, this.state.fileName); }}>
              <ListItemIcon className={classes.icon}>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} inset primary="Download PDF" />
            </MenuItem>
            <MenuItem onClick={(event) => { this.handleResourceManagementClose(); handleHistory(event, this.state.resourceId); }}>
              <ListItemIcon className={classes.icon}>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} inset primary="History" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={(event) => { this.handleResourceManagementClose(); handleRemove(event, this.state.resourceId); }}>
              <ListItemIcon className={classes.icon}>
                <WarningIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} inset primary="Remove File" />
            </MenuItem>
          </MenuList>
        </Menu>
      );
    } else {
      actionMenu = (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={this.handleResourceManagementClose}
        >
          <MenuList>
            <MenuItem onClick={(event) => { this.handleResourceManagementClose(); handleDownload(event, this.state.resourceId, this.state.fileName); }}>
              <ListItemIcon className={classes.icon}>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} inset primary="Download PDF" />
            </MenuItem>
          </MenuList>
        </Menu>
      )
    }

    return (
      <div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Resource Name</CustomTableCell>
                <CustomTableCell>Processed</CustomTableCell>
                <CustomTableCell>Doc Type</CustomTableCell>
                <CustomTableCell align="right">#Pages</CustomTableCell>
                <CustomTableCell align="right">#Sections</CustomTableCell>
                <CustomTableCell align="right">#Brands</CustomTableCell>
                <CustomTableCell align="right">#Products</CustomTableCell>
                <CustomTableCell>Manage</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((row) => {
                let statusDate = 'unknowndate';
                if (isValid(parseISO(row.last_action_date))) {
                  statusDate = formatRelative(parseISO(row.last_action_date), now);
                }

                let docType = 'unknown';
                if (row.metadata.doctype) {
                  docType = row.metadata.doctype;
                }
                docType = docType.charAt(0).toUpperCase() + docType.slice(1);

                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row" style={{ cursor: 'pointer' }} onClick={event => handleClick(event, row.id)}>
                      {row.name}
                    </CustomTableCell>
                    <CustomTableCell title={statusDate} align="right">
                      {row.status_message}
                    </CustomTableCell>
                    <CustomTableCell align="center">{docType}</CustomTableCell>
                    <CustomTableCell align="right">{row.pages}</CustomTableCell>
                    <CustomTableCell align="right">{row.matches}</CustomTableCell>
                    <CustomTableCell align="right">{row.brands}</CustomTableCell>
                    <CustomTableCell align="right">{row.products}</CustomTableCell>
                    {/* { Auth.hasManager() ? <CustomTableCell style={{cursor:'pointer'}} onClick={event => handleReprocess(event, row.id)}>Reprocess</CustomTableCell> : '' }
                    { Auth.hasManager() ? <CustomTableCell style={{cursor:'pointer'}} onClick={event => handleRemove(event, row.id)}>Remove</CustomTableCell> : '' } */}
                    <CustomTableCell>
                      <Tooltip title="Resource Management">
                        <IconButton
                          aria-owns={isMenuOpen ? 'material-appbar' : null}
                          aria-haspopup="true"
                          onClick={(event) => { this.handleResourceManagementOpen(event, row.id, row.name); }}
                          color="inherit"
                        >
                          <GearIcon />
                        </IconButton>
                      </Tooltip>
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        {actionMenu}
      </div>
    );
  }
}

ResourceTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResourceTable);
