/**
  Module for the definiton of a Material type

  @module types/material
*/

import uuidv4 from 'uuid/v4';

import apit from '../../lib/api';
import * as Logger from '../../lib/logger';
import { getDivisionByCategoryId } from './DivisionManager';

import * as equiv from './equiv';
import * as MaterialTag from './materialTag';

// const divMgr = new DivisionManager();

const materialState = {
  id: '', // a uuid
  projectId: '', // a uuid
  division: '0', // a numeric string
  category: 0, // a number
  title: '', // a string
  titleTag: null, // an objet
  note: '', // a string
  equivIfApproved: false, // a boolean
  equivilents: [], // an array of objects
  code: '', // a string
  tagList: [], // an array of strings

  nested: { parent: [], child: [] }, // an array of nested objects
};

/**
  Initialize a new material.  Requires a projectId

  @param {string} projectId - The ProjectId to default the material to
  @return {types/material} The initialized material
*/
export function initialize(projectId) {
  return {
    id: uuidv4(), // a uuid
    projectId, // a uuid
    division: '0', // a numeric string
    category: 0, // a number
    title: '', // a string
    titleTag: null, // an objet
    note: '', // a string
    equivIfApproved: false, // a boolean
    equivilents: [], // an array of objects
    code: '', // a string
    tagList: [], // an array of strings
    nested: { parent: [], child: [] }, // an array of nested objects
  };
}

// incoming state is a material, we need duplicate
export function duplicateMaterial(state) {
  if (!state) {
    Logger.error('copyMaterial() failed, state is null');
    return null; // THIS IS AN ERROR!
  }

  return {
    ...state,
    id: uuidv4(),
    title: `${state.title} - Copy`,
    titleTag: MaterialTag.duplicateTag(state.titleTag),
    equivilents: equiv.duplicateEquivilents(state.equivilents),
  };
}

/**
  Convert a material to an API payload

  @param {types/material} state - The material to convert to api format
  @return {object} The material formatted for API consumption
*/
export function toAPI(state = materialState) {
  // Logger.debug('toAPI()', state);

  return {
    id: state.id,
    projectId: state.projectId,
    division: state.division,
    category: state.category,
    title: state.title,
    note: state.note,
    equivIfApproved: state.equivIfApproved,

    titleTag: state.titleTag ? MaterialTag.toAPI(state.titleTag) : null,
    equivilents: state.equivilents.map((eq) => equiv.toAPI(eq)),

    code: state.code,
    tagList: state.tagList,

    nested: state.nested,

    // TODO:  we dont accept this yet.
    // nestedOn: [], // an array of uuids
    // nestedTo: [], // an array of uuids
  };
}

/**
  Convert an API payload to a material

  @param {object} payload - A material object received from the API
  @returns {types/material} The material state object
 */
export function fromAPI(payload) {
  // Logger.debug('**** ** *MATERIAL FROM API', { payload });
  return {
    ...materialState,
    id: payload.id,
    projectId: payload.projectId,
    division: payload.division,
    category: payload.category,
    title: payload.title,
    note: payload.note,
    equivIfApproved: payload.equivIfApproved,
    titleTag: payload.titleTag ? MaterialTag.fromAPI(payload.titleTag) : null,
    equivilents: payload.equivilents.map((eq) => equiv.fromAPI(eq)),

    code: payload.code,
    tagList: payload.tagList,
    nested: payload.nested,
  };
}

/**
  Load a single material from the server

  @param {string} projectId - A uuid project identifer
  @param {string} materialId - A uuid material identifer
  @returns {types/material} The material state object
*/
export async function loadMaterial(projectId, materialId) {
  try {
    return apit
      .get(`/v2/material/${projectId}/${materialId}`)
      .then((results) => fromAPI(results.data.data));
  } catch (e) {
    Logger.error(`loading material for ${projectId}/${materialId} failed`, e);
    return null;
  }
}

/**
  Load all materials for a project from the server

  @param {string} projectId - a uuid project identifer
  @returns {types/material[]} All of the materials
*/
export async function loadMaterials(projectId) {
  try {
    // Logger.debug('Loading Materials for Project', projectId);
    return await apit
      .get(`/v2/material/${projectId}`)
      .then((results) => results.data.data.map((inmat) => fromAPI(inmat)));
  } catch (e) {
    Logger.error(`loading materials for ${projectId} failed`, e);
    return [];
  }
}

/**
  Save a single material to the server

  @param {types/material} state - a material object
  @returns {Promise} The material object after update
 */
export async function saveMaterial(state) {
  if (!state) {
    Logger.error('saveMaterial() failed, state is null');
    return null; // THIS IS AN ERROR!
  }

  const savState = { ...state };

  // realistically, can update this when the category changes.
  savState.division = await getDivisionByCategoryId(savState.category);

  if (!state.title) {
    savState.title = 'Unnamed Material';
  }
  Logger.debug('** ABOUT TO SAVE A STATE', savState);
  return apit
    .put(`/v2/material/${state.projectId}/${state.id}`, toAPI(savState))
    .then((response) => response.data);
}
