import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class HelpDialog extends React.Component {
  state = {
    open: false,
    scroll: 'paper',
  };

  constructor(props) {
    super(props);

    this.state.open = props.open;
    this.state.onCloseHelp = props.onClose;
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ open: this.props.open });  
    }
  }

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = () => {
    const { state } = this;
    this.setState({ open: false });
    state.onCloseHelp();
  };


  render() {
    const { state } = this;
    return (
      <Dialog
        open={state.open}
        onClose={this.handleClose}
        scroll={state.scroll}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">Inspex Help</DialogTitle>
        <DialogContent>
          <div style={{ color: '#333' }}>
            <h4>Zoom:</h4>
            <ul><li>ALT key + scroll the Mouse Wheel to Zoom in and out</li></ul>
            <h4>Panning:</h4>
            <ul><li>ALT key + hold down the left mouse button and move mouse left/right</li></ul>
            <br />
            <h4>Material Editor</h4>
            <ul>
              <li>While holding the ctrl key, highlight text</li>
              <li>The editor will appear</li>
              <li>Drag the red bar showing the highlighted text to either the Brand, Product or Notes field.</li>
              <li>Click outside of the editor, or the &apos;X&apos;, to close the editor.</li>
              <li>Pressing the &apos;r&apos; key, will create a new row. (like clicking &apos;Create New Equivilent Row&apos;)</li>
              <li>Pressing &apos;b&apos; key, will place the content into the latest Brand</li>
              <li>Pressing &apos;p&apos; key, will place the content into the latest Product</li>
              <li>Pressing &apos;n&apos; key, will place the content into the latest Note</li>
              <li>Pressing &apos;x&apos; key, will Explode content, creating a new Row, and then placing the exploded content into the Brand and Product fields</li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default HelpDialog;
