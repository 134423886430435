import styled from 'styled-components';
import { Table as MuiTable } from '@material-ui/core';
import MuiPagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Table = styled(MuiTable)`
  thead {
    tr {
      background-color: #f0f0f0;
    }
  }

  tbody {
    tr:nth-of-type(even) {
      background-color: #f5f5f5;
    }
  }
`;

export const Pagination = styled(MuiPagination)`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;

export const Loader = styled(CircularProgress)`
  display: block !important;
  margin: 50px auto;
`;
