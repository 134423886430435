import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import { Popover, TableCell, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import { Thumbnail } from './Thumbnail';

export const MappedProductPreviewPopover = ({ product, asset }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    console.log('in', event);
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    console.log('out');
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!product) {
    return null;
  }

  return (
    <>
      <div style={{ padding: '5px 10px' }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href=""
          style={{ fontSize: 12, color: 'black' }}
          onClick={handlePopoverOpen}
        >
          Currently Mapped to: <strong>{product.id}</strong>
        </a>
        &nbsp;
        <HelpIcon
          color="primary"
          style={{ fontSize: 18, position: 'relative', top: 4, cursor: 'help' }}
        />
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
      >
        <div style={{ padding: 10 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <strong style={{ paddingTop: 7 }}>Currently Mapped To</strong>
            <IconButton size="small" onClick={handlePopoverClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell variant="head">Product</TableCell>
                <TableCell>{product?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Manufacturer</TableCell>
                <TableCell>{product?.manufacturer_name}</TableCell>
              </TableRow>
              {product?.dp_category_names.length > 0 && (
                <TableRow>
                  <TableCell variant="head">Categories</TableCell>
                  <TableCell>{product.dp_category_names.join(',')}</TableCell>
                </TableRow>
              )}
              {asset && (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Thumbnail name={asset.name} url={asset.url} />
                    </div>
                    <div>
                      {asset.referenceOnly ? 'Image for reference' : ''}
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Popover>
    </>
  );
};

MappedProductPreviewPopover.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  asset: PropTypes.object.isRequired,
};
