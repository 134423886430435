/* eslint-disable react/prop-types */
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';
import AlertDialog from '../components/AlertDialog';
import projectsService from '../services/projects';

export default function SearchMatchActions({
  disabled,
  projectId,
  status,
  onSucess,
  onFailed,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  // eslint-disable-next-line no-param-reassign
  status = status || 'Unassigned';
  const forceDisable = status === 'Ready To Archive' || status === 'Importing';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveProjectStatus = async (newStatus) => {
    if (!newStatus) {
      return;
    }

    setLoading(true);
    const response = await projectsService.changeStatus(projectId, newStatus);
    if (response.status === 'Sucess') {
      enqueueSnackbar('Status Updated', {
        variant: 'success',
      });
      onSucess && onSucess(newStatus);
    } else {
      enqueueSnackbar('Status Updated', {
        variant: 'error',
      });
      onFailed && onFailed();
    }
    setLoading(false);
    handleClose();
  };

  const handleOnConfirm = (confirm) => {
    if (confirm) {
      saveProjectStatus('Importing');
    } else {
      handleClose();
    }
    setShowAlert(false);
  };

  if (!projectId) {
    return null;
  }

  return (
    <div>
      <IconButton
        disabled={!!disabled || loading || forceDisable}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          disabled={status === 'Unassigned'}
          onClick={() => saveProjectStatus('Unassigned')}
        >
          Unassigned
        </MenuItem>
        <MenuItem
          disabled={status === 'Ignored'}
          onClick={() => saveProjectStatus('Ignored')}
        >
          Ignored
        </MenuItem>
        <MenuItem
          disabled={status === 'Ignored'}
          onClick={() => setShowAlert(true)}
        >
          Import
        </MenuItem>
      </Menu>
      <AlertDialog
        open={showAlert}
        onClose={handleOnConfirm}
        confirm="Import"
        refuse="Cancel"
        message="You are about to import this project, once started the import cannot be stoped. Are you sure you want to import it?"
        title="Confirmation"
      />
    </div>
  );
}
