/* eslint-disable no-undef */
import { useState, useEffect } from 'react';
import * as PDFJS from 'pdfjs-dist';
import apit from '../lib/api';

const CMAP_URL = '/pdfjs-copy/cmaps/';
const CMAP_PACKED = true;

PDFJS.GlobalWorkerOptions.workerSrc = '/pdfjs-copy/pdf.worker.min.js';

export default function PdfLoader(props) {
  // console.log('PDFLoader:constructor()', props);
  const [state, setState] = useState({
    url: props.url,
    pdfDocument: null,
    orig: true,
  });

  useEffect(() => {
    async function getPDF() {
      // console.log('PDFLoader.getPDF() called');
      const opts = apit.pdfload(state.url);
      opts.CMAP_PACKED = CMAP_PACKED;
      opts.CMAP_URL = CMAP_URL;

      PDFJS.getDocument(opts)
        .promise.then((pdfDoc) => {
          if (state.pdfDocument !== pdfDoc) {
            setState({
              pdfDocument: pdfDoc,
            });
          }
        })
        .catch((e) => {
          alert(`Failed to load PDF: ${e.message}`);
        });
    }
    getPDF();
  }, []);

  if (state.pdfDocument) {
    return props.children(state.pdfDocument);
  }

  return props.beforeLoad;
}
