/* global localStorage */
import jwtDecode from 'jwt-decode';
import history from './history';

let acct = null;

function nullAccount() {
  return {
    is_active: false,
    permissions: 0,
  };
}

function clearJWT() {
  localStorage.removeItem('jwtToken');
}
function getJWT() {
  return localStorage.getItem('jwtToken');
}

function getAccount() {
  acct = nullAccount();

  const jwt = getJWT();

  try {
    const user = jwtDecode(jwt);
    if (user) {
      acct = user.account;
    }
  } catch (Error) {
    // acct is null already
  }
}

const Auth = {
  logout: () => {
    acct = null;
    clearJWT();
    history.push('/login');
  },
  isActive: () => {
    if (!acct) {
      getAccount();
    }
    return acct.is_active;
  },
  getToken: () => getJWT(),

  //
  // is*() :  Check if the user *is* the settings
  //    see has*() if you want to check permission levels
  //
  isAdministrator: () => {
    if (!acct) {
      getAccount();
    }
    return acct.permissions === 1;
  },
  isManager: () => {
    if (!acct) {
      getAccount();
    }
    return acct.permissions === 2;
  },
  isEditor: () => {
    if (!acct) {
      getAccount();
    }
    return acct.permissions === 4;
  },

  //
  // has*() :  Check if the user *has* the permission or better
  //    see is*() if you want to check the actual permission of a user
  //
  hasAdministrator: () => {
    if (!acct) {
      getAccount();
    }
    return acct.permissions === 1;
  },
  hasManager: () => {
    if (!acct) {
      getAccount();
    }
    return acct.permissions === 2 || Auth.isAdministrator();
  },
  hasEditor: () => {
    if (!acct) {
      getAccount();
    }
    return acct.permissions === 4 || Auth.hasManager();
  },
};

export default Auth;
