/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import {
  Accordion as ExpansionPanel,
  AccordionSummary as ExpansionPanelSummary,
  AccordionDetails as ExpansionPanelDetails,
} from '@material-ui/core';
import Pill from './Pill';

import DivisionManager from '../Material/types/DivisionManager';
import {
  filterDivision,
  setCurrentMaterial,
} from '../Material/types/projectMaterials';

//
//  this list is derived from a list provided in the Project Spreadsheet.
//  based on CSI divisions
//

const divMgr = new DivisionManager();

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '90%',
    flexShrink: 0,
  },
}));

export default function MaterialNavigation(props) {
  const classes = useStyles();
  const { materials, setMaterials } = props;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const chooseMaterial = (materialId) => {
    setMaterials(setCurrentMaterial(materials, materialId));
  };

  console.log('MaterialNavigation.render()', materials);
  return (
    <div className={classes.root}>
      {divMgr.divisions.map((division) => {
        const matState = filterDivision(materials, division.value);
        const mats = Object.values(matState.materialIds);
        return (
          <ExpansionPanel
            key={division.label}
            expanded={expanded === division.value}
            onChange={handleChange(division.value)}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                {division.label}
              </Typography>
              <Pill count={mats.length} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List component="nav">
                {mats.map((material) => (
                  <ListItem
                    key={material.id}
                    button
                    onClick={() => chooseMaterial(material.id)}
                  >
                    <ListItemText primary={material.title} />
                  </ListItem>
                ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
}
