import React from 'react';
import { Link } from 'react-router-dom';
import apit from '../lib/api';
import Header from '../components/Header';

class Jobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFinished: false,
      jobs: [],
      jobdate: new Date(),
    };

    this.queryJobs = this.queryJobs.bind(this);
  }

  componentDidMount() {
    this.queryJobs();
  }

  queryJobs() {
    apit.getjson('/v2/job/')
      .then((res) => {
        this.setState({ jobs: res.jobs, jobdate: new Date() });
        setInterval(this.checkStatus, 10000);
      })
      .catch(err => console.log(err));
  }

  render() {
    const { jobs, jobdate } = this.state;

    return (
      <div>
        <Header title="Accounts" />
        <div className="accounts">
          <h2>
            {'Jobs'}
          </h2>
          <p>There are {jobs.length} jobs. Last checked: {jobdate.toLocaleString('en-US')}</p>
          <table style={{width: '80%'}}>
            <thead>
              <tr>
                <th>Job ID</th>
                <th>Status</th>
                <th>Job Type</th>
                <th>Create Date</th>
              </tr>
            </thead>
            <tbody>
            {jobs.map((job, index) => (
              <tr
                key={index}
                className="job"
              >
                <td>
                  <Link to={`/job/${job.id}/`}>
                  {job.id}
                </Link>
                </td>
                <td>{job.status}</td>
                <td>{job.type}</td>
                <td>{job.createdAt}</td>
                </tr>
            ))}
            </tbody>
            </table>
        </div>
        <div>
          <br />
          <br />
          <b>Notice</b>: Any jobs that have a created date more than a day ago is probably not actually running.
          <br /><br/>
          <b>Statuses:</b>
          <ul>
            <li>0: Waiting to execute</li>
            <li>1: Running</li>
            <li>2: Finished</li>
          </ul>
          <br />
          <b>Job Types</b>
          <ul>
            <li>1: Download Job - Download PDF from Google Drive</li>
            <li>2: Process Job - Extract Products and Brands from the PDF</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Jobs;
