import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { isValid, formatRelative, parseISO } from 'date-fns';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 900,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  margin: {
    margin: theme.spacing(2),
  },
});


function CustomizedTable(props) {
  const { classes, records } = props;
  const now = new Date();
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell>ObjectType</CustomTableCell>
            <CustomTableCell>Action</CustomTableCell>
            <CustomTableCell>Status</CustomTableCell>
            <CustomTableCell>Date</CustomTableCell>
            <CustomTableCell>Result</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map(row => {            
            let actionDate = 'Unknown';
            if (isValid(parseISO(row.date))) {
              const p = parseISO(row.date);
              actionDate = formatRelative(p, now);
            }

            return (
              <TableRow className={classes.row} style={{cursor:'pointer'}} key={row.id}>
                {/* <CustomTableCell component="th" scope="row">{row.id}</CustomTableCell>
                <CustomTableCell>{row.objectId}</CustomTableCell> */}
                <CustomTableCell>{row.objectType}</CustomTableCell>
                <CustomTableCell>{row.action}</CustomTableCell>
                <CustomTableCell>
                  {row.status}
                </CustomTableCell>
                <CustomTableCell>{actionDate}</CustomTableCell>
                <CustomTableCell>
                  {JSON.stringify(row.result)}
                </CustomTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);
