// this is offically sad, but we're trying to get stuff done...
export function PermsToString(perm) {
  switch (perm) {
    case 1:
      return 'Administrator';
    case 2:
      return 'Manager';
    case 4:
      return 'Editor';
    default:
      return 'No Access';
  }
}

export function StringToPerms(str) {
  switch (str) {
    case 'Administrator':
      return 1;
    case 'Manager':
      return 2;
    case 'Editor':
      return 4;
    default:
      return 0;
  }
}

export const Permissions = [
  'No Access',
  'Administrator',
  'Manager',
  'Editor',
];
