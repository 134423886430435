/**
  Module that defines Project Materials

  These objects are the complete definition of a material.

  They MUST correllate with the API's definition of a material.
  And therefore, the synchronization between the two occurs here.

  @module types/projectMaterials
 */

import * as Logger from '../../lib/logger';

import * as Material from './material';

const initialMaterials = {
  currentMaterialId: null,
  materialIds: {}, // by id
  projectId: '', // a uuid
};

/**
  Initialize the materials state

  @param {number} projectId - The project id
  @return {types/projectMaterials} The updated materials state
*/
export function initialize(projectId) {
  // Logger.debug('*******************initialize', projectId);
  return {
    ...initialMaterials,
    projectId,
  };
}
// export function initializeMaterials(projectId) {
//   // Logger.debug('** INITIALIZING MATERIALS', projectId);
//   const blankMat = Material.initialize(projectId);

//   return {
//     ...initialMaterials,
//     currentMaterialId: blankMat.id,
//     materialIds: {
//       [blankMat.id]: blankMat,
//     },
//     projectId,
//   };
// }

/**
  Load the project materials based on the project id

  @param {number} projectId - The project id
  @return {types/projectMaterials} The updated materials state
*/
export async function loadMats(projectId) {
  const matids = await Material.loadMaterials(projectId);
  const mm = {};
  // this kindof sucks... we need at least 1 material to exist during initial rendering
  let curr = null;
  //    we should make that requirement go away.
  for (let i = 0; i < matids.length; i += 1) {
    if (!curr) {
      curr = matids[i].id;
    }
    mm[matids[i].id] = matids[i];
  }

  if (matids.length < 1) {
    Logger.warn('*******************initialize inside loadMats', projectId);
    return initialize(projectId);
  }

  return {
    ...initialMaterials,
    currentMaterialId: curr,
    projectId,
    materialIds: mm,
  };
}

/**
  Load the project materials based on the project id

  @param {types/projectMaterials} state - The materials to operate on
  @param {number} projectId - The project id
  @return {types/projectMaterials} The updated materials state
*/
// export async function loadProjectMaterials(state = initialMaterials, projectId) {
//   const matids = await Material.loadMaterials(projectId);
//   const mm = {};
//   // this kindof sucks... we need at least 1 material to exist during initial rendering
//   let curr = null;
//   //    we should make that requirement go away.
//   for (let i = 0; i < matids.length; i += 1) {
//     if (!curr) {
//       curr = matids[i].id;
//     }
//     mm[matids[i].id] = matids[i];
//   }

//   if (matids.length < 1) {
//     return state;
//   }

//   return {
//     ...state,
//     currentMaterialId: curr,
//     projectId,
//     materialIds: mm,
//   };
// }

/**
  Update the given material

  @param {types/projectMaterials} state - The materials to operate on
  @param {material} materialId - The material to update
  @return {types/projectMaterials} The updated materials state
*/
export function updateMaterial(state = initialMaterials, payload) {
  if (!payload?.id) {
    return state;
  }
  return {
    ...state,
    materialIds: {
      ...state.materialIds,
      [payload.id]: {
        ...state.materialIds[payload.id],
        ...payload,
      },
    },
  };
}

// given the material, copy it
// note that this does not return the materials object, but rather just the single material
export function duplicateMaterial(state = initialMaterials, currentMaterial) {
  return Material.duplicateMaterial(currentMaterial);
  // return {
  //   ...state,
  //   materialIds: {
  //     ...state.materialIds,
  //     [newMat.id]: {
  //       ...newMat,
  //     },
  //   },
  // };
}

/**
  Get the current material

  @param {types/projectMaterials} state - The materials to operate on
  @return {types/material} The material object
*/

export function getCurrentMaterial(state = initialMaterials) {
  // Logger.debug('getCurrentMaterial:', state);
  if (!state) {
    return null;
  }
  return state.materialIds[state.currentMaterialId];
}

/**
  get the material based on the given material id

  @param {types/projectMaterials} state - The materials to operate on
  @param {number} materialId - The material id to use as the current material
  @return {types/material} The material object
*/
export function getMaterial(state = initialMaterials, materialId) {
  return state.materialIds[materialId];
}

/**
  Set the current material to the given material id

  @param {types/projectMaterials} state - The materials to operate on
  @param {number} materialId - The material id to use as the current material
  @return {types/projectMaterials} The updated materials state
*/
export function setCurrentMaterial(state = initialMaterials, materialId) {
  return {
    ...state,
    currentMaterialId: materialId,
  };
}

/**
  Return materials that match a given division

  @param {types/projectMaterials} state - The materials to operate on
  @param {number} division - The division to filter on
  @return {types/projectMaterials} The materials matching the division
*/
export function filterDivision(state = initialMaterials, division) {
  const filtered = {};
  Object.keys(state.materialIds).forEach((k) => {
    // yup double eq on next line, as could be a number or a string
    // eslint-disable-next-line eqeqeq
    if (state.materialIds[k].division == division) {
      filtered[k] = state.materialIds[k];
    }
  });
  // Logger.debug(`FILTERING DIVISION ${division}`, { filtered, state });
  return {
    ...state,
    materialIds: filtered,
  };
}

/**
  Get the tags for all materials
 */
export function getMaterialTags(state = initialMaterials) {
  const tags = [];
  console.log('*** getMaterialTags:State', state);
  Object.keys(state.materialIds).forEach((k) => {
    const mat = state.materialIds[k];
    console.log('*** getMaterialTags', mat);
    if (mat.titleTag) {
      tags.push({ tag: mat.titleTag, type: 'material' });
    }
    if (mat.equivilents) {
      for (let i = 0; i < mat.equivilents.length; i += 1) {
        const eqv = mat.equivilents[i];
        if (eqv.brand && eqv.brand.tag) {
          tags.push({ tag: eqv.brand.tag, type: 'brand' });
        }
        if (eqv.product && eqv.product.tag) {
          tags.push({ tag: eqv.product.tag, type: 'product' });
        }
      }
    }
  });
  return tags;
}

//
// given a list of materials (state), and a material that has changed nested information (updatedmaterial)
//   make sure existing materials have accurate representation of nested properties
// we have to both, remove references that are gone, and add references that have been added
export function updateNestedReferences(state, updatematerial) {
  // remove all references
  const matids = {};

  Object.keys(state.materialIds).forEach((k) => {
    const mat = state.materialIds[k];
    mat.nested.parent = mat.nested.parent.filter(
      (i) => i !== updatematerial.id
    );
    mat.nested.child = mat.nested.child.filter((i) => i !== updatematerial.id);

    updatematerial.nested.child.forEach((c) => {
      if (mat.id === c) {
        mat.nested.parent.push(updatematerial.id);
      }
    });
    updatematerial.nested.parent.forEach((p) => {
      if (mat.id === p) {
        mat.nested.child.push(updatematerial.id);
      }
    });
    matids[k] = mat;
  });

  return {
    ...state,
    materialIds: matids,
  };
}
