import apit from '../lib/api';

const getDivisions = async () => {
  const response = await apit.get('/v2/division/');
  const body = await response.data;
  if (response.status !== 200) throw Error(body.message);

  return body.data;
};

export default {
  getDivisions,
};
