import apit from '../lib/api';

const getAll = async (projectId) => {
  const response = await apit.get(`/dp/project/${projectId}/assets`);
  const body = await response.data;
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body.assets;
};

const getProductAssets = async (productId) => {
  const response = await apit.get(`/dp/product/${productId}/assets`);
  const body = await response.data;
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body.data;
};

const download = async (assetId, fileName = 'document.pdf') => {
  await apit.download(`/dp/asset/${assetId}/download`, fileName);
};

export default {
  getAll,
  download,
  getProductAssets,
};
