import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HistoryTable from './HistoryTable';
import apit from '../lib/api';

const logger = require('../lib/logger');


class HistoryDialog extends React.Component {
  state = {
    open: false,
    scroll: 'paper',

    historyId: '',
    records: [],
  };

  constructor(props) {
    super(props);

    this.state.historyId = props.historyId;
    this.state.open = props.open;
    this.state.onCloseHelp = props.onClose;
  }

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      await this.setState({ open: this.props.open, historyId: this.props.historyId });

      if (this.state.historyId) {
        logger.info('calling since props updated', this.state.historyId);
        const response = await apit.get(`/v2/history/${this.state.historyId}`);
        const body = await response.data;
        if (response.status !== 200) throw Error(body.message);
        this.setState({ records: body.history });
      }
    }
  }

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  }

  handleClose = () => {
    this.setState({ open: false });
    this.state.onCloseHelp();
  }

  render() {
    const { state } = this;
    return (
      <Dialog
        open={state.open}
        onClose={this.handleClose}
        scroll={state.scroll}
        fullWidth
        maxWidth="lg"
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">History Summary</DialogTitle>
        <DialogContent>
          <div style={{ color: '#333' }}>
            <h4>History:</h4>
            <HistoryTable records={state.records} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default HistoryDialog;
