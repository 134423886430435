/* global document */
import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, useSnackbar } from 'notistack';
import 'typeface-roboto';
import PropTypes from 'prop-types';

import './style/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const DismissAction = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <React.Fragment>
      <Button onClick={() => closeSnackbar(id)}>Close</Button>
    </React.Fragment>
  );
};

DismissAction.propTypes = {
  id: PropTypes.string.isRequired,
};

ReactDOM.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    action={(key) => <DismissAction id={key.toString()} />}
  >
    <App />
  </SnackbarProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
