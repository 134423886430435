import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinkIcon from '@material-ui/icons/Link';
import LockIcon from '@material-ui/icons/Lock';
import PublicIcon from '@material-ui/icons/Public';
import BlockIcon from '@material-ui/icons/Block';
import { debounce } from 'lodash';
import { Thumbnail } from './Thumbnail';
import { MappedProductPreviewPopover } from './MappedProductPreviewPopover';
import DesignerPagesService from '../services/designer-pages';
import {
  Wrapper,
  SearchContainer,
  PreviewContainer,
  TableContainer,
  TableCell,
  PreviewHeader,
  ThumbnailsContainer,
  Centered,
  SmallText,
  InlineContainer,
  ProjectCount,
} from './ProductSearchModal.styles';
import { ShortenTextPopover } from './ShortenTextPopover';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
  },
}))(MuiDialogActions);

export const ProductSearchModal = ({
  open,
  currentMaterial,
  currentProduct,
  currentAsset,
  defaultSearch,
  onSave,
  onCancel,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const imageInputRef = React.useRef();

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [search, setSearch] = useState(defaultSearch);
  const [products, setProducts] = useState([]);
  const [assets, setAssets] = useState([]);
  const [fetchedProduct, setFetchedProduct] = useState({});
  const [fetchedProjectsNumber, setFetchedProjectsNumber] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(currentProduct);
  const [selectedAsset, setSelectedAsset] = useState(currentAsset);
  const [referenceOnly, setReferenceOnly] = useState(
    currentAsset?.name === 'Image for reference' || currentAsset?.referenceOnly
  );

  const searchProducts = useCallback(
    debounce(async (term) => {
      if (!term) {
        return;
      }

      setLoading(true);
      const newProducts = await DesignerPagesService.searchProduct(term);
      setProducts(newProducts || []);
      setLoading(false);
    }, 500),
    []
  );

  const getAssets = async (productId) => {
    setAssetsLoading(true);
    const response = await DesignerPagesService.getProduct(productId);
    const newAssets = response.images_for_lightbox_image_selector || [];
    setAssets(Array.isArray(newAssets) ? newAssets : [newAssets]);
    setFetchedProduct(response.product || {});
    setFetchedProjectsNumber(response.projects_number || 0);
    setAssetsLoading(false);
  };

  const handleProductChange = (newProduct) => {
    if (newProduct) {
      if (newProduct.id === selectedProduct?.id) {
        setSelectedProduct(null);
        setAssets([]);
        setFetchedProduct({});
        setFetchedProjectsNumber(0);
      } else {
        setSelectedProduct(newProduct);
        getAssets(newProduct.id);
      }
    }
    setSelectedAsset(null);
    setReferenceOnly(false);
  };

  const handleAssetChange = (asset) => {
    setSelectedAsset(asset);
    setReferenceOnly(false);
  };

  const handleFileChange = async (evt) => {
    setUploading(true);
    const file = evt?.target?.files ? evt.target.files[0] : null;
    if (!file) {
      return;
    }

    const caption = window.prompt(
      'Please enter the image caption (Optional)',
      ''
    );

    try {
      await DesignerPagesService.uploadProductAsset(
        selectedProduct.id,
        file,
        caption
      );
      enqueueSnackbar('Image Uploaded.', {
        variant: 'success',
      });
      await getAssets(selectedProduct.id);
    } catch (e) {
      enqueueSnackbar('Error uploading image, try again.', {
        variant: 'error',
      });
    }
    imageInputRef.current.value = '';
    setUploading(false);
  };

  const handleClose = () => {
    onCancel && onCancel();
  };

  const handleSave = () => {
    const selection = {
      product: selectedProduct,
      asset: {
        id: selectedAsset?.id,
        name: selectedAsset?.image_caption || '',
        url: selectedAsset?.url,
        width: selectedAsset?.width,
        height: selectedAsset?.height,
        referenceOnly,
      },
    };
    onSave && onSave(selection);
  };

  useEffect(() => {
    if (search) {
      searchProducts(search);
    }
    if (currentProduct) {
      getAssets(currentProduct.id);
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle onClose={handleClose}>Product Mapping</DialogTitle>
      <DialogContent dividers style={{ minHeight: 400 }}>
        <h4 style={{ marginTop: 0 }}>Product</h4>
        <InlineContainer>
          <Table size="small">
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{currentMaterial?.product}</TableCell>
              <TableCell>{currentMaterial?.manufacturer}</TableCell>
              <TableCell>
                <ShortenTextPopover text={currentMaterial?.notes || ''} />
              </TableCell>
            </TableRow>
          </Table>
        </InlineContainer>
        <h4>DP Product:</h4>
        <Wrapper>
          <SearchContainer>
            <TextField
              label="Product Name"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                searchProducts(e.target.value);
              }}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
            <TableContainer>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell>ID</TableCell>
                    {/* <TableCell>Projects</TableCell> */}
                    <TableCell>Stub</TableCell>
                    <TableCell>Disc.</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Manufacturer</TableCell>
                    <TableCell>Category</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading && !products.length && (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        {search
                          ? 'No products found.'
                          : 'Type in the product name'}
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    products.map((p) => (
                      <TableRow
                        key={p.id}
                        style={{
                          backgroundColor:
                            selectedProduct?.id === p.id ? '#eee' : null,
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedProduct?.id === p.id}
                            onClick={() => handleProductChange(p)}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            href={`https://www.designerpages.com/products/${p.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {p.id}
                          </Button>
                        </TableCell>
                        {/* <TableCell>{p.saved_products_count}</TableCell> */}
                        <TableCell>
                          {p.is_stub && p.product_type === 0 && (
                            <LockIcon style={{ fontSize: 12 }} />
                          )}
                          {p.is_stub && p.product_type === 1 && (
                            <PublicIcon style={{ fontSize: 12 }} />
                          )}
                        </TableCell>
                        <TableCell>
                          {p.discontinued_at && (
                            <BlockIcon style={{ fontSize: 12 }} />
                          )}
                        </TableCell>
                        <TableCell>{p.name}</TableCell>
                        <TableCell>{p.manufacturer_name}</TableCell>
                        <TableCell>
                          {(p.dp_category_names || []).join(', ')}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SearchContainer>
          <PreviewContainer>
            <PreviewHeader>
              <div>
                <h2>{selectedProduct?.name || 'Product Name'}</h2>
                {selectedProduct?.id && (
                  <IconButton
                    href={`https://www.designerpages.com/products/${selectedProduct.id}`}
                    target="_blank"
                    rel="noreferrer"
                    size="small"
                    color="primary"
                  >
                    <LinkIcon />
                  </IconButton>
                )}
              </div>
              {selectedProduct && !assetsLoading && (
                <InlineContainer>
                  <div>
                    <SmallText>
                      Manufacturer:&nbsp;
                      {selectedProduct.manufacturer_name}
                      {fetchedProduct?.website && (
                        <IconButton
                          href={fetchedProduct?.website}
                          target="_blank"
                          rel="noreferrer"
                          size="small"
                          color="secondary"
                        >
                          <LinkIcon />
                        </IconButton>
                      )}
                    </SmallText>
                    <br />
                    <SmallText>
                      Categories:&nbsp;
                      {(selectedProduct.dp_category_names || []).join(', ')}
                    </SmallText>
                  </div>
                  <div>
                    <ProjectCount>
                      Added to Project: <strong>{fetchedProjectsNumber}</strong>
                    </ProjectCount>
                  </div>
                </InlineContainer>
              )}
            </PreviewHeader>
            <ThumbnailsContainer>
              {(assetsLoading || uploading) && (
                <Centered>
                  <CircularProgress />
                </Centered>
              )}
              {!(assetsLoading || uploading) && !selectedProduct && (
                <Centered>Select a product</Centered>
              )}
              {!(assetsLoading || uploading) && selectedProduct && (
                <Thumbnail
                  selected={!selectedAsset?.id}
                  onSelect={() => handleAssetChange(null)}
                />
              )}
              {!(assetsLoading || uploading) &&
                assets.map((a) => (
                  <Thumbnail
                    key={a.id}
                    name={a.image_caption}
                    url={a.url}
                    selected={selectedAsset?.id === a.id}
                    onSelect={() => handleAssetChange(a)}
                  />
                ))}
            </ThumbnailsContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!selectedAsset?.id}
                    checked={referenceOnly || false}
                    onChange={(e) => setReferenceOnly(e.target.checked)}
                  />
                }
                label="Image only for reference"
              />
              <div style={{ marginTop: 5 }}>
                <label htmlFor="upload-file">
                  <input
                    ref={imageInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-file"
                    onChange={handleFileChange}
                  />
                  <Button
                    component="span"
                    variant="text"
                    color="secondary"
                    size="small"
                    disabled={!selectedProduct || uploading}
                  >
                    <CloudUploadIcon /> &nbsp; Upload Image
                  </Button>
                </label>
              </div>
            </div>
          </PreviewContainer>
        </Wrapper>
      </DialogContent>
      <DialogActions>
        <MappedProductPreviewPopover
          product={currentProduct}
          asset={currentAsset}
        />
        <div>
          <Button autoFocus onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleSave} color="primary">
            Save Selection
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ProductSearchModal.defaultProps = {
  open: false,
  currentMaterial: null,
  currentProduct: null,
  currentAsset: null,
  defaultSearch: '',
};

ProductSearchModal.propTypes = {
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  currentMaterial: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  currentProduct: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  currentAsset: PropTypes.object,
  defaultSearch: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
