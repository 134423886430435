/* eslint-disable no-undef */
import React from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AlertDialog from '../components/AlertDialog';
import PickUserDialog from '../components/PickUser';
import projectsService from '../services/projects';
import Auth from '../lib/Auth';

export default function ProjectActionsMenu({
  projectId,
  assignedUserName,
  onProjectArchived,
  onHistoryClick,
  onAssignedToChange,
  onFileUploaded,
}) {
  const inputRef = React.createRef();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showUserDialog, setShowUserDialog] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const open = Boolean(anchorEl);

  const archiveProject = async () => {
    try {
      const archived = await projectsService.archive(projectId);
      if (archived) {
        enqueueSnackbar('Project Archived!', { variant: 'success' });
        onProjectArchived && onProjectArchived(projectId);
      } else {
        enqueueSnackbar('Failed to archive project', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const assignToUser = async (user) => {
    try {
      const assigned = await projectsService.assign(projectId, user.id);
      if (assigned) {
        enqueueSnackbar('User Assigned', { variant: 'success' });
        onAssignedToChange && onAssignedToChange(user);
      } else {
        enqueueSnackbar('Failed to assign user', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBrowseFiles = () => {
    inputRef.current && inputRef.current.click();
    handleClose();
  };

  const handleUploadFiles = async () => {
    const files = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < inputRef.current.files.length; index++) {
      const file = inputRef.current.files[index];
      if (!file.name.match(/.pdf$/)) {
        enqueueSnackbar('Invalid file extension', { variant: 'error' });
        return;
      }
      files.push(file);
      console.log(file);
    }
    // inputRef.current.files = new FileList();
    try {
      await projectsService.uploadFiles(projectId, files);
      enqueueSnackbar('File uploaded succesfully', { variant: 'success' });
      onFileUploaded && onFileUploaded();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const handleShowArchiveDialog = () => {
    if (projectId) {
      setShowConfirm(true);
    }
    handleClose();
  };

  const handleShowUserDialog = () => {
    if (projectId) {
      setShowUserDialog(true);
    }
    handleClose();
  };

  const handleShowHistory = () => {
    if (projectId) {
      onHistoryClick && onHistoryClick(projectId);
    }
    handleClose();
  };

  const handleShowMaterials = () => {
    if (projectId) {
      // history.push(`/project/${projectId}/materials`);
      window.open(`/#/project/${projectId}/materials`, '_blank');
    }
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {Auth.hasManager() && (
          <MenuItem onClick={handleBrowseFiles}>Upload Files</MenuItem>
        )}
        {Auth.hasManager() && (
          <MenuItem onClick={handleShowArchiveDialog}>Archive Project</MenuItem>
        )}
        {Auth.hasManager() && (
          <MenuItem onClick={handleShowUserDialog}>Assign User</MenuItem>
        )}
        {Auth.hasManager() && (
          <MenuItem onClick={handleShowHistory}>Show History</MenuItem>
        )}
        <MenuItem onClick={handleShowMaterials}>Show Products</MenuItem>
      </Menu>
      <AlertDialog
        open={showConfirm}
        onClose={(confirm) => {
          confirm && archiveProject();
          setShowConfirm(false);
        }}
        title="Archive Project"
        message="Are you sure you want to archive this project? It will no longer be accessible"
      />
      <PickUserDialog
        title="Assign User"
        selectedValue={assignedUserName}
        open={showUserDialog}
        onClose={(user) => {
          assignToUser(user);
          setShowUserDialog(false);
        }}
      />
      <input
        multiple
        ref={inputRef}
        type="file"
        accept="application/pdf, .pdf"
        onChange={handleUploadFiles}
        style={{ display: 'none' }}
      />
    </div>
  );
}

ProjectActionsMenu.propTypes = {
  projectId: PropTypes.string.isRequired,
  assignedUserName: PropTypes.string.isRequired,
  onProjectArchived: PropTypes.func.isRequired,
  onHistoryClick: PropTypes.func.isRequired,
  onAssignedToChange: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
};
