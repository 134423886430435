/* eslint-disable no-undef */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import format from 'date-fns/format';
import SearchMatchResources from './SearchMatchResources';
import SearchMatchActions from './SearchMatchActions';

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#f0f0f0',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CustomButton = withStyles(() => ({
  root: {
    textTransform: 'none',
    textAlign: 'left',
    alignSelf: 'center',
  },
}))(Button);

export default function SearchMatchRow(props) {
  const { index, classes, row, onStatusUpdate } = props;

  const {
    id,
    name,
    match_date,
    total_matches,
    project_size,
    unit,
    bid_date,
    total_assets,
    project_manufacturers,
    external_url,
  } = row;

  const [isExpanded, setIsExpanded] = React.useState(false);

  const calcClassName = (projectStatus) => {
    switch (projectStatus) {
      case 'Ignored':
        return classes.textError;
      case 'Importing':
        return classes.textInfo;
      case 'Ready To Archive':
        return classes.textSuccess;

      default:
        return null;
    }
  };

  return (
    <>
      <TableRow
        className={classes.row}
        key={`${id}-collapsed`}
        style={{
          backgroundColor: index % 2 ? '#f5f5f5' : '',
        }}
      >
        <CustomTableCell style={{ borderBottomWidth: isExpanded ? 0 : 1 }}>
          <CustomButton
            color="primary"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {name}
          </CustomButton>
        </CustomTableCell>
        <CustomTableCell style={{ borderBottomWidth: isExpanded ? 0 : 1 }}>
          {match_date ? format(new Date(match_date), 'MM/dd/yyyy') : 'N/A'}
        </CustomTableCell>

        <CustomTableCell
          align="center"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          {total_matches || '0'}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          {project_manufacturers || '0'}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          {project_size || '0'} {unit || 'sqft'}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          <IconButton
            onClick={() =>
              window.open(
                `https://projects.analytics.design/projects/${id}`,
                '_blank'
              )
            }
          >
            <LinkIcon />
          </IconButton>
        </CustomTableCell>
        <CustomTableCell
          align="center"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          <IconButton
            onClick={() =>
              window.open(
                `https://www.designerpages.com/projects/${id}`,
                '_blank'
              )
            }
          >
            <LinkIcon />
          </IconButton>
        </CustomTableCell>
        <CustomTableCell
          align="center"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          <IconButton onClick={() => window.open(external_url, '_blank')}>
            <LinkIcon />
          </IconButton>
        </CustomTableCell>
        <CustomTableCell style={{ borderBottomWidth: isExpanded ? 0 : 1 }}>
          {bid_date ? format(new Date(bid_date), 'MM/dd/yyyy HH:mm') : 'N/A'}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          {total_assets || '0'}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          <span
            className={calcClassName(row.project_import_status)}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {row.project_import_status === 'Importing' && (
              <span style={{ alignSelf: 'center' }}>
                <CircularProgress size={20} style={{ marginRight: 10 }} />
              </span>
            )}
            <span style={{ alignSelf: 'center' }}>
              {row.project_import_status || 'Unassigned'}
            </span>
          </span>
        </CustomTableCell>
        <CustomTableCell
          align="right"
          style={{ borderBottomWidth: isExpanded ? 0 : 1 }}
        >
          <SearchMatchActions
            projectId={row.project_id}
            status={row.project_import_status}
            onSucess={(s) =>
              onStatusUpdate && onStatusUpdate(row.project_id, s)
            }
          />
        </CustomTableCell>
      </TableRow>
      {isExpanded && (
        <TableRow key={`${id}-expanded`}>
          <TableCell colSpan={20}>
            <SearchMatchResources
              projectId={id}
              status={row.project_import_status}
              onStatusChange={(s) =>
                onStatusUpdate && onStatusUpdate(row.project_id, s)
              }
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

SearchMatchRow.propTypes = {
  index: PropTypes.number.isRequired,
  classes: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  onStatusUpdate: PropTypes.func.isRequired,
};
