import axios from 'axios';

const DP_BASE_URL =
  process.env.NODE_ENV === 'development' && false
    ? 'http://localhost:3100'
    : 'https://www.designerpages.com';

const searchProduct = async (term) => {
  if (term) {
    const fields = [
      'id',
      'name',
      'manufacturer_id',
      'manufacturer_name',
      'dp_category_ids',
      'dp_category_names',
      'discontinued',
      'is_stub',
      'product_type',
      'saved_products_count',
    ];

    const params = {
      master_token: 'HL9SacqykIiBG13qtY3lVQ',
      per_page: 100,
      complete: true,
      fields: fields.join(','),
    };

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(term)) {
      params.term = term;
    } else {
      params.id = term;
    }

    const response = await axios.get(
      `${DP_BASE_URL}/api/v2/search/products/archive`,
      { params }
    );
    const body = await response.data;
    if (response.status !== 200) throw Error(body.message);

    return body.data;
  }
};

const getProduct = async (productId) => {
  if (productId) {
    const params = {
      id: productId,
      master_token: 'HL9SacqykIiBG13qtY3lVQ',
      key: '2c180042e581d5e9213b491192b75a85 ',
    };
    const response = await axios.get(
      `${DP_BASE_URL}/api/v2/products/images_with_firm.json`,
      { params }
    );
    const body = await response.data;
    if (response.status !== 200) throw Error(body.message);

    return body;
  }
};

const uploadProductAsset = async (productId, asset, caption) => {
  if (!productId || !(asset && asset instanceof File)) {
    throw new Error('Invalid params');
  } else {
    const params = {
      master_token: 'HL9SacqykIiBG13qtY3lVQ',
    };

    const formData = new FormData();
    formData.append('asset[product_id]', productId);
    formData.append('asset[filename]', asset);
    formData.append('format[json]', true);
    formData.append('firm_id', 11111);

    if ((caption || '').trim().length > 0) {
      formData.append('asset[name]', caption.trim());
    }

    const response = await axios.post(
      `${DP_BASE_URL}/api/v2/asset_files`,
      formData,
      {
        params,
      }
    );
    const body = await response.data;
    if (response.status !== 200) throw Error(body.message);

    return body;
  }
};

export default {
  searchProduct,
  getProduct,
  uploadProductAsset,
};
