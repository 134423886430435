import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import blue from '@material-ui/core/colors/blue';

import DataCache from '../lib/DataCache';
import apit from '../lib/api';

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

async function loadUsers() { 
  const response = await apit.get('/v2/account/');
  const body = await response.data;
  if (response.status !== 200) throw Error(body.message);

  return body.accounts;
}

const dataCache = DataCache(60 * 1000, loadUsers);


class PickUserDialog extends React.Component {

  state = {
    title: 'Select User',
    users: [],
    error: false,

  }
  constructor(props) {
    super(props);
    this.state.title = props.title || 'Select User';
  }

  componentDidMount() {
    if (this.state.error) {
      // errror state
      console.log(' load error state...  SO DONT TRY TO RELOAD');
    } else if ((!this.state.error && this.state.users.length < 1) || dataCache.isExpired()) {
      dataCache.getData()
               .then(res => {
                 if (res !== null) {
                   this.setState({ error: false, users: res });
                 }
               })
               .catch(err => {
                 this.setState({error: true});
                 console.log(err);
               });         
    }
  }

  // close the dialog w/o any changes
  handleClose = () => { this.props.onClose(null); };
  // close the dialog with a selection
  handleListItemClick = value => { this.props.onClose(value); };

  render() {
    const { showAll, classes, onClose, selectedValue, ...other } = this.props;
    this.componentDidMount();
    return (
      <Dialog onClose={this.handleClose} aria-labelledby="pickuser-dialog-title" {...other}>
        <DialogTitle id="pickuser-dialog-title">{this.state.title}</DialogTitle>
        <div>
          <List>
            {showAll &&
              <ListItem button onClick={() => this.handleListItemClick({})} key={''}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={"All Users"} />
              </ListItem>
            }

            {this.state.users.map(user => (
              <ListItem button onClick={() => this.handleListItemClick(user)} key={user.id}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={user.name} />
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
    );
  }
}

PickUserDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};


export default withStyles(styles)(PickUserDialog);