import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Sort from '../components/Sort';

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#f0f0f0',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const ProjectTableRowHeader = ({ sort, direction, onSortChange }) => {
  return (
    <TableRow>
      <CustomTableCell>
        <Sort
          label="Project Name"
          field="name"
          sort={sort}
          direction={direction}
          onSortChange={onSortChange}
        />
      </CustomTableCell>
      <CustomTableCell>Assigned To</CustomTableCell>
      <CustomTableCell>
        <Sort
          label="Last Updated"
          field="updated_at"
          sort={sort}
          direction={direction}
          onSortChange={onSortChange}
        />
      </CustomTableCell>
      <CustomTableCell align="center">#Files</CustomTableCell>
      <CustomTableCell align="center">#Products</CustomTableCell>
      <CustomTableCell align="center">Status</CustomTableCell>
      <CustomTableCell align="center">Actions</CustomTableCell>
    </TableRow>
  );
};

ProjectTableRowHeader.defaultProps = {
  sort: '',
  direction: 'DESC',
  onSortChange: () => {},
};

ProjectTableRowHeader.propTypes = {
  sort: PropTypes.string,
  direction: PropTypes.string,
  onSortChange: PropTypes.func,
};

export default ProjectTableRowHeader;
