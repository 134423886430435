/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchableSelect } from '@dccs/react-searchable-select-mui';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    justifyContent: 'start',
    boxShadow: '0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)',
    padding: '10px 15px',
    borderRadius: 10,
  },
  filtersLabel: {
    color: '#B4B4B4',
    textTransform: 'uppercase',
    alignSelf: 'center',
    marginRight: 20,
  },
  item: {
    display: 'flex',
  },
  itemLabel: {
    alignSelf: 'center',
    marginRight: 5,
  },
  input: {
    alignSelf: 'center',
    maxHeight: 32,
    marginRight: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const HomeFilters = ({
  accountId,
  accounts,
  onSearchChange,
  onAccountChange,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && search.length) {
      onSearchChange && onSearchChange(search);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div className={classes.filters}>
        <div className={classes.filtersLabel}>Filters</div>
        <div className={classes.item}>
          <span className={classes.itemLabel}>Search: </span>
          <TextField
            className={classes.input}
            type="text"
            margin="normal"
            value={search}
            onKeyDown={handleKeyDown}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  {!!search.length && (
                    <InputAdornment
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSearch('');
                        onSearchChange && onSearchChange('');
                      }}
                    >
                      <CloseIcon />
                    </InputAdornment>
                  )}
                  <InputAdornment
                    style={{ cursor: 'pointer' }}
                    onClick={() => onSearchChange && onSearchChange(search)}
                  >
                    <SearchIcon />
                  </InputAdornment>
                </>
              ),
            }}
          />
          <span className={classes.itemLabel}>User:</span>
          <SearchableSelect
            showAll
            value={accountId || 'all'}
            options={[
              { key: 'all', value: 'All' },
              ...accounts.map((a) => ({
                key: a.id,
                value: a.name,
              })),
            ]}
            onChange={(event) =>
              onAccountChange && onAccountChange(event.target?.value || '')
            }
            style={{ width: 120 }}
          />
        </div>
      </div>
    </div>
  );
};

HomeFilters.defaultProps = {
  accounts: [],
};

HomeFilters.propTypes = {
  accountId: PropTypes.string.isRequired,
  accounts: PropTypes.array.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onAccountChange: PropTypes.func.isRequired,
};

export default HomeFilters;
