import React from 'react';

const SiteLinkComponent = (props, context) => {
  const { url } = props;
  return (
    <a style={{ color: '#333' }} target="_extsite" href={`${url}`}>{url}</a>
  );
};

export default SiteLinkComponent;
