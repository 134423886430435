import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { Checkbox } from '@material-ui/core';
import { TagLabel } from './TagLabel';

const options = [
  { value: 'auto', label: 'Automatic Zoom' },
  { value: 'page-actual', label: 'Actual Size' },
  { value: 'page-fit', label: 'Page Fit' },
  { value: 'page-width', label: 'Page Width' },
  { value: '0.5', label: '50%' },
  { value: '0.75', label: '75%' },
  { value: '1', label: '100%' },
  { value: '1.25', label: '125%' },
  { value: '1.5', label: '150%' },
  { value: '2', label: '200%' },
  { value: '3', label: '300%' },
  { value: '4', label: '400%' },
];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1 * 0.25),
    fontSize: '12px',
    padding: theme.spacing(1),
    minWidth: theme.spacing(5),
  },
}));

export default function PDFSizeControls(props) {
  const classes = useStyles();
  const [currentSize, setCurrentSize] = React.useState(options[0]);
  const { onResize, onForceTextRender, onToggleTags } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showTags, setShowTags] = React.useState({
    bad: false,
    good: false,
    best: false,
    scores: false,
  });

  function handleForceTextRender(e) {
    onForceTextRender(e.target.checked);
  }

  function handleToggleTags(opts) {
    setShowTags(opts);
    onToggleTags(opts);
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  async function handleMenuItemClick(sizeValue) {
    const ff = options.find((o) => o.value === sizeValue);
    await setCurrentSize(ff);
    setAnchorEl(null);
    onResize('scale', sizeValue);
  }

  return (
    <>
      <Container className={classes.container}>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={() => {
            onResize('zoomout');
          }}
        >
          <ZoomOutIcon />
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={() => {
            onResize('zoomin');
          }}
        >
          <ZoomInIcon />
        </Button>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={handleClick}
          className={classes.button}
        >
          {currentSize.label}
          <ArrowDropDownIcon />
        </Button>
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          value={currentSize}
          onClose={() => setAnchorEl(null)}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => handleMenuItemClick(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </Container>
      <Container>
        Force Text Render
        <Checkbox
          color="secondary"
          size="small"
          onClick={handleForceTextRender}
        />
        <p style={{ margin: 0 }}>
          Synonyms ( Hide scores
          <Checkbox
            style={{ padding: 5 }}
            color="secondary"
            size="small"
            checked={!showTags.scores}
            onClick={(e) =>
              handleToggleTags({ ...showTags, scores: !e.target.checked })
            }
          />
          )
        </p>
        <Checkbox
          style={{ padding: 5 }}
          color="primary"
          size="small"
          checked={showTags.bad}
          onClick={(e) =>
            handleToggleTags({ ...showTags, bad: e.target.checked })
          }
        />
        <TagLabel label="Bad" color="#e57373" />
        <Checkbox
          style={{ padding: 5 }}
          color="primary"
          size="small"
          checked={showTags.good}
          onClick={(e) =>
            handleToggleTags({ ...showTags, good: e.target.checked })
          }
        />
        <TagLabel label="Good" color="#ffb74d" />
        <Checkbox
          style={{ padding: 5 }}
          color="primary"
          size="small"
          checked={showTags.best}
          onClick={(e) =>
            handleToggleTags({ ...showTags, best: e.target.checked })
          }
        />
        <TagLabel label="Best" color="#81c784" />
      </Container>
    </>
  );
}

PDFSizeControls.defaultProps = {
  onForceTextRender: () => {},
  onToggleTags: () => {},
};

PDFSizeControls.propTypes = {
  onResize: PropTypes.func.isRequired,
  onForceTextRender: PropTypes.func,
  onToggleTags: PropTypes.func,
};
