import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Header from '../components/Header';
import apit from '../lib/api';
import history from '../lib/history';
import AttributeComponent from '../components/AttributeComponent';
import SynonymList from '../components/SynonymList';


const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    color: theme.palette.common.black,
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },

  queryable: {
    width: '100%',
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
});


class Product extends React.Component {
  state = {
    id: '',
    product: {
      name: '',
      id: '',
    },
    brand: {
      name: '',
      id: '',
    },
    attributes: [],
    synonyms: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.productid,
      product: {
        name: '',
      },
      attributes: [],
      synonyms: [],
      brand: {
        id: '',
        name: '',
      }
    }
  }

  componentDidMount() {
    this.loadDataset();
  }

  callApi = async () => {
    const params = {}
    const response = await apit.get(`/v2/product/${this.state.id}`);
    console.log('got our response')
    const body = await response.data;
    console.log('set our response')
    if (response.status !== 200) {
      console.log('Throwing an error due to !200')
      throw Error(body.message);
    }

    console.log('sending data')
    return body;
  }
  
  loadDataset = async() => {
    try {
      const data = await this.callApi();
      console.log('and our data');
      await this.setState({ product: data.product, synonyms: data.synonyms, attributes: data.attributes, brand: data.brand })
    } catch (err) {
      console.log(err)
    }
  }

  handleIgnoreProduct = (event) => {
    event.preventDefault();
    const { product } = this.state;
    product.ignored = event.target.checked;
    console.log('product ignore is now', product.ignored);
    this.setState({product});
    this.updateRecord(product);
  }
 updateRecord = async (record) => {
   console.log('Updating record')
    const response = await apit.put(`/v2/product/${record.id}`, record);
    const body = await response.data;
    if (body.status) {
      console.log('product ignore status updated');
    } else {
      alert('Failed to update product record');
    }
  }

  handleBrandClick = async (e, id) => {
    e.preventDefault();
    history.push(`/brand/${id}`);
  }

  render() {
    const { classes } = this.props;
    const { redirectTo, product, attributes, synonyms, brand, id } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    let ignoredStatus = '';
    if (product.ignored) {
      ignoredStatus = '! This product is ignored !'
    }
    return (
      <div>
        <Header title={'Product'}  />
        <Paper className={classes.root}>
          <h3>{product.name}</h3>
          <FormControlLabel
            control={
              <Switch
                checked={(!!product.ignored)}
                onChange={this.handleIgnoreProduct}
                value="checked"
                color="primary"
              />
            }
            label="Ignore Status"
          />
          <h4>{ignoredStatus}</h4>
          <h5>
            <a style={{color:'#333'}} href='#' onClick={event => this.handleBrandClick(event, brand.id)}>{brand.name}</a>
          </h5>
          <div className={classes.attrbutes}>
            Attributes:  {attributes.length}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell>Name</CustomTableCell>
                  <CustomTableCell>Value</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.attributes.map((row) => {
                  return (<AttributeComponent key={row.attributeTypeId} classes={classes} row={row} dpType='products'></AttributeComponent>);
                })}
              </TableBody>
            </Table>
          </div>
          <div className={classes.synonyms}>
            <SynonymList objectId={id} objectType="product" label="Product Synonyms" />
          </div>
        </Paper>
      </div>
    );
  }
}

Product.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Product);
