import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import apit from '../lib/api';

export default class BrandDialog extends React.Component {
  state = {
    open: false,
    brand_id: 0,
    brand: {
      name: '',
      dp_id: 0,
    },
    copytxt: '',
  };

  constructor(props) {
    super(props);

    this.state.open = props.open;
    this.state.onClose = props.onClose;
    this.state.onRemove = props.onRemove;
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.state.onClose();
  };

  handleRemoveTag = () => {
    this.state.onRemove();
    this.handleClose();
  }

  componentDidUpdate = (prevProps) => {
    if (!this.props.open) { return; }
    console.log(`brand: cdu:  ${prevProps.brand.inspexId}   ${this.props.brand.inspexId}`)

    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open,
      });
    }

    if (prevProps.brand.inspexId !== this.props.brand.inspexId) {
      this.setState({
        brand_id: this.props.brand.inspexId 
      });
      
      this.props.handleTagClick({type: 'b', id: this.props.brand.id }).then((r) => { this.setState({ copytxt: r })});
      apit.getjson(`/v2/brand/${this.props.brand.inspexId}`)
      .then((res) => {
        console.log('got res');
        if (!res.brand) {
          res.brand = { name: 'UNKNOWN - BUG - NOT A VALID BRAND', dp_id: 0 };
        }
        this.setState({ brand: res.brand });
      }).catch(err => {
        console.log('BRAND::errorr');
        console.log(err);
      })
    }
  };

  render() {

    const { brand, copytxt } = this.state;
    if (!this.state.open) {
      return (<div/>);
    }
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{brand.name}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This brand is a brand.  This info is not nec. accurate yet.
            </DialogContentText>
            <p style={{color:'#333'}}>
              DesignerPages Brand:
              <a style={{color:'#333'}} target='_dpwindow' href={`https://www.designerpages.com/brands/${brand.dp_id}`}>{brand.dp_id}</a>
            </p>
            <p style={{color:'#333'}}>
              Inspex Brand Info:
              <a style={{color:'#333'}} target='_dpwindow' href={`/brand/${brand.id}`}>{brand.id}</a>
            </p>
            <p style={{color:'#333'}}>
            Copyable Data:<br />
            BrandName, BrandId<br />
            {copytxt}
              {/* Show:
              <br/>
              The Highlighted Text.
              <br />
              The DP BRAND title + ID + Link
              <br />
              Ability to remove tag
              <br />
              Ability to close dialog
              <br/>
              Ability to map to inspex brand (synonym)
              <br/>
              Ability to 'fix' highlight... e.g. off by 1 char, how to fix easily? */}
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleRemoveTag} color="primary">
              Remove Tag
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}