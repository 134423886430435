/* global localStorage */
import React from 'react';
import { Router, Route, Link, Redirect, Switch } from 'react-router-dom';
import ModalProvider from 'mui-modal-provider';
import jwtDecode from 'jwt-decode';
import Home from './Project/Home';
import { ArchiveList } from './Project/Archive';
import Explorer from './Explorer/Explorer';
import Project from './Project/Project';
import Materials from './Project/Materials';
import Login from './Login/Login';
import Logout from './Login/Logout';
import Jobs from './Job/Jobs';
import Job from './Job/Job';
import Accounts from './Account/Account';
import Create from './Project/Create';
import history from './lib/history';
import Brands from './Brand/Brands';
import Searches from './Searches/Searches';
import SearchMatches from './Searches/SearchMatches';
import Brand from './Brand/Brand';
import Products from './Product/Products';
import Product from './Product/Product';
import { ProjectHistoryList } from './Project/ProjectHistory';
import { Dictionary } from './Dictionary/Dictionary';

const publicRoutes = [
  {
    path: '/login',
    exact: true,
    main: Login,
    public: true,
  },
  {
    path: '/logout',
    exact: true,
    main: Logout,
    public: true,
  },
];

const routes = [
  { path: '/', exact: true, main: Home },
  { path: '/explorer/projects/:project/:file', exact: true, main: Explorer },
  { path: '/explorer/projects/:project/:file/:page/:top', main: Explorer },
  { path: '/project/:project/materials', main: Materials },
  { path: '/project/:project', main: Project },
  { path: '/jobs/', main: Jobs },
  { path: '/job/:jobid', main: Job },
  { path: '/searches/', main: Searches },
  { path: '/search/:searchid', main: SearchMatches },
  { path: '/brands/', main: Brands },
  { path: '/brand/:brandid', main: Brand },
  { path: '/products/', main: Products },
  { path: '/product/:productid', main: Product },
  { path: '/usermgmt/', main: Accounts },
  { path: '/create/', main: Create },
  { path: '/archived', main: ArchiveList },
  { path: '/projecthistory', main: ProjectHistoryList },
  { path: '/dictionary', main: Dictionary },
];

function getUser() {
  const jwt = localStorage.getItem('jwtToken');
  try {
    const user = jwtDecode(jwt);
    return user;
  } catch (Error) {
    return null;
  }
}

function isAuthenticated() {
  const user = getUser();
  if (
    user &&
    user.account &&
    user.account.permissions > 0 &&
    user.account.is_active
  ) {
    return true;
  }
  return false;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} user={getUser()} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No page match for
      <code>{location.pathname}</code>
    </h3>
    <Link to="/">Go Home</Link>
  </div>
);

const Application = () => (
  <Router history={history}>
    <div>
      <ModalProvider>
        <Switch>
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.main}
            />
          ))}
          {routes.map((route, index) => (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.main}
            />
          ))}
          <Route component={NoMatch} />
        </Switch>
      </ModalProvider>
    </div>
  </Router>
);

export default Application;
