/* eslint-disable react/destructuring-assignment,no-plusplus */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useModal } from 'mui-modal-provider';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from '../components/Header';
import MaterialService from '../services/materials';
import ProjectService from '../services/projects';
import NicaUserService from '../services/nicaUsers';
import { SynonymSyncModal } from '../components/SynonymSyncModal';
import { ProductSearchModal } from '../components/ProductSearchModal';
import Auth from '../lib/Auth';
import { MaterialEditModal } from '../components/MaterialEditModal';
import { MaterialRow } from '../components/MaterialRow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  head: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
  },
  division: {
    backgroundColor: '#eee',
    color: '#333',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  material: {
    backgroundColor: '#aaa',
    color: '#333',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fontSmall: {
    fontSize: 12,
  },
  footer: {
    display: 'flex',
    padding: '30px 20px 10px 20px',
    justifyContent: 'space-between',
  },
}));
/*
  show list of all defined materials
  some ability to export to xls file
*/
export default function Materials(props) {
  const classes = useStyles();
  const { showModal } = useModal();
  const { project: projectId } = props.match.params;
  const { enqueueSnackbar } = useSnackbar();

  const [project, setProject] = useState(null);
  const [allNicaUsers, setAllNicaUsers] = useState([]);
  const [nicaUser, setNicaUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingNicaUsers, setLoadingNicaUsers] = useState(false);
  const [saving, setSaving] = useState(false);
  const [divisions, setDivisions] = useState({});
  const [mappings, setMappings] = useState({});

  const fetchProject = async () => {
    const response = await ProjectService.get(projectId);
    setProject(response || null);
  };

  const fetchNicaUsers = async () => {
    setLoadingNicaUsers(true);
    const response1 = await NicaUserService.getAll();
    setAllNicaUsers(response1 || null);
    const response2 = await ProjectService.getNicaUser(projectId);
    setNicaUser(response2 || null);
    setLoadingNicaUsers(false);
  };

  const saveNicaUser = async (nicaUserId) => {
    setSaving(true);
    try {
      await ProjectService.saveNicaUser(projectId, nicaUserId);
      enqueueSnackbar('NICA User saved.', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('An error occurred, please try again.', {
        variant: 'error',
      });
      throw e;
    }
    setSaving(false);
  };

  const fetchMaterials = async () => {
    setLoading(true);
    const response = await MaterialService.getAll(projectId);
    const rawDivisions = response.data;
    setDivisions(rawDivisions);

    const newMaterialsMap = {};
    Object.keys(rawDivisions).forEach((key) => {
      rawDivisions[key].forEach((division) => {
        division.items.forEach((material) => {
          newMaterialsMap[material.id] = material.mappings;
        });
      });
    });
    setMappings(newMaterialsMap);
    setLoading(false);
  };

  const updateDivisionsItems = (item) => {
    setDivisions((prev) => {
      const newDivisions = _.cloneDeep(prev);
      let found = false;
      Object.keys(prev).forEach((key) => {
        prev[key].forEach((cat, catIndex) => {
          cat.items.forEach((i, itemIndex) => {
            if (i.id === item.id) {
              found = true;
              newDivisions[key][catIndex].items[itemIndex] = {
                ...newDivisions[key][catIndex].items[itemIndex],
                ...item,
              };
            }
          });
        });
      });

      if (found) {
        return newDivisions;
      }
      return prev;
    });
  };

  const saveMappings = async (equivId, mapping) => {
    try {
      const success = await MaterialService.updateMappings(equivId, mapping);
      if (!success) {
        enqueueSnackbar('An error occurred, please try again.', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Saved succesfully.', { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const downloadXLS = async (e) => {
    const fileName = (project?.name || '')
      .replace(/[^a-zA-Z 0-9]/g, '')
      .replaceAll(' ', '_');
    await MaterialService.download(projectId, fileName);
  };

  const handleProductChange = async (materialId, mapping) => {
    await saveMappings(materialId, mapping);
    setMappings({ ...mappings, [materialId]: mapping });
  };

  const handleNicaChange = async (newNicaUser) => {
    try {
      await saveNicaUser(newNicaUser?.id || null);
      setNicaUser(newNicaUser);
    } catch (e) {}
  };

  const handleOpenSynonymsModal = () => {
    const synonyms = [];
    Object.keys(divisions).forEach((key) => {
      const division = divisions[key];
      division.forEach((category) => {
        category.items.forEach((material) => {
          if (mappings[material.id]?.product?.id) {
            synonyms.push({
              equivId: material.id,
              productId: mappings[material.id]?.product?.id,
              productName: mappings[material.id]?.product?.name,
              categoryIds: mappings[material.id]?.product?.dp_category_ids,
              categoryNames: mappings[material.id]?.product?.dp_category_names,
              manufacturerId: mappings[material.id]?.product?.manufacturer_id,
              manufacturerName:
                mappings[material.id]?.product?.manufacturer_name,
              synonyms: {
                manufacturerName: material.manufacturer,
                categoryName: material.dpCategoryName,
                productName: material.product,
              },
            });
          }
        });
      });
    });

    const modal = showModal(
      SynonymSyncModal,
      {
        projectId,
        synonyms,
        onSave: () => {
          modal.hide();
        },
        onCancel: () => {
          modal.hide();
        },
      },
      {
        destroyOnClose: true,
      }
    );
  };

  const handleOpenSearchModal = (material, product, asset) => {
    const modal = showModal(
      ProductSearchModal,
      {
        currentMaterial: material,
        currentProduct: product,
        currentAsset: asset,
        defaultSearch: `${material.product} ${material.manufacturer}`,
        onSave: (s) => {
          handleProductChange(material.id, s);
          modal.hide();
        },
        onCancel: () => {
          modal.hide();
        },
      },
      {
        destroyOnClose: true,
      }
    );
  };

  const handleOpenMaterialEditModal = (materialEquiv) => {
    const modal = showModal(
      MaterialEditModal,
      {
        materialEquiv,
        onSave: (item) => {
          modal.hide();
          updateDivisionsItems(item);
        },
        onCancel: () => {
          modal.hide();
        },
      },
      {
        destroyOnClose: true,
      }
    );
  };

  const hasDivisions = () => {
    return Object.keys(divisions).length > 0;
  };

  useEffect(() => {
    fetchMaterials();
    fetchProject();
    fetchNicaUsers();
  }, []);

  const processItem = (item) => {
    return (
      <MaterialRow
        key={`prod-${item.id}-${Math.random()}`}
        projectId={projectId}
        item={item}
        mappings={mappings}
        onEdit={(i) => handleOpenMaterialEditModal(i)}
        onProductSearch={(i, p, a) => handleOpenSearchModal(i, p, a)}
        onProductChange={(i, mapping) => handleProductChange(i.id, mapping)}
        onBestGuessChange={(i) => updateDivisionsItems(i)}
      />
    );
  };

  const processMaterial = (material) => {
    return (
      <React.Fragment key={`mat-${material.materialName}`}>
        <TableRow>
          <TableCell colSpan={2} className={classes.material}>
            {material.materialName}
          </TableCell>
          <TableCell className={classes.material}>
            {material.dpCategoryName}
          </TableCell>
          <TableCell className={classes.material}>
            {material.dpCategory}
          </TableCell>
          <TableCell className={classes.material}>{material.csiCode}</TableCell>
          <TableCell className={classes.material} colSpan={8} />
        </TableRow>
        {material.items.map((itm) => processItem(itm))}
      </React.Fragment>
    );
  };

  const processDivision = (divId, data) => {
    return (
      <React.Fragment key={`div-${divId}`}>
        <TableRow>
          <TableCell
            className={classes.division}
            colSpan={13}
          >{`DIVISION ${divId}`}</TableCell>
        </TableRow>
        {data.map((mat) => processMaterial(mat))}
      </React.Fragment>
    );
  };

  return (
    <div>
      <Header title="Material List" />
      <Paper className={classes.root}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Project Name:</strong> {project?.name || 'Loading...'}
              </TableCell>
              <TableCell>
                <strong>Assigned To:</strong>{' '}
                {project?.assigned || 'Loading...'}
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', gap: 8 }}>
                  <strong style={{ paddingTop: 5 }}>NICA User:&nbsp;</strong>
                  {/* <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={nicaUser?.id || 0}
                    onChange={(e) => handleNicaChange(e.target.value)}
                  >
                    <MenuItem value={0}>Select NICA User</MenuItem>
                    {allNicaUsers.map((nica) => (
                      <MenuItem value={nica.id}>
                        {nica.firstName} ({nica.userId})
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Autocomplete
                    disableClearable
                    options={allNicaUsers}
                    value={nicaUser}
                    getOptionSelected={(option) => option.id === nicaUser?.id}
                    getOptionLabel={(option) =>
                      `${option.firstName} (${option.userId})`
                    }
                    style={{ width: 250 }}
                    onChange={(e, n) => handleNicaChange(n)}
                    disabled={loadingNicaUsers || saving}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select NICA User"
                        variant="standard"
                      />
                    )}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.head} colSpan={2} />
              <TableCell className={classes.head}>Manufacturer</TableCell>
              <TableCell className={classes.head}>Product</TableCell>
              <TableCell className={classes.head}>Best Guess</TableCell>
              <TableCell className={classes.head}>Notes</TableCell>
              <TableCell className={classes.head}>Specified</TableCell>
              <TableCell className={classes.head}>Nested</TableCell>
              <TableCell className={classes.head}>Code</TableCell>
              <TableCell className={classes.head}>Category</TableCell>
              <TableCell className={classes.head}>DP Product</TableCell>
              <TableCell className={classes.head}>DP Image</TableCell>
              <TableCell className={classes.head}>Anchors</TableCell>
              {/* <TableCell className={classes.head}>DP Link</TableCell>
              <TableCell className={classes.head}>Web Link</TableCell>
              <TableCell className={classes.head}>Web Image</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={20} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {!loading && !hasDivisions() && (
              <TableRow>
                <TableCell colSpan={20} align="center">
                  The are no products yet
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              Object.keys(divisions).map((div) =>
                processDivision(div, divisions[div])
              )}
          </TableBody>
        </Table>
        {!loading && (
          <div className={classes.footer}>
            {Auth.hasManager() && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={!hasDivisions()}
                startIcon={<MenuBookIcon />}
                onClick={handleOpenSynonymsModal}
              >
                Import To Synonym Dictionary
              </Button>
            )}
            {!Auth.hasManager() && <div>&nbsp;</div>}
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={!hasDivisions() || saving}
              startIcon={<DownloadIcon />}
              onClick={(event) => {
                downloadXLS(event);
              }}
            >
              Download XLS
            </Button>
          </div>
        )}
      </Paper>
    </div>
  );
}
