import styled from 'styled-components';
import MuiTableCell from '@material-ui/core/TableCell';

export const Wrapper = styled.div`
  display: flex;
`;

export const SearchContainer = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  max-width: 60%;
  flex-grow: 1;
`;

export const PreviewContainer = styled.div`
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  flex-basis: 1;
  max-width: 40%;
  flex-grow: 1;
`;

export const TableContainer = styled.div`
  margin-top: 10px;
  max-height: 400px;
  overflow: auto;
`;

export const TableCell = styled(MuiTableCell)`
  font-size: 12px !important;

  a {
    font-size: 12px !important;
    padding: 0;
    color: blue;
  }
`;

export const PreviewHeader = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 8px;
  }

  & > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const ThumbnailsContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
  min-height: 300px;
  max-height: 300px;
  border: 1px solid #ccc;
  padding: 10px;
  overflow: hidden;
  overflow-y: auto;
`;

export const SmallText = styled.span`
  display: inline-block;
  font-size: 12px;
  margin-bottom: 5px;
`;

export const Centered = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const InlineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProjectCount = styled.span`
  display: inline-block;
  background-color: #252525;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  font-size: 12px;
  margin-top: 15px;
`;
