import React from 'react';
import { Link } from 'react-router-dom';
import apit from '../lib/api';
import Header from '../components/Header';

class Job extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobid: '',
      job: {
        jobData: { type: 'none' },
        resultData: {},
      },
    };

    this.state.jobid = this.props.match.params.jobid;
  }

  componentDidMount() {
    this.queryJobs();
  }

  queryJobs() {
    apit.getjson(`/v2/job/${this.state.jobid}`)
      .then((res) => {
        this.setState({ job: res.job });
        setInterval(this.checkStatus, 10000);
      })
      .catch(err => console.log(err));
  }

  render() {
    const { job } = this.state;

    let jobDataView = '';
    if (job.jobData.data) {
      jobDataView = (
        <div>
          <Link to={`/project/${job.jobData.data.projectId}/`}>
            Project
          </Link> {job.jobData.data.projectId} 
          <br />
          <Link to={`/explorer/projects/${job.jobData.data.projectId}/${job.jobData.data.resourceId}/`}>
            Resource
          </Link> {job.jobData.data.resourceId}
        </div>
      );
    }

    return (
      <div>
        <Header title="Accounts" />
        <div className="accounts">
          <h2>
            {'Job'}
          </h2>
          <table style={{ width: '80%'}}>
            <thead>
              <tr>
                <th>Job ID</th>
                <th>Status</th>
                <th>Queue</th>
                <th>Queued Date</th>
                <th>Assigned Date</th>
                <th>Processed Date</th>
                <th>Heartbeat Date</th>
              </tr>
            </thead>
            <tbody>
              <tr className="job">
                <td>{job.id}</td>
                <td>{job.status}</td>
                <td>{job.queueName}</td>
                <td>{job.queuedDT}</td>
                <td>{job.assignedDT}</td>
                <td>{job.processedDT}</td>
                <td>{job.heartbeatDT}</td>
              </tr>
            </tbody>
            </table>
            <h1>Job Type: {(job.jobData.type === 2) ? 'Process' : 'Download'}</h1>
        <div>
          {jobDataView}
          <br />
          <div>{JSON.stringify(job.jobData.data)}</div>
          <br />
          <h3>Result Data</h3>
          {JSON.stringify(job.resultData)}
        </div>

        </div>
      </div>
    );
  }
}

export default Job;
