import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import format from 'date-fns/format';
import Pagination from '@material-ui/lab/Pagination';
import Header from '../components/Header';
import searchesService from '../services/searches';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '50px auto',
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    margin: '20px auto',
    width: '95%',
  },
  tableHead: {
    backgroundColor: '#f0f0f0',
  },
  checkbox: {
    maxWidth: 25,
  },
  pagination: {
    margin: '30px auto',
    display: 'flex',
    justifyContent: 'center',
  },
  textInfo: {
    color: theme.palette.info.dark,
  },
  textSuccess: {
    color: theme.palette.success.dark,
  },
}));

export default function Searches() {
  const classes = useStyles();

  const [searches, setSearches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [onPage, setOnPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const fetchData = async () => {
    const response = await searchesService.getAll({
      page: onPage,
      perPage,
    });

    setSearches(response.data || []);
    setPagination(response.pagination || {});
    return response;
  };

  const onPageChange = (event, page) => {
    setOnPage(page);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, [onPage]);

  return (
    <>
      <Header title="Projects Audit" />

      <TableContainer component={Paper}>
        {loading && (
          <Box className={classes.box}>
            <CircularProgress disableShrink />
          </Box>
        )}
        {!loading && (
          <>
            <Table aria-label="simple table" className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell width="50%">Search Name</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="right">Last Run</TableCell>
                  <TableCell align="right">1 Day</TableCell>
                  <TableCell align="right">1 Week</TableCell>
                  <TableCell align="right">1 Month</TableCell>
                  <TableCell align="right">YTD</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* <TableRow style={{ backgroundColor: '#eee' }}>
                <TableCell colSpan={3}>
                  <Button color="primary" to={`/search/all`} component={Link}>
                    All Searches
                  </Button>
                </TableCell>
              </TableRow> */}
                {(searches || []).map((row, index) => (
                  <TableRow
                    key={`${row.name}-${index}`}
                    style={{
                      backgroundColor: index % 2 ? '#f5f5f5' : '',
                    }}
                  >
                    <TableCell component="th" scope="row" width="50%">
                      <Button
                        color="primary"
                        to={`/search/${row.id}`}
                        component={Link}
                      >
                        {row.name}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      {row.importing_total > 0 && (
                        <span className={classes.textInfo}>
                          {row.importing_total} Importing
                        </span>
                      )}
                      {row.importing_total === 0 && (
                        <span className={classes.textSuccess}>OK</span>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {row.last_run_date
                        ? format(new Date(row.last_run_date), 'MM/dd/yyyy')
                        : 'N/A'}
                    </TableCell>
                    <TableCell align="right">
                      {row.day_matches || '0'}
                    </TableCell>
                    <TableCell align="right">
                      {row.week_matches || '0'}
                    </TableCell>
                    <TableCell align="right">
                      {row.month_matches || '0'}
                    </TableCell>
                    <TableCell align="right">
                      {row.year_matches || '0'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              color="primary"
              className={classes.pagination}
              count={pagination.pages || 1}
              page={pagination.onPage || 1}
              onChange={onPageChange}
            />
          </>
        )}
      </TableContainer>
    </>
  );
}
