/**
  A module that defines a single `Equivilent` portion of a material

  @module types/equivilent
*/
import uuidv4 from 'uuid/v4';

import * as Logger from '../../lib/logger';
import * as EquivType from './equivType';

const materialEquivState = {
  id: '', // uuid
  product: null, // equivilentType
  brand: null, // equivilentType
  note: '', // string
  isBasis: false, // bool
  isNested: false, // bool
  code: '', // string
  categoryId: null, // number
};

/**
  Initialize a new equivilent

  @return {types/equivilent} The initialized equivilent
*/
export function initialize() {
  return {
    ...materialEquivState,
    id: uuidv4(),
  };
}

export function duplicateEquivilent(inEqv) {
  return {
    ...inEqv,
    id: uuidv4(),
    product: EquivType.duplicateEquivilentType(inEqv.product),
    brand: EquivType.duplicateEquivilentType(inEqv.brand),
  };
}

export function duplicateEquivilents(inEquivs) {
  return inEquivs.map(eqv => duplicateEquivilent(eqv));
}
/**
  Convert an Equivilent to an API payload

  @param {types/equivilent} state - The equivilent to convert to api format
  @return {object} The equivilent formatted for API consumption
*/
export function toAPI(state = materialEquivState) {
  Logger.debug('materialETOJSON', state);
  const x = {
    id: state.id,
    product: (state.product) ? EquivType.toAPI(state.product) : null,
    brand: (state.brand) ? EquivType.toAPI(state.brand) : null,
    note: state.note,
    isBasis: (state.isBasis),
    isNested: (state.isNested),
    code: state.code,
    categoryId: state.categoryId,
  };
  Logger.debug('materialETOJSON POST', x);
  return x;
}

/**
  Convert an API payload to an equivilent

  @param {object} payload - An equivilent object received from the API
  @returns {types/equivilent} The equivilent state object
 */
export function fromAPI(payload) {
  // Logger.debug('MATERIALEQUIV FROM API', payload);
  // we expect productText, brandText, productDpId, brandDpId as well
  // which should be part of the product/brand equivtype
  return {
    ...materialEquivState,
    id: payload.id,
    product: EquivType.fromAPI(payload.product),
    brand: EquivType.fromAPI(payload.brand),
    note: payload.note,
    isBasis: payload.isBasis,
    isNested: payload.isNested,
    code: payload.code,
    categoryId: payload.categoryId,
  };
}


/**
  Set the IsBasis value for the given Equivilent

  @param {types/equivilent} state - The equivilent to operate on
  @param {boolean} bl - The isBasis value
  @return {types/equivilent} The equivilent object after modification
*/
export function setIsBasis(state = materialEquivState, bl) {
  // Logger.debug('setIsBasis', { state, bl });
  return {
    ...state,
    isBasis: bl,
  };
}

/**
  Set the code value for the given Equivilent

  @param {types/equivilent} state - The equivilent to operate on
  @param {string} code - The code value
  @return {types/equivilent} The equivilent object after modification
*/
export function setCode(state = materialEquivState, code) {
  Logger.debug('setCode', { state,code });
  return {
    ...state,
    code,
  };
}

/**
  Set the categoryId value for the given Equivilent

  @param {types/equivilent} state - The equivilent to operate on
  @param {number} categoryId - The categoryId value
  @return {types/equivilent} The equivilent object after modification
*/
export function setCategoryId(state = materialEquivState, categoryId) {
  return {
    ...state,
    categoryId,
  };
}

/**


/**
  Set the IsNested value for the given Equivilent

  @param {types/equivilent} state - The equivilent to operate on
  @param {boolean} bl - The isNested value
  @return {types/equivilent} The equivilent object after modification
*/
export function setIsNested(state = materialEquivState, bl) {
  return {
    ...state,
    isNested: bl,
  };
}

/**
  Set the Product Text value for the given Equivilent

  @param {types/equivilent} state - The equivilent to operate on
  @param {string} txt - The Project Text value
  @return {types/equivilent} The equivilent object after modification
*/
export function updateProductText(state = materialEquivState, txt) {
  return {
    ...state,
    product: EquivType.equivTypeUpdate(state.product, { text: txt }),
  };
}

/**
  Set the Brand Text value for the given Equivilent

  @param {types/equivilent} state - The equivilent to operate on
  @param {string} txt - The Brand Text value
  @return {types/equivilent} The equivilent object after modification
*/
export function updateBrandText(state = materialEquivState, txt) {
  return {
    ...state,
    brand: EquivType.equivTypeUpdate(state.brand, { text: txt }),
  };
}

/**
  Set the Product Tag value for the given Equivilent

  @param {types/equivilent} state - The equivilent to operate on
  @param {types/materialTag} inTag - The Project Tag value
  @return {types/equivilent} The equivilent object after modification
*/
export function updateProductChanges(state = materialEquivState, inTag) {
  // Logger.debug('UPDATE PROJECT CHANGES IN:', { state, inTag });
  return {
    ...state,
    product: EquivType.equivTypeUpdate(state.product, inTag),
  };
}

/**
  Set the Brand Tag value for the given Equivilent

  @param {types/equivilent} state - The equivilent to operate on
  @param {types/materialTag} inTag - The Brand Tag value
  @return {types/equivilent} The equivilent object after modification
*/
export function updateBrandChanges(state = materialEquivState, inTag) {
  // Logger.debug('UPDATE BRAND CHANGES IN:', { state, inTag });
  return {
    ...state,
    brand: EquivType.equivTypeUpdate(state.brand, inTag),
  };
}

/**
  Set the Note for the given Equivilent

  @param {types/equivilent} state - The equivilent to operate on
  @param {string} note - The Note value
  @return {types/equivilent} The equivilent object after modification
*/
export function updateNote(state = materialEquivState, note) {
  // Logger.debug('UPDATE NOTE IN:', { state, note });

  return {
    ...state,
    note,
  };
}

/**
  Append a string to the Note for the given Equivilent

  @param {types/equivilent} state - The equivilent to operate on
  @param {string} note - The string to append to the note
  @return {types/equivilent} The equivilent object after modification
*/
export function appendNote(state = materialEquivState, note) {
  // Logger.debug('APPEND NOTE IN:', { state, note });
  return {
    ...state,
    note: `${state.note} ${note}`,
  };
}
