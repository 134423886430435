import apit from '../lib/api';

const getAll = async () => {
  const response = await apit.get('/v2/nica_users');
  const body = await response.data;
  if (response.status !== 200) {
    if (response.status !== 200) throw Error(body.error || body.message);
  }
  return body.nicaUsers;
};

export default {
  getAll,
};
