/**
  Module for the definiton of a Material Tag

  @module types/materialTag
*/

import uuidv4 from 'uuid/v4';
import * as Logger from '../../lib/logger';

const materialTagState = {
  id: '',
  text: '',
  resourceId: '',
  page: 0,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

/**
  Initialize a new materialTag.

  @return {types/materialTag} The initialized materialTag
*/
export function initialize() {
  return {
    ...materialTagState,
    id: uuidv4(),
  };
}

export function duplicateTag(inTag) {
  console.log('duptag', inTag);
  if (!inTag) return null;

  return {
    ...inTag,
    id: uuidv4(),
  };
}
/**
  Convert a materialTag to an API payload

  @param {types/materialTag} state - The materialTag to convert to api format
  @return {object} The materialTag formatted for API consumption
*/
export function toAPI(state = materialTagState) {
  if (!state) {
    return null;
  }

  if (state.id === '') {
    console.log('*************************** SETTING UNDEFINED!!!!!');
    state.id = undefined;
  }

  return {
    id: state.id,
    text: state.text,
    resourceId: state.resourceId,
    page: state.page,
    left: state.left,
    right: state.right,
    top: state.top,
    bottom: state.bottom,
  };
}

/**
  Convert an API payload to a materialTag

  @param {object} payload - A materialTag object received from the API
  @returns {types/materialTage} The materialTag state object
 */
export function fromAPI(payload) {
  if (!payload) { return null; }
  // Logger.debug('materialTagFromAPI', payload);

  return {
    ...materialTagState,
    id: payload.id,
    text: payload.name,
    resourceId: payload.resourceId,
    page: payload.page,
    left: payload.left,
    right: payload.right,
    top: payload.top,
    bottom: payload.bottom,
  };
}

/**
  Updates the materialTag object

  @param {types/materialTag} state - A materialTag state object
  @param {types/materialTag} payload - An materialTag object
  @returns {types/materialTag} The materialTag state object after modification
 */
export function materialTagUpdate(state = materialTagState, payload) {
  // Logger.debug('**materialTagUpdate, set tag to null if not a valid exist or payload tag', payload);
  if (!state && !payload.resourceId) {
    Logger.error('THIS SHOULD BE AN INVALID TAG, so return NULL');
    return null;
  }

  return {
    ...state,
    text: payload.text,
    resourceId: payload.resourceId,
    page: payload.page,
    left: payload.left,
    right: payload.right,
    top: payload.top,
    bottom: payload.bottom,
  };
}
