import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DragIcon from '@material-ui/icons/DragIndicator';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DuplicateIcon from '@material-ui/icons/Report';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Draggable from 'react-draggable';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

import CategoryComponent from './CategoryComponent';
import TagList from './TagList';
import MaterialList from './MaterialList';
import ProductTable from './ProductTable';
import { saveMaterial } from './types/material';
import { updateNestedReferences } from './types/projectMaterials';
import { getCategoryLabel } from './types/DivisionManager';

// const DM = new DivisionManager();

const Logger = require('../lib/logger');

function handleStop(e, f) {
  Logger.debug('EntrySelection.handleStop() called (NOOP)', { e, f });
}
function PaperComponent(props) {
  return (
    <Draggable
      cancel={'[class*="MuiDialogContent-root"]'}
      onStop={handleStop}
      handle=".dragHandle"
    >
      <Paper {...props} />
    </Draggable>
  );
}
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const useStyles = makeStyles((theme) => ({
  chip: {
    width: '100%',
  },
  container: {
    marginBottom: '10px',
  },

  textFieldMaterial: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40%',
  },
  textFieldCode: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '5%',
  },
  categorySelect: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 600,
  },
  categorySelectDIV: {
    width: '600px',
    display: 'inline-flex',
  },
  actionBtn: {
    minWidth: '32px',
  },
}));

//
//  TODO: when we pass in the material to be edited.
//      it needs to be the material object, so we can directly
//      modify it without passing back down the hierarchy.
//      the only hierarchical action should be to update the
//      array of materials we have.
//

export default function MaterialEditor(props) {
  const classes = useStyles();
  //console.log('****** MAT EDITOR PROPS', props)
  const { isOpen, content, location, handleClose, hightlightSelection } = props;
  const { updateMaterial, currentMaterial, gotoLocation } = props;

  const [theMaterial, setTheMaterial] = useState(currentMaterial);
  const [categoryName, setCategoryName] = useState('');

  const [modified, setModified] = useState(false);
  // this *only* exists to force a draw due to absymal understanding of react.
  // since state object contains class methods,
  // we can't use prevState=> ({...prevstate, ...newState})
  // just calling setState() doesn't trigger the redraw.
  // so, incomes our fake state...
  // this screams developer-implmentation-error...
  const [fixme, setFixMe] = useState(0);

  const [chipIcon, setChipIcon] = useState(<DragIcon />);
  const [chipColor, setChipColor] = useState('secondary');
  //  const [isHeader, setIsHeader] = useState(false);

  // Logger.debug('MATERIALEDITOR:: working on', currentMaterial);

  const acceptAssignment = () => {
    setChipIcon(<DoneIcon />);
    setChipColor('primary');
  };

  // const [headerContent, setHeaderContent] = useState({ title: theMaterial.title, category: theMaterial.category, categoryName: 'still have to load it' });
  // const saveHeaderChange = field => async (newValue) => {
  //   const newD = headerContent;
  //   newD[field] = newValue;
  //   setHeaderContent(newD);
  // };

  const saveNested = async (typ, items) => {
    const nested = theMaterial.nested;
    nested[typ] = items;

    // we have to update EVERY other material that may have had a reference to this material added or removed.
    props.setMaterials(updateNestedReferences(props.materials, theMaterial));

    return saveChange('nested')(nested);
  };

  const saveChange = (field) => async (newValue) => {
    // all material changes should come thru here, this will ensure the data is passed properly.
    const newState = theMaterial;
    newState[field] = newValue;

    Logger.debug('SAVING STATE', newState);
    await setTheMaterial(newState);
    await setModified(true);
    await setFixMe(Date.now);
  };

  //
  //   we should take what we've used for gotolocation()
  //
  const updateDocumentWithSelection = (theTag, type) => {
    Logger.debug('EntrySelection.updateDocumentWithSelection()', {
      theTag,
      type,
    });
    // const hlInfo = {
    //   content: theTag.text,
    //   location: theTag,
    //   type,
    // };

    hightlightSelection({ tag: theTag, type });
  };

  const dropper = (e, f) => {
    Logger.debug('EntrySelection.dropper() dropped data', {
      e,
      f,
      content,
      location,
    });
    if (f === 'material-title') {
      acceptAssignment();
      // setting both the header and the material indicates we have an issue.
      //saveHeaderChange('title')(content);
      saveChange('title')(content);
      const theTag = {
        ...location.box,
        text: content,
      };
      updateDocumentWithSelection(theTag, 'material');
    } else if (f === 'material-code') {
      console.log('DROPPING ON THE CODE', content);
      saveChange('code')(content);
    }
  };

  const closeEditor = async (e) => {
    setChipIcon(<DragIcon />);
    setChipColor('secondary');
    handleClose();
    if (modified) {
      Logger.debug(
        '*************************CLOSE EDITOR STATE IS',
        theMaterial
      );

      const newMat = await saveMaterial(theMaterial);
      Logger.debug('*** AND OUR RETURNED, UPDATED MATERIAL IS: ', newMat);

      setTheMaterial(newMat);
      // showMaterialStatus(false);
      // THIS IS WHER WE SHOULD HAVE THE ABILITY TO UPDATE OUR MATERIAL ON OUR OWN
      Logger.debug('EDITOR IS GOING TO UPDATE THE MATERIAL');
      await updateMaterial(newMat);
      await setFixMe(Date.now);
    } else {
      Logger.debug(
        'MaterialEditor.closeEditor()... the data was not changed... not updating'
      );
    }
    e.stopPropagation();
  };

  const deleteMaterial = async (e) => {
    alert('you want to delete the material.  sorry not supported yet');
    e.stopPropagation();
  };

  const duplicateMaterial = async (e) => {
    alert('you want to copy the material.  sorry not supported yet');
    e.stopPropagation();
  };

  const handleDragStart = (e, data) => {
    e.dataTransfer.setData('text/plain', data);
  };

  useEffect(() => {
    async function getCats() {
      Logger.debug('getCats', theMaterial);
      const currentCat = await getCategoryLabel(theMaterial.category);
      setCategoryName(currentCat.label);
    }
    getCats();
  }, []);

  // const startHeaderEdit = (() => {
  //   Logger.debug('EntrySelection.startHeaderEdit() Starting header edit');

  //   saveHeaderChange('title')(theMaterial.title);
  //   saveHeaderChange('category')(theMaterial.category);
  //   setIsHeader(false);
  // });

  // const finishedHeaderEdit = (() => {
  //   Logger.debug('EntrySelection.finishedHeaderEdit() SAving is now', headerContent);
  //   saveChange('title')(headerContent.title);
  //   saveChange('category')(headerContent.category);
  //   // this is not actually passed through to the material, so we only
  //   // have this value when we set it. not when we load it from the db.
  //   // saveChange('categoryName')(headerContent.categoryName);

  //   setIsHeader(true);
  // });

  const productTableRef = useRef();
  // Logger.debug('EntrySelection.rendering  HEADER IS', theMaterial);
  return (
    <Dialog
      open={isOpen}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeEditor}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="lg"
      onKeyPress={(event) => {
        // probably not the best way to prevent keys inside text inputs
        if (event.target.type === 'text') {
          return;
        }

        const theKey = event.key;
        const theData = { content, location };
        if (theKey === 'r') {
          productTableRef.current.keyHandler(theKey, theData);
          Logger.debug('keypress: MATERIALEDITOR CREATE NEW ROW');
        } else if (theKey === 'b') {
          Logger.debug('keypress: MATERIALEDITOR ASSIGN TO CURRENT BRAND');
          productTableRef.current.keyHandler(theKey, theData);
        } else if (theKey === 'p') {
          Logger.debug('keypress: MATERIALEDITOR ASSIGN TO CURRENT PRODUCT');
          productTableRef.current.keyHandler(theKey, theData);
        } else if (theKey === 'n') {
          Logger.debug('keypress: MATERIALEDITOR ASSIGN TO CURRENT NOTE');
          productTableRef.current.keyHandler(theKey, theData);
        } else if (theKey === 't') {
          Logger.debug('keypress: MATERIALEDITOR ASSIGN TO TITLE');
          dropper(null, 'material-title');
        } else if (theKey === 'x') {
          // this is unique:  we want to:
          // explode the text by ';'
          // then create a row
          // and put expl[0] into brand
          // and put expl[1] into product
          const [brandText, productText] = content.split(';');

          // calculate the approximate length of each character
          const chars = content.length;
          const locLen = location.box.right - location.box.left;
          const chLen = locLen / chars;
          productTableRef.current.keyHandler('r', null); // create the new row

          // add brand and product if there is content.
          // reset the width of the bounding box for each based on their text
          if (brandText) {
            const bChars = brandText.length;
            const bLen = location.box.left + chLen * bChars;

            const bloc = {
              box: {
                ...location.box,
              },
              page: location.page,
              resource: location.resource,
            };
            bloc.box.right = bLen;
            productTableRef.current.keyHandler('b', {
              content: brandText,
              location: bloc,
            });
          }

          if (productText) {
            const pChars = productText.length;
            const pLen = location.box.right - chLen * pChars;
            const ploc = {
              box: {
                ...location.box,
              },
              page: location.page,
              resource: location.resource,
            };
            ploc.box.left = pLen;
            productTableRef.current.keyHandler('p', {
              content: productText,
              location: ploc,
            });
          }
        }
        // maybe also a delete? close? ...
      }}
    >
      <div
        style={{ backgroundColor: '#ccc', maxHeight: '10px', height: '10px' }}
        className="dragHandle" /* we need the reference to this classname elsewhere */
      />
      <DialogTitle>
        {/* { isHeader
          ? (
            <>
              <CardHeader
                avatar={(
                  <Avatar aria-label="Material" className={classes.avatar}>
                    M
                  </Avatar>
                )}
                action={(
                  <IconButton aria-label="Close" className={classes.closeButton} onClick={closeEditor}>
                    <CloseIcon />
                  </IconButton>
                )}
                title={headerContent.title}
                subheader={`${headerContent.categoryName} (${headerContent.category})`}
                onDragOver={e => e.preventDefault()}
                onDrop={e => dropper(e, 'material-title')}
                onClick={() => startHeaderEdit()}
              />
              <div>This is the Code/Tag</div>
              <div>This, is, the, TagList</div>
            </>
          )
          : ( */}
        <>
          <Container className={classes.container}>
            <TextField
              id="standard-name"
              placeholder="Product Classification"
              label="Product Classification"
              className={classes.textFieldMaterial}
              defaultValue={theMaterial.title}
              value={theMaterial.title}
              onChange={(event) => saveChange('title')(event.target.value)}
              margin="none"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => dropper(e, 'material-title')}
            />
            <TextField
              id="code-tag"
              placeholder="Code/Tag"
              label="Code/Tag"
              className={classes.textFieldCode}
              defaultValue={theMaterial.code}
              value={theMaterial.code}
              onChange={(event) => saveChange('code')(event.target.value)}
              margin="none"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => dropper(e, 'material-code')}
            />
            <div className={classes.categorySelectDIV}>
              <CategoryComponent
                className={classes.categorySelect}
                categoryLabel={categoryName}
                category={theMaterial.category}
                onChange={(a, b) => {
                  saveChange('category')(a);
                  setCategoryName(b);
                }}
              />
            </div>
            {/* <Button onClick={() => finishedHeaderEdit()} color="primary">
                  Close Title
                </Button> */}
          </Container>
          <Container>
            <TagList
              existing={theMaterial.tagList}
              onChange={(items) => {
                saveChange('tagList')(items);
              }}
            />
          </Container>
        </>
        {/* )
          } */}

        <Chip
          icon={chipIcon}
          color={chipColor}
          draggable
          onDragStart={(e) =>
            handleDragStart(e, JSON.stringify({ content, location }))
          }
          label={content}
          className={classes.chip}
        />
      </DialogTitle>
      <DialogContent>
        <ProductTable
          ref={productTableRef}
          key={99}
          records={theMaterial.equivilents}
          equiv_if_approved={theMaterial.equivIfApproved}
          onChange={saveChange('equivilents')}
          onChangeEquiv={saveChange('equivIfApproved')}
          gotoLocation={gotoLocation}
          acceptAssignment={acceptAssignment}
          updateDocumentWithSelection={updateDocumentWithSelection}
          resourceId={props.resourceid}
        />
      </DialogContent>
      <DialogActions>
        <MaterialList
          label="This Product is nested on:"
          existing={theMaterial.nested.child}
          materials={props.materials}
          onChange={(items) => {
            saveNested('child', items);
          }}
        />
        <MaterialList
          label="Products Nested on me:"
          existing={theMaterial.nested.parent}
          materials={props.materials}
          onChange={(items) => {
            saveNested('parent', items);
          }}
        />
        {/* <Button className={classes.actionBtn} onClick={duplicateMaterial} color="primary" title="Duplicate Material">
          <DuplicateIcon />
        </Button> */}
        <Button
          className={classes.actionBtn}
          onClick={deleteMaterial}
          color="primary"
          title="Delete Material"
        >
          <DeleteIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
