import styled from 'styled-components';
import Table from '@material-ui/core/Table';

export const ProjectResourcesContainer = styled.div`
  border-left: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  padding-left: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
`;

export const ProjectResourcesTable = styled(Table)`
  thead {
    th {
      border-bottom: 1px solid #919191;
      position: sticky;
      top: 68px;
      z-index: 2;
      background-color: white;
      box-shadow: 0 6px 8px -8px #919191;
    }

    tr:first-child {
      th {
        border-bottom: 0;
        top: 0;
      }
    }
  }

  tbody {
    tr:nth-of-type(even) {
      background-color: #f5f5f5;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }

    td {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
`;
